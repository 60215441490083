import React, { ReactElement } from "react";
import styles from "./MenuBO.module.scss";
import "./Prime.css";
import { Dock } from "primereact/dock";
import { MenuItem } from "./menu_item/MenuItem";
import RoutesEnum from "../../properties/RoutesEnum";

export function MenuBO(): ReactElement {
  const items = [
    {
      icon: () => (
        <>
          <MenuItem label="Chat" icon="pi-envelope" route="#" />
        </>
      ),
    },
    {
      icon: () => (
        <>
          <MenuItem label="Notas" icon="pi-money-bill" route="#" />
        </>
      ),
    },
    {
      icon: () => (
        <>
          <MenuItem label="Usuário" icon="pi-users" route={RoutesEnum.User} />
        </>
      ),
    },
    {
      icon: () => (
        <>
          <MenuItem label="Saques" icon="pi-wallet" route="#" />
        </>
      ),
    },
    {
      icon: () => (
        <>
          <MenuItem label="Capturas" icon="pi-server" route="#" />
        </>
      ),
    },
    {
      icon: () => (
        <>
          <MenuItem label="Utm" icon="pi-map-marker" route="#" />
        </>
      ),
    },
  ];

  return (
    <>
      <div className={styles.Dock}>
        <Dock model={items} position="left" magnification={false} />
      </div>
    </>
  );
}

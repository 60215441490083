import styles from "./EmptyBox.module.scss";
import { Card } from "primereact/card";
import { Tag } from "primereact/tag";
import React, { ReactElement } from "react";

interface EmptyBoxProps {
  msg?: string | null;
}

export function EmptyBox(props: EmptyBoxProps): ReactElement {
  return (
    <>
      <div className={styles.EmptyBox}>
        <Card className={styles.cardEmpty}>
          <div>
            <i className="pi pi-spin pi-ban" style={{ fontSize: "2em" }}></i>
            <Tag value={props.msg} severity="danger"></Tag>
          </div>
        </Card>
      </div>
    </>
  );
}

const format = (date: string): string => {
  return new Date(date).toLocaleDateString("pt-BR");
};

const formatTime = (date: number): string => {
  const format = new Date(date);
  return `${format.toLocaleDateString("pt-BR").substring(0, 5)} às ${format
    .toLocaleTimeString("pt-BR")
    .substring(0, 5)}`;
};

const formatFullTime = (date: number): string => {
  const format = new Date(date);
  return `${format.toLocaleDateString("pt-BR")} às ${format
    .toLocaleTimeString("pt-BR")
    .substring(0, 5)}`;
};

const formatDate = (date: Date): string => {
  return date.toLocaleDateString("pt-BR");
};

const DateUtils = {
  format,
  formatTime,
  formatDate,
  formatFullTime,
};

export default DateUtils;

import React, { ReactElement } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Extrato from "../../../../interfaces/Extrato";
import NumberUtils from "../../../../utils/NumberUtils";

interface UserExtratoProps {
  list: Array<Extrato>;
}

export function UserExtrato(props: UserExtratoProps): ReactElement {
  if (props.list.length === 0) {
    return <></>;
  }

  const valorFormat = (row: Extrato): string => {
    return NumberUtils.money.format(row.valor ?? 0);
  };

  return (
    <>
      <DataTable
        showGridlines
        value={props.list}
        scrollable
        scrollHeight="70vh"
      >
        <Column field="data" header="DATA" style={{ minWidth: "200px" }} />
        <Column field="tipo" header="TIPO" style={{ minWidth: "200px" }} />
        <Column
          field="valor"
          header="VALOR"
          style={{ minWidth: "200px" }}
          body={valorFormat}
        />
      </DataTable>
    </>
  );
}

import styles from "../ChatPage.module.scss";
import {Loading} from "../../../components/loading/Loading";
import {Toast} from "primereact/toast";
import React, {
    Dispatch,
    ReactElement,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from "react";
import ChatController from "../ChatController";
import ChatStatus from "../../../interfaces/ChatStatus";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {InputText} from "primereact/inputtext";
import {Badge} from "primereact/badge";
import {Button} from "primereact/button";
import "../Prime.css";
import {FilterMatchMode} from "primereact/api";
import DateUtils from "../../../utils/DateUtils";
import {Chip} from "primereact/chip";
import RoutesEnum from "../../../properties/RoutesEnum";
import RouteUtils from "../../../utils/RouteUtils";
import {Dropdown} from "primereact/dropdown";

interface ChatListProps {
    chat: ChatStatus | null;
    setChat: Dispatch<SetStateAction<ChatStatus | null>>;
}

export function ChatList(props: ChatListProps): ReactElement {
    const toast = useRef<Toast>(null);
    const [isResponseLoading, setIsResponseLoading] = useState(false);

    const [copySource, setCopySource] = useState<Array<ChatStatus>>([]);
    const [source, setSource] = useState<Array<ChatStatus>>([]);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        cpf: {value: "", matchMode: FilterMatchMode.STARTS_WITH},
    });
    const [cpf, setCPF] = useState("");
    const [order, setOrder] = useState<boolean>(true);
    const [estado, setEstado] = useState({name: 'Todos', code: 'ALL'});
    const [userDisableFilter, setUserDisableFilter] = useState({name: '', code: undefined});

    const userDisableFilterOptions = [
        {name: 'Todos', code: null},
        {name: 'Somente Desabilitados', code: "true"},
        {name: 'Somente Habilitados', code: "false"},
    ]

    const estados = [
        {name: 'Todos os Estados', code: 'ALL'},
        {name: 'São Paulo', code: 'SP'},
        {name: 'Rio de Janeiro', code: 'RJ'},
        {name: 'Rio Grande do Sul', code: 'RS'},
        {name: 'Acre', code: 'AC'},
        {name: 'Alagoas', code: 'AL'},
        {name: 'Amapá', code: 'AP'},
        {name: 'Amazonas', code: 'AM'},
        {name: 'Bahia', code: 'BA'},
        {name: 'Ceará', code: 'CE'},
        {name: 'Espírito Santo', code: 'ES'},
        {name: 'Goiás', code: 'GO'},
        {name: 'Maranhão', code: 'MA'},
        {name: 'Mato Grosso', code: 'MT'},
        {name: 'Mato Grosso do Sul', code: 'MS'},
        {name: 'Minas Gerais', code: 'MG'},
        {name: 'Pará', code: 'PA'},
        {name: 'Paraíba', code: 'PB'},
        {name: 'Paraná', code: 'PR'},
        {name: 'Pernambuco', code: 'PE'},
        {name: 'Piauí', code: 'PI'},
        {name: 'Rio Grande do Norte', code: 'RN'},
        {name: 'Rondônia', code: 'RO'},
        {name: 'Roraima', code: 'RR'},
        {name: 'Santa Catarina', code: 'SC'},
        {name: 'Sergipe', code: 'SE'},
        {name: 'Tocantins', code: 'TO'},
        {name: 'Distrito Federal', code: 'DF'}
    ]

    const controller = React.useCallback(
        () => new ChatController(setIsResponseLoading, toast),
        [setIsResponseLoading, toast]
    );

    const onGlobalFilterChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ): void => {
        const value = e.target.value;
        const _filters = {...filters};

        _filters["cpf"].value = value;
        setCPF(value);
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const compareTimestamp = (a: ChatStatus, b: ChatStatus): number => {
        if (a.last_message.timestamp < b.last_message.timestamp) {
            return order ? -1 : 1;
        }
        if (a.last_message.timestamp > b.last_message.timestamp) {
            return order ? 1 : -1;
        }
        return 0;
    };

    const compareUnread = (a: ChatStatus, b: ChatStatus): number => {
        if (a.unread < b.unread) {
            return order ? -1 : 1;
        }
        if (a.unread > b.unread) {
            return order ? 1 : -1;
        }
        return 0;
    };

    const [hideRead, setHideRead] = useState<boolean>(false);

    function sortByTimestamp(): void {
        setOrder(!order);
        setCopySource(
            source
                .sort(compareTimestamp)
                .filter((x) => x.unread > (hideRead ? 0 : -1))
        );
    }

    function sortByMsg(): void {
        setOrder(!order);
        setCopySource(
            source.sort(compareUnread).filter((x) => x.unread > (hideRead ? 0 : -1))
        );
    }

    function showOrHide(): void {
        setHideRead(!hideRead);
    }

    async function getUserList(): Promise<void> {
        const data = await controller().getUserList(estado.code, userDisableFilter?.code);
        if (data != null) {
            setSource(data);
            setCopySource(data.sort(compareTimestamp));
        }
    }

    useEffect(() => {
        getUserList();
    }, [setSource, controller, estado, userDisableFilter]);

    useEffect(() => {
        if (!hideRead) {
            setCopySource(source);
        } else {
            setCopySource(copySource.filter((x) => x.unread > (hideRead ? 0 : -1)));
        }
    }, [hideRead]);

    const buildBadge = (value: number): ReactElement => {
        if (value <= 3) {
            return <Badge value={value} severity="success" className="mr-2"></Badge>;
        }
        if (value <= 6) {
            return <Badge value={value} severity="warning" className="mr-2"></Badge>;
        }
        if (value > 10) {
            return <Badge value="10+" severity="danger" className="mr-2"></Badge>;
        }
        return <Badge value={value} severity="danger" className="mr-2"></Badge>;
    };

    const itemTemplate = (item: ChatStatus): ReactElement => {
        return (
            <div className={item.cpf == cpf ? styles.selected : styles.row}>
                <div className={styles.column}>
                    <span>{item.name}</span>
                    <strong>{item.cpf}</strong>
                    <Chip
                        className={styles.chatChip}
                        label={DateUtils.formatTime(item.last_message.timestamp)}
                    />
                </div>
                {item.cpf != cpf ? buildBadge(item.unread) : <></>}
            </div>
        );
    };

    function onEnterCPF(e: { key: string; keyCode: number }): void {
        if ((e.key === "Enter" || e.keyCode === 13) && cpf.length === 11) {
            props.setChat({
                last_message: {timestamp: 0},
                name: "",
                unread: 0,
                cpf: cpf,
            });
            RouteUtils.changeRoute(RoutesEnum.Chat, cpf);
        }
    }

    const headerSearch = (): ReactElement => {
        return (
            <>
                <Button className={styles.updateBtn} onClick={getUserList}>
                    <i className="pi pi-sync"></i>
                    <span>Atualizar</span>
                </Button>
                <div className={styles.column}>
                    <Dropdown value={userDisableFilter} onChange={(e) => setUserDisableFilter(e.value)}
                              options={userDisableFilterOptions}
                              optionLabel="name"
                              placeholder="Filtro por Crédito"
                              className={styles.select}/>
                    <Dropdown value={estado} onChange={(e) => setEstado(e.value)} options={estados}
                              optionLabel="name"
                              placeholder="Filtro por Estado"
                              className={styles.select}/>
                    <InputText
                        value={globalFilterValue}
                        placeholder="Filtrar por CPF"
                        onChange={onGlobalFilterChange}
                        onKeyUp={onEnterCPF}
                        className={styles.inputSimples}
                    />
                </div>
            </>
        );
    };

    const updateTemplate = (): ReactElement => {
        return (
            <>
                <Button className={styles.updateBtn} onClick={sortByTimestamp}>
                    <i className="pi pi-sort-alt"></i>
                    <span>DATA</span>
                </Button>
                <Button className={styles.updateBtn} onClick={sortByMsg}>
                    <i className="pi pi-sort-alt"></i>
                    <span>MENSAGENS</span>
                </Button>
                <Button className={styles.updateBtn} onClick={showOrHide}>
                    <i className={hideRead ? "pi pi-eye" : "pi pi-eye-slash"}></i>
                    <span>{hideRead ? "MOSTRAR LIDOS" : "ESCONDER LIDOS"}</span>
                </Button>
            </>
        );
    };

    return (
        <>
            <Loading isLoading={isResponseLoading}/>
            <Toast ref={toast}/>
            <div className={styles.listBody}>
                <DataTable
                    scrollable
                    scrollHeight="90vh"
                    className={styles.tabelaChat}
                    value={copySource}
                    selectionMode="single"
                    selection={props.chat}
                    onSelectionChange={(e) => {
                        props.setChat(e.value as ChatStatus);
                        setCPF(e.value.cpf);
                        RouteUtils.changeRoute(RoutesEnum.Chat, e.value.cpf);
                    }}
                    responsiveLayout="scroll"
                    filters={filters}
                    globalFilterFields={["cpf"]}
                    header={headerSearch}
                    emptyMessage="Chat vazio! :)"
                >
                    <Column
                        filterField="cpf"
                        header={updateTemplate}
                        body={itemTemplate}
                        bodyClassName={styles.body_row}
                    />
                </DataTable>
            </div>
        </>
    );
}

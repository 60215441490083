import React, {ReactElement, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import {Loading} from "../../components/loading/Loading";
import styles from "./SaquePage.module.scss";
import SaqueController from "./SaqueController";
import {Button} from "primereact/button";
import {Card} from 'primereact/card';

export function SaquePage(): ReactElement {
    const toast = useRef<Toast>(null);
    const [isResponseLoading, setIsResponseLoading] = useState(false);
    const controller = new SaqueController(setIsResponseLoading, toast);

    async function fecharLote(): Promise<void> {
        await controller.fecharLote();
        return;
    }

    return (
        <div className={styles.containerBody}>
            <Loading isLoading={isResponseLoading}/>
            <Toast ref={toast}/>
            <div className={styles.Saque}>
                <div className={styles.column}>
                    <Button
                        label="Fechar Lote BTG?"
                        icon="pi pi-send"
                        className={styles.expandBtn}
                        onClick={fecharLote}
                    />

                    <Card title="Descrição">
                        <p className="m-0">
                            Ao executar a rotina do BTG aguarde a mensagem no canal <a
                            href="https://chat.varejo360.com/group/dnn_suporte" target="_blank"
                            rel="noreferrer">#dnn_suporte</a>,
                            <b> somente uma rotina pode ser executada de cada vez!!!</b>
                        </p>
                    </Card>
                </div>
            </div>
        </div>
    )
}

import React, { ReactElement, useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import NumberUtils from "../../../../utils/NumberUtils";
import Premio from "../../../../interfaces/Premio";
import DateUtils from "../../../../utils/DateUtils";
import styles from "./UserPremio.module.scss";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import UserPremioController from "./UserPremioController";
import { Loading } from "../../../../components/loading/Loading";

interface UserPremioProps {
  list: Array<Premio>;
  cpf: string | undefined;
}

export function UserPremio(props: UserPremioProps): ReactElement {
  const [status, setStatus] = useState(null);
  const [total, setTotal] = useState(10);
  const [premios, setPremios] = useState<Array<Premio>>(props.list);
  const toast = useRef<Toast>(null);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const controller = React.useCallback(
    () => new UserPremioController(setIsResponseLoading, toast),
    [setIsResponseLoading, toast]
  );

  useEffect(() => {
    async function getPremios(): Promise<void> {
      const { cpf } = props;
      if (cpf) {
        setPremios(await controller().getPremios(cpf, status, total));
      }
    }
    if (premios.length !== 0) {
      getPremios();
    }
  }, []);

  if (props.list.length === 0 || props.cpf === undefined) {
    return <></>;
  }

  const valorFormat = (row: Premio): string => {
    return NumberUtils.money.format(row.awards.cashValue ?? 0);
  };

  const createdAt = (row: Premio): string => {
    return DateUtils.format(row.createdAt);
  };

  const updatedAt = (row: Premio): string => {
    const date = row.updatedAt;
    if (!date) {
      return "NÃO UTILIZADO";
    }
    return DateUtils.format(date);
  };

  const statusOp = [
    { label: "Todos", value: null },
    { label: "Pendente", value: "pendente" },
    { label: "Resgatado", value: "resgatado" },
  ];

  const totalOp = [
    { label: "10", value: 10 },
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
    { label: "Todos", value: null },
  ];

  const leftContents = (
    <>
      <h4 className={styles.padding}>Últimos:</h4>
      <Dropdown
        optionLabel="label"
        optionValue="value"
        value={total}
        options={totalOp}
        onChange={(e) => setTotal(e.value)}
        placeholder="Todos"
      />
      <h4 className={styles.padding}>Status:</h4>
      <Dropdown
        optionLabel="label"
        optionValue="value"
        value={status}
        options={statusOp}
        onChange={(e) => setStatus(e.value)}
        placeholder="Todos"
      />
      <div className="p-toolbar-separator mr-2" />
    </>
  );

  return (
    <>
      <Loading isLoading={isResponseLoading} />
      <Toast ref={toast} />
      <div className={styles.UserPremio}>
        <Toolbar left={leftContents} className={styles.row} />
        <div className={styles.row}>
          <DataTable
            showGridlines
            value={premios}
            scrollable
            scrollHeight="70vh"
          >
            <Column field="status" header="Status" />
            <Column header="Criado em" body={createdAt} />
            <Column header="Utilizado em" body={updatedAt} />
            <Column header="VALOR" body={valorFormat} />
          </DataTable>
        </div>
      </div>
    </>
  );
}

import React, { ReactElement } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Saque from "../../../../interfaces/Saque";
import NumberUtils from "../../../../utils/NumberUtils";
import DateUtils from "../../../../utils/DateUtils";
import { Button } from "primereact/button";
import UserController from "../../UserController";

interface UserSaqueProps {
  list: Array<Saque>;
  controller: UserController;
}

export function UserSaque(props: UserSaqueProps): ReactElement {
  if (props.list.length === 0) {
    return <></>;
  }

  const saldoFormat = (row: Saque): string => {
    return NumberUtils.money.format(row.saldo ?? 0);
  };

  const createdAtFormat = (row: Saque): string => {
    return DateUtils.format(row.createdAt);
  };

  const prazoFormat = (row: Saque): string => {
    return DateUtils.format(row.prazo_pagamento);
  };

  const statusFormat = (row: Saque): ReactElement | string => {
    if (row.status === "Pago") {
      return (
        <Button
          onClick={() => props.controller.goToComprovante(row.id_transfeera)}
          tooltip="Abrir Comprovante"
        >
          Pago
        </Button>
      );
    }

    return row.status;
  };

  const checkAjuda = (row: Saque): ReactElement => {
    return <span style={{width: '100%', textAlign: 'center'}}>
      <i 
        className={row.disasterHelperId ? 'pi pi-check-circle' : 'pi pi-times-circle'}
        style={row.disasterHelperId ? {color: 'green'} : {color: 'red'}}
      />
    </span>;
  }

  return (
    <>
      <DataTable
        showGridlines
        value={props.list}
        scrollable
        scrollHeight="70vh"
      >
        <Column field="banco" header="Banco" />
        <Column 
          field="disasterHelperId"
          header="Foi ajuda?"
          body={checkAjuda}
        />
        <Column field="saldo" header="Saldo" body={saldoFormat} />
        <Column field="tipo_conta" header="Tipo de Conta" />
        <Column
          field="createdAt"
          header="Data Registro"
          body={createdAtFormat}
        />
        <Column
          field="prazo_pagamento"
          header="Prazo Pagamento"
          body={prazoFormat}
        />
        <Column field="status" header="Status" body={statusFormat} />
      </DataTable>
    </>
  );
}

import React, { ReactElement, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import UserController from "../../UserController";
import DnnUser from "../../../../interfaces/DnnUser";
import DateUtils from "../../../../utils/DateUtils";
import styles from "./UserApiRequestsLog.module.scss";
import UserApiLog from "../../../../interfaces/UserApiLog";

interface UserApiRequestsLogProps {
  controller: UserController;
  user: DnnUser;
}

export function UserApiRequestsLogTable(
  props: UserApiRequestsLogProps
): ReactElement {
  const [userLogs, setUserLogs] = useState<Array<UserApiLog>>([]);

  async function loadLog(): Promise<void> {
    const data = await props.controller.getApiLogUser(props.user.cpf ?? "");
    if (data.length > 0) {
      setUserLogs(data);
    }
  }

  useEffect(() => {
    loadLog();
  }, [props.user]);

  const timestamp = (row: UserApiLog): string => {
    return DateUtils.formatFullTime(row.timestamp);
  };

  const status = (row: UserApiLog): ReactElement => {
    return <p className={styles.info}>{row.status}</p>;
  };

  const method = (row: UserApiLog): ReactElement => {
    return <p className={styles.info}>{row.method}</p>;
  };

  const uri = (row: UserApiLog): ReactElement => {
    return <p className={styles.info}>{row.uri}</p>;
  };

  const url = (row: UserApiLog): ReactElement => {
    return <p className={styles.info}>{row.url}</p>;
  };

  const host = (row: UserApiLog): ReactElement => {
    return <p className={styles.info}>{row.host}</p>;
  };

  const userAgent = (row: UserApiLog): ReactElement => {
    return <p className={styles.info}>{row.userAgent}</p>;
  };

  const referrer = (row: UserApiLog): ReactElement => {
    return <p className={styles.info}>{row.referrer}</p>;
  };

  const remoteAddress = (row: UserApiLog): ReactElement => {
    return <p className={styles.info}>{row.remoteAddress}</p>;
  };

  const headers = (row: UserApiLog): ReactElement => {
    return <p className={styles.info}>{row.headers}</p>;
  };

  return (
    <>
      <div className={styles.UserApiRequestsLog}>
        <DataTable value={userLogs} scrollable scrollHeight="90vh">
          <Column body={timestamp} header="timestamp" className={styles.box} />
          <Column body={status} header="status" className={styles.small} />
          <Column body={headers} header="headers" className={styles.big} />
          <Column body={uri} header="uri" className={styles.box} />
          <Column body={url} header="url" className={styles.box} />
          <Column body={method} header="method" className={styles.box} />
          <Column body={host} header="host" className={styles.box} />
          <Column body={userAgent} header="userAgent" className={styles.box} />
          <Column body={referrer} header="referrer" className={styles.box} />
          <Column
            body={remoteAddress}
            header="remoteAddress"
            className={styles.box}
          />
        </DataTable>
      </div>
    </>
  );
}

import api from "./Api";
import { AxiosResponse } from "axios";
import token from "./TokeService";

const getNotas = (
  year: number,
  month: number,
  cpf: string,
  filtrarSaque: string,
  psql: boolean,
): Promise<AxiosResponse> => {
  return api.get(
    `/notas_month?token=${token()}&year=${year}&month=${month}&cpf=${cpf}&filtrar_ultimo_saque=${filtrarSaque}&psql=${psql}`
  );
};

const NotasService = {
  getNotas,
};

export default NotasService;

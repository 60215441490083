import UserController from "../UserController";
import { TabPanel, TabView } from "primereact/tabview";
import styles from "./UserLogTable.module.scss";
import React, { ReactElement, useState } from "react";
import DnnUser from "../../../interfaces/DnnUser";
import { UserActionLogTable } from "./action_log/UserActionLog";
import { UserApiRequestsLogTable } from "./api_requests/UserApiRequestsLog";
import { UserApiRequestsErrorLogTable } from "./api_requests_error/UserApiRequestsErrorLog";

import "./Custom.css";

interface UserLogProps {
  controller: UserController;
  user: DnnUser;

  enableScroll: boolean;
}

export function UserLogTable(props: UserLogProps): ReactElement {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  return (
    <>
      <div className={styles.UserLogTable}>
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
          scrollable={props.enableScroll}
        >
          <TabPanel header="Action Log">
            <UserActionLogTable
              controller={props.controller}
              user={props.user}
            />
          </TabPanel>
          <TabPanel header="Api Requests Log">
            <UserApiRequestsLogTable
              controller={props.controller}
              user={props.user}
            />
          </TabPanel>
          <TabPanel header="Api Error Log">
            <UserApiRequestsErrorLogTable
              controller={props.controller}
              user={props.user}
            />
          </TabPanel>
        </TabView>
      </div>
    </>
  );
}

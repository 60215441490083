import React, {ReactElement} from "react";
import {Card} from "primereact/card";
import styles from "./ReportCard.module.scss";
import {Badge} from "primereact/badge";
import {Divider} from 'primereact/divider';
import ReportUser from "../../../interfaces/ReportUser";
import ReportUtils from "../ReportUtils";
import HTMLReactParser from "html-react-parser";

interface ReportCardProps {
    value: ReportUser | undefined
}

export default function ReportCard(props: ReportCardProps): ReactElement {

    function mapDetails(item: {
        label: string;
        content: string;
    }): ReactElement {
        return (
            <>
                <Card className={styles.body} key={item.label}>
                    <div className={styles.column}>
                        <strong className={styles.label}>{item.label}</strong>
                        <p className={styles.label}>{item.content}</p>
                    </div>
                </Card>
            </>
        )
    }

    if (!props.value) return (<></>);

    return (
        <>
            <div className={styles.ReportCard}>
                <div className={styles.row}>
                    <Card className={styles.body}>
                        <div className={styles.column}>
                            <Badge
                                className={styles.time}
                                value={ReportUtils.getStatus(props.value.status ?? "")}
                                severity={ReportUtils.getStatusTag(props.value.status ?? "")}
                            />
                            <p className={styles.label}><strong>Código:</strong> {props.value.protocol}</p>
                            <p className={styles.label}><strong>Data de
                                envio:</strong> {props.value.data}</p>
                        </div>
                    </Card>
                </div>
                <Divider/>
                <div className={styles.user}>
                    <div className="p-overlay-badge">
                        {
                            props.value.details?.map(mapDetails)
                        }
                    </div>
                </div>

                <div className={styles.dnn}>
                    <div className="p-overlay-badge">
                        {props.value.answer ? <Card className={styles.body}>
                            <div className={styles.column}>
                                <strong className={styles.label}>Resposta:</strong>
                                <p className={styles.label}>{HTMLReactParser(props.value.answer)}</p>
                                <hr/>
                            </div>
                        </Card> : <></>}
                    </div>
                </div>
                <Divider/>
            </div>
        </>
    );
}

import React, { 
  ChangeEvent, 
  FormEvent, 
  ReactElement, 
  useEffect, 
  useState 
} from "react";
import PremioSorteioController from "../premio/PremioSorteioController";
import PremioSorteio from "../../../interfaces/PremioSorteio";
import styles from "./PremioSorteioForm.module.scss";
import { format, set } from "date-fns";
import { ptBR } from 'date-fns/locale';
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Nullable } from "primereact/ts-helpers";

interface PremioSorteioFormProps {
	visible: boolean;
	setVisible: (valor: boolean) => void;
  premioForm: PremioSorteio | undefined;
  acaoForm: string;
  idSorteio: number;
  idApuracao: number;
  controller: PremioSorteioController;
  getPremios: () => Promise<void>;
}

interface PremioResgatado {
  name: string;
  value: boolean;
}

interface TipoPremio {
  name: string;
  value: string;
}

export function PremioSorteioForm(props: PremioSorteioFormProps): ReactElement {
  const [id, setId] = useState<number | null>(null);
  const [ordem, setOrdem] = useState('');
  const [numeroDaSorte, setNumeroDaSorte] = useState('');
  const [descricao, setDescricao] = useState('');
  const [valor, setValor] = useState('');
  const [validade, setValidade] = 
    useState<Nullable<Date>>(null);
  const [resgatado, setResgatado] = useState(false);
  const [tutorial, setTutorial] = useState('');
  const [userInfo, setUserInfo] = useState('');
  const [tipo, setTipo] = useState('');
  const [cartaoCsc, setCartaoCsc] = useState('');
  const [cartaoNumero, setCartaoNumero] = useState('');
  const [cartaoValidade, setCartaoValidade] = useState('');
  const [cartaoNome, setCartaoNome] = useState('');
  
  const resgatadoOptions: PremioResgatado[] = [
    { name: 'Sim', value: true },
    { name: 'Não', value: false }
  ]

  const tipoOptions: TipoPremio[] = [
    {name: 'Cartão', value: 'cartao'}
  ]

  function setEndOfDay(data: Date): Date {
    return set(data, {
      hours: 23,
      minutes: 59,
      seconds: 59
    });
  }

  function changeOrdem(valor: string): void {
    setOrdem(valor);
  }

  useEffect(() => {
    if(props.premioForm) {
      const {
        id,
        ordem,
        numeroDaSorteContemplado,
        tutorial,
        descricao,
        userInfo,
        valor,
        validade,
        resgatado,
        tipo,
        cartaoNome,
        cartaoNumero,
        cartaoCsc,
        cartaoValidade
      } = props.premioForm;

      setId(id);
      if(ordem != null)
        setOrdem(ordem.toString());
      setNumeroDaSorte(numeroDaSorteContemplado as string);
      setTutorial(tutorial as string);
      setDescricao(descricao);
      setUserInfo(userInfo as string);
      setValor(valor);
      setValidade(new Date(validade));
      setResgatado(resgatado);
      setTipo(tipo);
      setCartaoNome(cartaoNome as string);
      setCartaoNumero(cartaoNumero as string);
      setCartaoCsc(cartaoCsc as string);
      setCartaoValidade(cartaoValidade as string);
    }
  }, [props.premioForm]);

  function buildPremio(): PremioSorteio {
    return {
      id: id,
      ordem: parseInt(ordem),
      numeroDaSorteContemplado: numeroDaSorte,
      tutorial: tutorial,
      descricao: descricao,
      userInfo: userInfo,
      valor: valor,
      validade: format(
        setEndOfDay(validade as Date),
        'yyyy-MM-dd HH:mm:ss', 
        { locale: ptBR }
      ),
      resgatado: resgatado,
      tipo: tipo,
      cartaoNome: cartaoNome,
      cartaoNumero: cartaoNumero,
      cartaoCsc: cartaoCsc,
      cartaoValidade: cartaoValidade,
      codigo: ''
    }
  }
  
  function handleSubmit(e: FormEvent): void {
    e.preventDefault();
    const premio = buildPremio();
    if(props.acaoForm === 'Criar') {
			props.controller.createPremioByIdApuracao(
        props.idSorteio,
        props.idApuracao,
        premio
      ).then(() => {
        props.setVisible(false);
        props.getPremios();
      })
		} else if (props.acaoForm === 'Editar') {
			props.controller.updatePremioByIdApuracao(
        props.idSorteio,
        props.idApuracao,
        premio
      ).then(() => {
        props.setVisible(false);
        props.getPremios();
      })
		}
  }

  function regexInteiros(
    e: ChangeEvent<HTMLInputElement>, 
    setFunction: (x: string) => void
  ): void {
    const pattern = /^[0-9]*$/;
    const isValid = pattern.test(e.target.value);
    console.log(isValid)
    if(isValid)
      setFunction(e.target.value);
  }

  function regexNumeroDaSorte(e: ChangeEvent<HTMLInputElement>): void {
    const pattern = /^[0-9]*[/]?[0-9]*$/;
    const isValid = pattern.test(e.target.value);
    if(isValid)
      setNumeroDaSorte(e.target.value);
  }

  function regexValor(e: ChangeEvent<HTMLInputElement>): void {
    const pattern = /^[0-9]*[,]?[0-9]*$/;
    const isValid = pattern.test(e.target.value);
    if(isValid)
      setValor(e.target.value);
  }

  function regexValidadeCartao(e: ChangeEvent<HTMLInputElement>): void {
    const pattern = /^[0-9]{0,2}[/]{0,1}[0-9]{0,2}$/;
    const isValid = pattern.test(e.target.value);
    if(isValid)
      setCartaoValidade(e.target.value);
  }

  function regexCartaoCsc(e: ChangeEvent<HTMLInputElement>): void {
    const pattern = /^[0-9]{0,3}$/;
    const isValid = pattern.test(e.target.value);
    if(isValid)
      setCartaoCsc(e.target.value);
  }

  return (
    <>
      <Dialog 
				className={styles.modal}
				header={`${props.acaoForm} prêmio`}
				onHide={() => props.setVisible(false)}
				visible={props.visible}
			>
        <form onSubmit={handleSubmit}>
          <div className={styles.formRow}>
						<div className={styles.formGroup}>
							<label htmlFor="ordem">Ordem</label>
							<InputText 
								value={ordem} 
								id="ordem" 
								type="text" 
								placeholder="Ordem"
								onChange={(e) => regexInteiros(e, changeOrdem)}
							/>
						</div>
            <div className={styles.formGroup}>
							<label htmlFor="numero_da_sorte" >
                Número da sorte
              </label>
							<InputText 
								value={numeroDaSorte} 
								id="numero_da_sorte" 
								type="text" 
								placeholder="Número da sorte"
								onChange={regexNumeroDaSorte}
							/>
						</div>
					</div>
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor="tutorial">Link do tutorial</label>
              <InputText 
                value={tutorial} 
                id="tutorial" 
                type="text" 
                placeholder="Link para o tutorial"
                onChange={(e) => setTutorial(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor="descricao">Descrição</label>
              <InputTextarea 
                id="descricao"
                rows={5} 
                value={descricao} 
                onChange={(e) => setDescricao(e.target.value)} 
                placeholder="Descrição"
                autoResize
                required
              />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor="valor">Valor</label>
							<InputText 
								value={valor} 
								id="valor" 
								type="text" 
								placeholder="Valor"
								onChange={regexValor}
								required
							/>
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="validade">Data de validade</label>
							<Calendar 
                inputId="validade"
                placeholder="dia / mês / ano" 
                showIcon
                locale="pt-br"
                dateFormat="dd/mm/yy"
                value={validade}
                onChange={(e) => {
                  setValidade(e.target.value as Date)}
                }
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="resgatado">Resgatado</label>
							< Dropdown 
                inputId="resgatado"
                value={resgatado}
                onChange={(e) => setResgatado(e.value)}
                options={resgatadoOptions}
                optionLabel="name"
                disabled
              />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor="tipo">Tipo</label>
              < Dropdown 
                inputId="tipo"
                value={tipo}
                onChange={(e) => setTipo(e.value)}
                options={tipoOptions}
                optionLabel="name"
                placeholder="Selecione uma opção"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="nome_cartao">Nome do cartão</label>
							<InputText 
								value={cartaoNome} 
								id="nome_cartao" 
								type="text" 
								placeholder="Nome do cartão"
								onChange={(e) => setCartaoNome(e.target.value)}
							/>
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor="numero_cartao">Número do cartão</label>
							<InputText 
								value={cartaoNumero} 
								id="numero_cartao" 
								type="text" 
								placeholder="Número do cartão"
								onChange={(e) => {
                  setCartaoNumero(e.target.value)
                }}
							/>
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="csc_cartao">CSC do cartão</label>
							<InputText 
								value={cartaoCsc} 
								id="csc_cartao" 
								type="text" 
								placeholder="CSC do cartão"
								onChange={regexCartaoCsc}
							/>
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="validade_cartao">Validade do cartão</label>
							<InputText 
								value={cartaoValidade} 
								id="validade_cartao" 
								type="text" 
								placeholder="Validade do cartão"
								onChange={regexValidadeCartao}
							/>
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor="info_ganhador">Informações do ganhador</label>
              <InputTextarea 
                id="info_ganhador"
                rows={5} 
                value={userInfo} 
                onChange={(e) => setUserInfo(e.target.value)} 
                placeholder="Informações do ganhador"
                autoResize
                disabled
              />
            </div>
          </div>

          <div className={styles.buttonsFormContainer}>
            <Button 
              label={props.acaoForm} 
              className="p-button-rounded p-button-info"
              type="submit"
            />
            <Button 
              label="Cancelar" 
              className="p-button-rounded p-button-danger" 
              type="button"
              onClick={() => props.setVisible(false)}
            />
          </div>
        </form>
      </Dialog>
    </>
  )
}
import React, {Dispatch, ReactElement, SetStateAction, useEffect, useRef, useState} from "react";
import styles from "./UserPage.module.scss";
import {TabView, TabPanel} from "primereact/tabview";
import {Menubar} from "primereact/menubar";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Tooltip} from "primereact/tooltip";
import "./Prime.css";
import {Toast} from "primereact/toast";
import {UserInfo} from "./user_info/UserInfo";
import {UserActions} from "./user_actions/UserActions";
import DnnUser from "../../interfaces/DnnUser";
import UserController from "./UserController";
import Extrato from "../../interfaces/Extrato";
import {UserDataTable} from "./user_datatable/UserDataTable";
import Saque from "../../interfaces/Saque";
import Compartilhamento from "../../interfaces/Compartilhamento";
import Premio from "../../interfaces/Premio";
import {Loading} from "../../components/loading/Loading";
import Bilhete from "../../interfaces/Bilhete";
import Pesquisa from "../../interfaces/Pesquisa";
import UserAccordion from "./user_datatable/UserAccordion";
import ChatStatus from "../../interfaces/ChatStatus";
import RouteUtils from "../../utils/RouteUtils";
import RoutesEnum from "../../properties/RoutesEnum";
import {useParams, useSearchParams} from "react-router-dom";
import {UserLogTable} from "./user_log/UserLogTable";
import MissaoUser from "../../interfaces/MissaoUser";
import ReportStatus from "../../interfaces/ReportStatus";

interface UserProps {
    user?: ChatStatus | ReportStatus | null;
    setReport?: Dispatch<SetStateAction<ReportStatus | null>>;
    enable: boolean;
    className?: string;
}

export function UserPage(props: UserProps): ReactElement {
    const [activeIndex, setActiveIndex] = useState(0);
    const toast = useRef<Toast>(null);
    const [isResponseLoading, setIsResponseLoading] = useState(false);
    const [userDnn, setUserDnn] = useState<DnnUser>({});
    const [listExtrato, setListExtrato] = useState<Array<Extrato>>([]);
    const [listSaque, setlistSaque] = useState<Array<Saque>>([]);
    const [listCompartilhamento, setlistCompartilhamento] = useState<
        Array<Compartilhamento>
    >([]);
    const [listPremio, setlistPremio] = useState<Array<Premio>>([]);
    const [listBilhete, setlistBilhete] = useState<Array<Bilhete>>([]);
    const [listPesquisa, setlistPesquisa] = useState<Array<Pesquisa>>([]);
    const [listMissao, setListMissao] = useState<Array<MissaoUser>>([]);
    const [param, setParam] = useState<string>();
    const {cpf} = useParams();
    const controller = new UserController(setIsResponseLoading, toast);
    const [searchParams, setSearchParams] = useSearchParams();

    async function searchUser(psql?: boolean): Promise<void> {
        const cpfToSearch = param ?? props.user?.cpf ?? cpf;
        if (cpfToSearch) {
            const user = await controller.searchUser(cpfToSearch);
            if (user !== null) {
                if (props.enable) {
                    RouteUtils.changeRoute(RoutesEnum.User, user?.cpf ?? "");
                }
                const [
                    userDnn,
                    extrato,
                    saques,
                    compartilhamentos,
                    premios,
                    bilhetes,
                    pesquisas,
                ] = await controller.infoUser(user?.cpf ?? "", psql);
                setUserDnn(userDnn);
                setListExtrato(extrato);
                setlistSaque(saques);
                setlistCompartilhamento(compartilhamentos);
                setlistPremio(premios);
                setlistBilhete(bilhetes);
                setlistPesquisa(pesquisas);

                const missaoUsers = await controller.getMissaoUser(cpfToSearch);
                setListMissao(missaoUsers);

                if (props.setReport) {
                    props.setReport({
                        timestamp: 0,
                        name: userDnn.nome ?? "",
                        unread: 0,
                        cpf: userDnn.cpf ?? "",
                    })
                }


            } else {
                toast.current?.show({
                    severity: "warn",
                    detail: "Usuário não encontrado",
                });
                setUserDnn({});
            }
        }
    }

    function searchUserPsql(): void {
        searchUser(true);
    }

    async function update(): Promise<void> {
        await new Promise(f => setTimeout(f, 5000));
        searchUser();
    }

    useEffect(() => {
        searchUser();
    }, [props.user]);

    return (
        <div className={styles.containerBody}>
            {props.enable ? <Loading isLoading={isResponseLoading}/> : <></>}
            <Toast ref={toast}/>
            <div className={props.className ?? styles.User}>
                {props.enable ? (
                    <Menubar
                        start={
                            <div className={styles.search_box}>
                                <Tooltip target=".search-user"/>
                                <span
                                    className={`search-user ${styles.search}`}
                                    data-pr-tooltip="CPF, e-mail ou UserID"
                                    data-pr-position="right"
                                >
                  <Button
                      className={styles.search_btn}
                      label="Buscar"
                      icon="pi pi-search"
                      onClick={() => searchUser()}
                  />
                  <Button
                      className={styles.search_btn}
                      label=" PSQL "
                      icon="pi pi-search"
                      onClick={searchUserPsql}
                  />
                  <InputText
                      placeholder="Buscar usuário (CPF, email ou UserID)"
                      type="text"
                      className={styles.search}
                      value={param ?? ""}
                      onChange={(event) => setParam(event.target.value)}
                      onKeyDown={(e) => {
                          if (e.key === "Enter") {
                              searchUser();
                          }
                      }}
                  />
                </span>
                            </div>
                        }
                    />
                ) : (
                    <></>
                )}
                <TabView
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                >
                    <TabPanel header="Informações Gerais">
                        <div className={styles.padding}>
                            <UserInfo {...userDnn} enable={props.enable}/>
                            <UserActions
                                cpf={userDnn.cpf}
                                id={userDnn._id}
                                email={userDnn.email}
                            />
                            <UserAccordion
                                cpf={userDnn.cpf}
                                extratos={listExtrato}
                                saques={listSaque}
                                compartilhamentos={listCompartilhamento}
                                premios={listPremio}
                                bilhetes={listBilhete}
                                pesquisa={listPesquisa}
                                index={[]}
                                controller={controller}
                                update={update}
                                missao={listMissao}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel header="Tabelas" className={styles.no_padding}>
                        <UserDataTable
                            cpf={userDnn.cpf}
                            extratos={listExtrato}
                            saques={listSaque}
                            compartilhamentos={listCompartilhamento}
                            premios={listPremio}
                            bilhetes={listBilhete}
                            pesquisa={listPesquisa}
                            enableScroll={!props.enable}
                            controller={controller}
                            missao={listMissao}
                            update={update}
                        />
                    </TabPanel>
                    <TabPanel header="Logs" className={styles.no_padding}>
                        <UserLogTable
                            controller={controller}
                            user={userDnn}
                            enableScroll={!props.enable}
                        />
                    </TabPanel>
                </TabView>
            </div>
        </div>
    );
}

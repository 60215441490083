import api from "./Api";
import {AxiosResponse} from "axios";
import token from "./TokeService";

async function getCapturasUrl(): Promise<AxiosResponse> {
  return await api.get(`/capturas/url?token=${token()}`);
}

const CapturaService = {
  getCapturasUrl
}

export default CapturaService;
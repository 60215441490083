import React, {ReactElement, useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import DepoimentoController from "./DepoimentoController";
import {Loading} from "../../components/loading/Loading";
import styles from "./DepoimentoPage.module.scss";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import Depoimento from "../../interfaces/Depoimento";

export function DepoimentoPage(): ReactElement {
    const toast = useRef<Toast>(null);
    const [isResponseLoading, setIsResponseLoading] = useState(false);

    const [source, setSource] = useState<Array<Depoimento>>([]);

    const controller = React.useCallback(
        () => new DepoimentoController(setIsResponseLoading, toast),
        [setIsResponseLoading, toast]
    );

    async function getDepoimentos(): Promise<void> {
        const data = await controller().get();
        if (data) {
            setSource(data);
        }
    }

    useEffect(() => {
        getDepoimentos();
    }, [setSource, controller]);


    const link = (row: Depoimento): ReactElement => {
        return <a target={'_blank'}
                  rel={'noreferrer'}
                  href={`https://console.cloud.google.com/storage/browser/_details/v360-dnn-depoimentos-videos/${row.video}`}>LINK</a>;
    };

    return (
        <>
            <Loading isLoading={isResponseLoading}/>
            <Toast ref={toast}/>
            <div className={styles.depoimentoPage}>
                <div className={styles.tab}>
                    <DataTable
                        value={source}
                        responsiveLayout="scroll"
                        className={styles.table}
                        showGridlines
                        rowHover
                    >
                        <Column field="cpf" header="CPF"/>
                        <Column field="name" header="Nome"/>
                        <Column field="estado" header="Estado"/>
                        <Column field="cidade" header="Cidade"/>
                        <Column field="descricao" header="Sorteio"/>
                        <Column field="video" header="Video"/>
                        <Column body={link} header="Download"/>
                    </DataTable>
                </div>
            </div>
        </>
    );
}

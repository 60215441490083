import {Dispatch, RefObject, SetStateAction} from "react";
import {Toast} from "primereact/toast";
import MissaoUserService from "../../../../services/MissaoUserService";

class UserMissaoController {
    private readonly dispatchLoading: Dispatch<SetStateAction<boolean>>;
    private readonly toast: RefObject<Toast>;

    constructor(
        dispatchLoading: Dispatch<SetStateAction<boolean>>,
        toast: RefObject<Toast>
    ) {
        this.dispatchLoading = dispatchLoading;
        this.toast = toast;
    }

    async completeMissao(
        missaoId: string,
        update: () => void,
    ): Promise<void> {
        try {
            this.dispatchLoading(true);
            const response = await MissaoUserService.completeMissao(missaoId);
            if (response.status === 200) {
                this.toast.current?.show({
                    severity: "success",
                    detail: "Missao completada com sucesso!!",
                });
                update();
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }
    }
}

export default UserMissaoController;

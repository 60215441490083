import { Dispatch, RefObject, SetStateAction } from "react";
import { Toast } from "primereact/toast";
import ChatService from "../../../../services/ChatService";

class ChatFormController {
  private readonly dispatchLoading: Dispatch<SetStateAction<boolean>>;
  private readonly toast: RefObject<Toast>;

  constructor(
    dispatchLoading: Dispatch<SetStateAction<boolean>>,
    toast: RefObject<Toast>
  ) {
    this.dispatchLoading = dispatchLoading;
    this.toast = toast;
  }

  async sendChat(cpf: string, message: string): Promise<boolean> {
    try {
      this.dispatchLoading(true);
      const response = await ChatService.sendMessage({
        cpf: cpf,
        message: message,
      });
      if (response.status !== 200) {
        this.toast.current?.show({ severity: "error", detail: "Erro" });
        return false;
      }
    } catch (e) {
      console.error(e);
      this.toast.current?.show({ severity: "error", detail: "Erro" });
      return false;
    } finally {
      this.dispatchLoading(false);
    }
    return true;
  }
}

export default ChatFormController;

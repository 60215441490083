import {Dispatch, RefObject, SetStateAction} from "react";
import {Toast} from "primereact/toast";
import SaqueService from "../../services/SaqueService";

class SaqueController {

    private readonly dispatchLoading: Dispatch<SetStateAction<boolean>>;
    private readonly toast: RefObject<Toast>;

    constructor(
        dispatchLoading: Dispatch<SetStateAction<boolean>>,
        toast: RefObject<Toast>
    ) {
        this.dispatchLoading = dispatchLoading;
        this.toast = toast;
    }


    async fecharLote(): Promise<void> {
        try {
            this.dispatchLoading(true);
            const response = await SaqueService.fecharLote();
            if (response.status === 200) {
                this.toast.current?.show({
                    severity: "success",
                    detail: "Lote sendo executado, aguarde a mensagem no chat #dnn_suporte, pode levar até 30 min."
                });
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }

        return;

    }
}

export default SaqueController;

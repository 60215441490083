import React, { ReactElement, useEffect, useRef, useState } from "react";
import styles from "./ApuracaoSorteioPage.module.scss";
import image from "../../../assets/dinheiro-na-nota.png";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import RoutesEnum from "../../../properties/RoutesEnum";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ApuracaoController from "./ApuracaoController";
import { Toast } from "primereact/toast";
import { Loading } from "../../../components/loading/Loading";
import Apuracao from "../../../interfaces/Apuracao";
import { format } from "date-fns";
import { ptBR } from 'date-fns/locale';
import { Controller, useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import Ganhador from "../../../interfaces/Ganhador";
import Sorteio from "../../../interfaces/Sorteio";
import { classNames } from "primereact/utils";
import { Carousel } from "primereact/carousel";

interface FormData {
  numeroExtracaoLoteria: string;
  bilhete1: string;
  bilhete2: string;
  bilhete3: string;
  bilhete4: string;
  bilhete5: string;
}

export function ApuracaoSorteioPage(): ReactElement {

  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [apuracao, setApuracao] = useState<Apuracao | null>(null);
  const [sorteio, setSorteio] = useState<Sorteio | null>(null);
  const [dataApuracao, setDataApuracao] = useState('');
  const [dataExtracao, setDataExtracao] = useState('');
  const [ganhadores, setGanhadores] = useState<Ganhador[]>([]);
  const [modoApresentacao, setModoApresentacao] = useState(false);
  const [label, setLabel] = useState('Apresentar');
  const [sortear, setSortear] = useState(false);
  const [numeroSorteado, setNumeroSorteado] = useState('');
  const [displayCarrosel, setDisplayCarrosel] = useState(false);

  const { 
    control, 
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FormData>({
    defaultValues: {
      numeroExtracaoLoteria: '',
      bilhete1: '',
      bilhete2: '',
      bilhete3: '',
      bilhete4: '',
      bilhete5: ''
    }
  })

  const toast = useRef<Toast>(null);
  
  const navigate = useNavigate();
  const { idSorteio, idApuracao } = useParams();
  const idSorteioValue = idSorteio ? parseInt(idSorteio) : NaN;
  const idApuracaoValue = idApuracao ? parseInt(idApuracao) : NaN;

  const controller = new ApuracaoController(setIsResponseLoading, toast);

  async function getGanhadores(apuracaoId: number): Promise<void> {
    await controller.buscarGanhadoresApuracao(apuracaoId)
      .then((data) => {
        setGanhadores(data);
      });
  }

  function desformatarNumerosSorteados(numerosSorteados: string): string[] {
    let numerosDesformatados = numerosSorteados.slice(1, -1);
    numerosDesformatados = numerosDesformatados.replace(/'/g, '');
    return numerosDesformatados.split(',');
  }

  function preencheDadosForm(data: Apuracao): void {
    let numerosSorteados = ["","","","",""];
    let numeroExtracaoLoteria = "";
    if (data.numerosSorteadosPelaExtracaoLoteriaFederal != null &&
      data.numerosSorteadosPelaExtracaoLoteriaFederal != "" &&
      data.numerosSorteadosPelaExtracaoLoteriaFederal != undefined
    ) {
      numerosSorteados = desformatarNumerosSorteados(
        data.numerosSorteadosPelaExtracaoLoteriaFederal!
      )
    }
    if (data.numeroExtracaoLoteriaFederal != null ||
      data.numeroExtracaoLoteriaFederal != ""
    ) {
      numeroExtracaoLoteria = data.numeroExtracaoLoteriaFederal!;
    }
    reset({
      numeroExtracaoLoteria: numeroExtracaoLoteria,
      bilhete1: numerosSorteados[0],
      bilhete2: numerosSorteados[1],
      bilhete3: numerosSorteados[2],
      bilhete4: numerosSorteados[3],
      bilhete5: numerosSorteados[4]
    })
  }

  async function getApuracao(): Promise<void> {
    await controller.buscarApuracaoPeloId(idApuracaoValue)
      .then((data) => {
        if (data != undefined) {
          setApuracao(data);
          setDataApuracao(format(
            data.data_apuracao, 
            'dd/MM/yyyy', 
            { locale: ptBR }
          ));
          setDataExtracao(format(
            data.dataExtracaoLoteriaFederal, 
            'dd/MM/yyyy', 
            { locale: ptBR }
          ));
          preencheDadosForm(data);
          if (data.numeroDaSorteSorteado != null && 
            data.numeroDaSorteSorteado != ""
          ) {
            getGanhadores(data.id!);
          }
        }
      });
  }

  async function getSorteio(): Promise<void> {
    await controller.getSorteioFromApuracao(idSorteioValue)
      .then((data) => {
        if (data != undefined)
          setSorteio(data);
      });
  }

  useEffect(() => {
    getApuracao();
    getSorteio();
  }, []);

  async function onSubmit(data: FormData): Promise<void> {
    const ap = {...apuracao};
    const numeroDaSorteSorteado = 
      data.bilhete1.charAt(data.bilhete1.length - 2) +
      data.bilhete2.charAt(data.bilhete2.length - 2) +
      '/' +
      data.bilhete1.charAt(data.bilhete1.length - 1) +
      data.bilhete2.charAt(data.bilhete2.length - 1) +
      data.bilhete3.charAt(data.bilhete3.length - 1) +
      data.bilhete4.charAt(data.bilhete4.length - 1) +
      data.bilhete5.charAt(data.bilhete5.length - 1);
    const numerosSorteados = 
      "['"+data.bilhete1+"'," +
      "'"+data.bilhete2+"'," +
      "'"+data.bilhete3+"'," +
      "'"+data.bilhete4+"'," +
      "'"+data.bilhete5+"']";

    ap.numerosSorteadosPelaExtracaoLoteriaFederal = numerosSorteados;
    ap.numeroDaSorteSorteado = numeroDaSorteSorteado;
    ap.numeroExtracaoLoteriaFederal = data.numeroExtracaoLoteria;
    
    await controller.processarApuracao(ap as Apuracao)
      .then(() => {
        getApuracao();
        getGanhadores(idApuracaoValue);
      });
  }

  function carouselContent(ganhador: Ganhador): ReactElement {
    return (
      <div className={styles.carrossel}>
        <p>{ganhador.posicao}ª posição</p>
        <p>Bilhete: {ganhador.numeroDaSorteContemplado}</p>
        <p>Nome: {ganhador.nome}</p>
        <p>Cidade: {ganhador.cidade}</p>
        <p>Prêmio: {ganhador.premio}</p>
      </div>
    )
  }

  function Ganhadores(): ReactElement {
    if (!modoApresentacao) {
      return (
        <div className={styles.tabelaContainer}>
          <div className={styles.numeroSorteado}>
            <h4>Número da Sorte: {apuracao?.numeroDaSorteSorteado}</h4>
          </div>
          <DataTable value={ganhadores} >
            <Column 
              field="numeroDaSorteContemplado" 
              header="Bilhete" 
              headerClassName={styles.tabela} 
            />
            <Column 
              field="nome" 
              header="Nome" 
              headerClassName={styles.tabela}   
            />
            <Column 
              field="cidade" 
              header="Cidade" 
              headerClassName={styles.tabela} 
            />
            <Column 
              field="premio" 
              header="Prêmio"
              headerClassName={styles.tabela} 
            />
            <Column 
              field="statusCupom" 
              header="Status Nota"
              headerClassName={styles.tabela} 
            />
          </DataTable>
        </div>
      )
    } else {
      if (displayCarrosel) {
        return (
          <div className={styles.tabelaContainer}>
            <Carousel 
              value={ganhadores} 
              numVisible={1} 
              itemTemplate={carouselContent} 
            />
          </div>
        )
      }
      return (
        <></>
      )
    }
  }

  function simularApuracao(): void {
    setSortear(false);
    const interval = setInterval(() => {
      let n1 = (Math.floor(Math.random()*100)).toString();
      let n2 = (Math.random()*100000).toFixed(0).toString();
      n1 = String(n1).padStart(2, '0');
      n2 = String(n2).padStart(5, '0');
      setNumeroSorteado(n1.concat('/').concat(n2));
    }, 200);
    setTimeout(() => {
      clearInterval(interval);
      setDisplayCarrosel(true);
      if (apuracao != null) {
        setNumeroSorteado(apuracao.numeroDaSorteSorteado!);
      }
    }, 5000);
  }

  function SorteioArea(): ReactElement {
    if (!modoApresentacao) {
      return (
        <>
          <h1 className={styles.title}>Sorteio</h1>
          <p>Apuração: {sorteio?.nome}</p>
          <p>Número do Certificado: {sorteio?.nrCertificadoSecap}</p>
          <p>Data da Apuração: {dataApuracao}</p>
          <p>
            Data da extração da Loteria Federal: {dataExtracao}&nbsp;
            (<a 
              href="https://loterias.caixa.gov.br/Paginas/Federal.aspx" 
              target="blank"
            >
              abrir site
            </a>)
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.extracao}>
              <label htmlFor="numeroExtracaoLoteria">
                Numero de extração da Loteria Federal:
              </label>
              <div className={styles.formGroup}>
                <Controller 
                  name="numeroExtracaoLoteria"
                  control={control}
                  rules={{ required: 'Campo obrigatório!' }}
                  render={({ field }) => (
                    <InputText
                      className={classNames(
                        { 'p-invalid': errors.numeroExtracaoLoteria }
                      )}
                      keyfilter="int"
                      {...field}
                    />
                  )}
                />
                {errors.numeroExtracaoLoteria?.type == "required" && (
                  <small>{errors.numeroExtracaoLoteria.message}</small>
                )}
              </div>
            </div>
            
            <p>
              Bilhetes:
            </p>
            <div className={styles.row}>
              <div className={styles.formGroup}>
                <Controller 
                  name="bilhete1"
                  control={control}
                  rules={{ required: 'Campo obrigatório!' }}
                  render={({ field }) => (
                    <InputText 
                      placeholder="Bilhete 1"
                      keyfilter="int"
                      className={classNames(
                        { 'p-invalid': errors.bilhete1 }
                      )}
                      {...field}
                    />
                  )}
                />
                {errors.bilhete1?.type == "required" && (
                  <small>{errors.bilhete1.message}</small>
                )}
              </div>
              <div className={styles.formGroup}>
                <Controller 
                  name="bilhete2"
                  control={control}
                  rules={{ required: 'Campo obrigatório!' }}
                  render={({ field }) => (
                    <InputText 
                      placeholder="Bilhete 2"
                      keyfilter="int"
                      className={classNames(
                        { 'p-invalid': errors.bilhete2 }
                      )}
                      {...field}
                    />
                  )}
                />
                {errors.bilhete2?.type == "required" && (
                  <small>{errors.bilhete2.message}</small>
                )}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.formGroup}>
                <Controller 
                  name="bilhete3"
                  control={control}
                  rules={{ required: 'Campo obrigatório!' }}
                  render={({ field }) => (
                    <InputText 
                      placeholder="Bilhete 3"
                      keyfilter="int"
                      className={classNames(
                        { 'p-invalid': errors.bilhete3 }
                      )}
                      {...field}
                    />
                  )}
                />
                {errors.bilhete3?.type == "required" && (
                  <small>{errors.bilhete3.message}</small>
                )}
              </div>
              <div className={styles.formGroup}>
                <Controller 
                  name="bilhete4"
                  control={control}
                  rules={{ required: 'Campo obrigatório!' }}
                  render={({ field }) => (
                    <InputText 
                      placeholder="Bilhete 4"
                      keyfilter="int"
                      className={classNames(
                        { 'p-invalid': errors.bilhete4 }
                      )}
                      {...field}
                    />
                  )}
                />
                {errors.bilhete4?.type == "required" && (
                  <small>{errors.bilhete4.message}</small>
                )}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.formGroup}>
                <Controller 
                  name="bilhete5"
                  control={control}
                  rules={{ required: 'Campo obrigatório!' }}
                  render={({ field }) => (
                    <InputText 
                      placeholder="Bilhete 5"
                      keyfilter="int"
                      className={classNames(
                        { 'p-invalid': errors.bilhete5 }
                      )}
                      {...field}
                    />
                  )}
                /> 
                {errors.bilhete5?.type == "required" && (
                  <small>{errors.bilhete5.message}</small>
                )}  
              </div>
            </div>
            <Button 
              type="submit"
              label="Processar"
              severity="info"
              className={styles.btnForm}
            />
          </form>
        </>
      )
    } else {
      if (!sortear) {
        return (
          <div className={styles.numeroSorteadoArea}>
            <h1>Número da Sorte Sorteado!</h1>
            <h2>{numeroSorteado}</h2>
          </div>
        )
      }
      return (
        <div className={styles.sorteioArea}>
          <Button 
            className={styles.btnSortear}
            label="Sortear!"
            onClick={simularApuracao}
          />
        </div>
      )
    }
  }

  function changeModo(): void {
    setModoApresentacao(!modoApresentacao);
    if (label == 'Apresentar') {
      setLabel('Apurar');
      setSortear(true);
      setDisplayCarrosel(false);
    } else {
      setLabel('Apresentar');
      setSortear(false);
    }
  }

  return (
      <main className={styles.main}>
        <Loading isLoading={isResponseLoading} />
        <Toast ref={toast} />
        <div className={styles.botoes}>
          <Button
            className={styles.btnVoltar}
            icon="pi pi-angle-left"
            onClick={() => {
              navigate(`${RoutesEnum.Sorteio}/${idSorteioValue}/apuracoes`)
            }}
            rounded
          />
          <Button 
          className={styles.btnModo}
            label={label}
            severity="info"
            onClick={changeModo}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.apuracaoContainer}>
            <div className={styles.image}>
              <img src={image} />
            </div>
            <div className={styles.data}>
              <SorteioArea />
            </div>
          </div>
          {ganhadores.length > 0 ? 
            <Ganhadores /> : 
            <div className={styles.vazio}></div>
          }
        </div>
      </main>
  )
}
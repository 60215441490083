import React, {ChangeEvent, Dispatch, ReactElement, SetStateAction, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import {Loading} from "../../../../components/loading/Loading";
import styles from "./ReportForm.module.scss";
import {Button} from "primereact/button";
import ReportController from "../../ReportController";
import ReportResume from "../../../../interfaces/ReportResume";
import {InputTextarea} from "primereact/inputtextarea";
import ReportStatus from "../../../../interfaces/ReportStatus";
import StringUtils from "../../../../utils/StringUtils";

interface ReportFormForm {
    user?: ReportStatus | null;
    set: Dispatch<SetStateAction<string>>;
    value: string;
    report: ReportResume | undefined;
    callback: () => void,
}

const trimSpaces = /^\s+|\s+$/g;

export function ReportForm(props: ReportFormForm): ReactElement {
    const toast = useRef<Toast>(null);
    const [isResponseLoading, setIsResponseLoading] = useState(false);

    const controller = React.useCallback(
        () => new ReportController(setIsResponseLoading, toast),
        [setIsResponseLoading, toast]
    );

    async function send(): Promise<void> {
        if (props.report) {

            const firstName = StringUtils.capitalizeFirstLetter(
                (props.user?.name ?? "").split(" ")[0]
            );

            const text = props
                .value
                .replace("{nome}", firstName)
                .replace(trimSpaces, "");

            await controller().sendAnswer(props.report.id, text)
            props.set("")
            props.callback()
        }
    }

    if (!props.report) return (<></>)

    function formatValue(event: ChangeEvent<HTMLTextAreaElement>): void {
        const text = event.target.value;
        const nativeEvent = event.nativeEvent as any as {
            inputType: string
        };

        console.log(event);

        if (nativeEvent.inputType == "insertFromPaste") {
            props.set(text.replace(trimSpaces, ""));
            return;
        }

        props.set(text);
    }

    return (
        <>
            <Loading isLoading={isResponseLoading}/>
            <Toast ref={toast}/>
            <div>
                <div className={styles.ReportForm}>
                    <div className={styles.column}>
                        <InputTextarea
                            className={styles.inputForm}
                            value={props.value}
                            onChange={(e) => formatValue(e)}
                            disabled={isResponseLoading}
                            autoComplete="on"
                            aria-autocomplete="list"
                        />
                    </div>
                    <div className={styles.columnSend}>
                        <Button className={styles.sendbutton} onClick={send}>
                            <i className="pi pi-send"></i>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

import api from "./Api";
import { AxiosResponse } from "axios";
import token from "./TokeService";
import CupomReplacer from "../interfaces/CupomReplacer";

const getCupomReplacer = (
  page: number,
  size: number,
  enable: string,
  filter: string
): Promise<AxiosResponse> => {
  return api.get(
    `/replacer/find?token=${token()}&enable=${enable}&size=${size}&page=${page}&filter=${filter}`
  );
};

const postCupomReplacer = (body: CupomReplacer): Promise<AxiosResponse> => {
  return api.post(`/replacer/upsert?token=${token()}`, body);
};

const deleteCupomReplacer = (body: CupomReplacer): Promise<AxiosResponse> => {
  return api.post(`/replacer/delete?token=${token()}`, body);
};

const CupomReplacerService = {
  getCupomReplacer,
  postCupomReplacer,
  deleteCupomReplacer,
};

export default CupomReplacerService;

import api from "./Api";
import {AxiosResponse} from "axios";
import token from "./TokeService";

const getList = (): Promise<AxiosResponse> => {
    return api.get(`/depoimentos?token=${token()}`);
}

const DepoimentoService = {
    getList,
};

export default DepoimentoService;

import {Dispatch, RefObject, SetStateAction} from "react";
import DnnUser from "../../interfaces/DnnUser";
import UserService from "../../services/UserService";
import {Toast} from "primereact/toast";
import Extrato from "../../interfaces/Extrato";
import Saque from "../../interfaces/Saque";
import Compartilhamento from "../../interfaces/Compartilhamento";
import Premio from "../../interfaces/Premio";
import Bilhete from "../../interfaces/Bilhete";
import Pesquisa from "../../interfaces/Pesquisa";
import RouteUtils from "../../utils/RouteUtils";
import UserLog from "../../interfaces/UserLog";
import UserApiLog from "../../interfaces/UserApiLog";
import UserApiErrorLog from "../../interfaces/UserApiErrorLog";
import PixInfo from "../../interfaces/PixInfo";
import {createSearchParams} from "react-router-dom";
import token from "../../services/TokeService";
import MissaoUser from "../../interfaces/MissaoUser";

class UserController {
    private readonly dispatchLoading: Dispatch<SetStateAction<boolean>>;
    private readonly toast: RefObject<Toast>;

    constructor(
        dispatchLoading: Dispatch<SetStateAction<boolean>>,
        toast: RefObject<Toast>
    ) {
        this.dispatchLoading = dispatchLoading;
        this.toast = toast;
    }

    async searchUser(param: string): Promise<DnnUser | undefined> {
        try {
            this.dispatchLoading(true);
            const response = await UserService.searchUser(param);
            if (response.status === 200) {
                return response.data.data;
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }
        return {};
    }

async infoUser(
        param: string,
        psql?: boolean
    ): Promise<
        [
            DnnUser,
            Array<Extrato>,
            Array<Saque>,
            Array<Compartilhamento>,
            Array<Premio>,
            Array<Bilhete>,
            Array<Pesquisa>
        ]
    > {
        try {
            this.dispatchLoading(true);
            const response = await UserService.infoUser(param, psql?? false);
            if (response.status === 200) {
                return [
                    response.data.data,
                    response.data.data.extrato,
                    response.data.data.solicitacoes_saque,
                    response.data.data.compartilhamentos,
                    response.data.data.premios.content,
                    response.data.data.bilhetes.content,
                    response.data.data.pesquisas.content,
                ];
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }
        return [{}, [], [], [], [], [], []];
    }

    async getMissaoUser(cpf: string): Promise<Array<MissaoUser>> {
        try {
            this.dispatchLoading(true);
            const response = await UserService.getMissaoUser(cpf);
            if (response.status === 200) {
                return response.data.data;
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }
        return [];
    }

    async getLogUser(param: string): Promise<Array<UserLog>> {
        try {
            this.dispatchLoading(true);
            const response = await UserService.getLogUser(param);
            if (response.status === 200) {
                return response.data.data;
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }
        return [];
    }

    async getApiLogUser(param: string): Promise<Array<UserApiLog>> {
        try {
            this.dispatchLoading(true);
            const response = await UserService.getLogRequestsUser(param);
            if (response.status === 200) {
                return response.data.data;
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }
        return [];
    }

    async getApiErrorLog(param: string): Promise<Array<UserApiErrorLog>> {
        try {
            this.dispatchLoading(true);
            const response = await UserService.getLogErrorUser(param);
            if (response.status === 200) {
                return response.data.data;
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }
        return [];
    }

    updateSaldo(cpf: string): void {
        window.open(UserService.getUrlUpdateSaldo(cpf), "_blank")?.focus();
    }

    goToNotas(cpf: string, year: number, month: number): void {
        const params = {
            year: year.toString(),
            month: (month + 1).toString(),
            cpf: cpf,
            token: token() ?? "",
        };

        window.open(`/notas?${createSearchParams(params)}`)
    }

    goToNotasByYear(cpf: string, year: number): void {
        const params = {
            year: year.toString(),
            month: "0",
            cpf: cpf,
            token: token() ?? "",
        };

        window.open(`/notas?${createSearchParams(params)}`)
    }

    async goToComprovante(id: string): Promise<void> {
        try {
            this.dispatchLoading(true);
            const response = await UserService.abrirComprovante(id);
            if (response.status === 200) {
                RouteUtils.openLink(response.data.data.url);
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }

        return;
    }

    async genTempPassword(cpf: string): Promise<string | undefined> {
        try {
            this.dispatchLoading(true);
            const response = await UserService.genTempPassword({cpf: cpf});
            if (response.status === 200) {
                return response.data.password;
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }

        return;
    }

    async sendTempPassword(cpf: string): Promise<void> {
        try {
            this.dispatchLoading(true);
            const response = await UserService.sendEmailTempPassword(cpf);
            if (response.status === 200) {
                this.toast.current?.show({
                    severity: "success",
                    detail: "Email Enviado com a Senha",
                });
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }

        return;
    }

    async refreshMissaoUser(cpf: string): Promise<void> {
        try {
            this.dispatchLoading(true);
            const response = await UserService.refreshMissaoUser(cpf);
            if (response.status === 200) {
                this.toast.current?.show({
                    severity: "success",
                    detail: "Missao atualizada com sucesso",
                });
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }

        return;
    }

    async cadastrarCompartilhamento(
        convidado: string,
        id: string
    ): Promise<void> {
        if (convidado.length < 11) {
            this.toast.current?.show({
                severity: "error",
                detail: "CPF Incompleto",
            });
            return;
        }
        try {
            this.dispatchLoading(true);
            const response = await UserService.cadastrarCompartilhamento({
                _id: id,
                convidado: convidado,
            });
            if (response.status === 200) {
                this.toast.current?.show({
                    severity: "success",
                    detail: "Compartilhamento cadastrado com sucesso",
                });
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({
                severity: "error",
                detail: "Erro",
            });
        } finally {
            this.dispatchLoading(false);
        }

        return;
    }

    async alterarEmailUsuario(
        id: string,
        emailAtual: string,
        emailNovo: string,
        email: string
    ): Promise<void> {
        if (email.toLowerCase().trim() !== emailAtual) {
            this.toast.current?.show({
                severity: "error",
                detail: "Email atual divergente!",
            });
            return;
        }
        if (emailNovo === emailAtual) {
            this.toast.current?.show({
                severity: "error",
                detail: "Email atual igual ao novo!",
            });
            return;
        }

        try {
            this.dispatchLoading(true);
            const response = await UserService.alterarEmailUsuario({
                _id: id,
                email_novo: emailNovo,
                email_atual: emailAtual,
            });
            if (response.status === 200) {
                this.toast.current?.show({
                    severity: "success",
                    detail: "Email alterado com Sucesso!",
                });
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({
                severity: "error",
                detail: "Erro",
            });
        } finally {
            this.dispatchLoading(false);
        }

        return;
    }

    async cancelarSolicitacaoExclusao(id: string): Promise<boolean> {
        try {
            this.dispatchLoading(true);
            const response = await UserService.cancelarSolicitacaoExclusao(id);
            if (response.status === 200) {
                this.toast.current?.show({
                    severity: "success",
                    detail: "Cancelado a Soliciticação de Exclusão!",
                });
                return false;
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({
                severity: "error",
                detail: "Erro",
            });
        } finally {
            this.dispatchLoading(false);
        }

        return true;
    }

    async getPixInfo(chave: string): Promise<PixInfo | undefined> {
        try {
            this.dispatchLoading(true);
            const response = await UserService.getPixInfo(chave);
            if (response.status === 200) {
                return response.data.data;
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }
        return;
    }
}

export default UserController;

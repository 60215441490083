import api from "./Api";
import {AxiosResponse} from "axios";
import token from "./TokeService";
import PremioSorteio from "../interfaces/PremioSorteio";

function getAllPremiosByIdApuracao(
  idSorteio: number, 
  idApuracao: number
): Promise<AxiosResponse> {
  return api.get(
    `/promocoes/${idSorteio}/apuracoes/${idApuracao}/premios?token=${token()}`
  );
}

function postPremioBySorteioApuracaoId(
  idSorteio: number, 
  idApuracao: number, 
  body: PremioSorteio
): Promise<AxiosResponse> {
  return api.post(
    `/promocoes/${idSorteio}/apuracoes/${idApuracao}/premios?token=${token()}`,
    body
  );
}

function putPremioBySorteioApuracaoId(
  idSorteio: number, 
  idApuracao: number, 
  body: PremioSorteio
): Promise<AxiosResponse> {
  return api.post(
    `/promocoes/${idSorteio}/apuracoes/${idApuracao}/premios/${body.id}/update?token=${token()}`,
    body
  );
}

function deletePremio(
  idSorteio: number, 
  idApuracao: number, 
  idPremio: number
): Promise<AxiosResponse> {
  let url=`/promocoes/${idSorteio}/apuracoes/${idApuracao}/premios/${idPremio}/delete`
  url = url + `?token=${token()}`
  return api.post(url)
}

const PremioSorteioService = {
  getAllPremiosByIdApuracao,
  postPremioBySorteioApuracaoId,
  putPremioBySorteioApuracaoId,
  deletePremio
};

export default PremioSorteioService;

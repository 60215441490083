import React, { ReactElement, useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DateUtils from "../../../../utils/DateUtils";
import styles from "./UserBilhete.module.scss";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import UserBilheteController from "./UserBilheteController";
import { Loading } from "../../../../components/loading/Loading";
import Bilhete from "../../../../interfaces/Bilhete";

interface UserBilheteProps {
  list: Array<Bilhete>;
  cpf: string | undefined;
}

export function UserBilhete(props: UserBilheteProps): ReactElement {
  const [total, setTotal] = useState(10);
  const [bilhetes, setBilhetes] = useState<Array<Bilhete>>(props.list);
  const toast = useRef<Toast>(null);
  const [isResponseLoading, setIsResponseLoading] = useState(false);

  const controller = React.useCallback(
    () => new UserBilheteController(setIsResponseLoading, toast),
    [setIsResponseLoading, toast]
  );

  useEffect(() => {
    async function getBilhetes(): Promise<void> {
      const { cpf } = props;
      if (cpf) {
        setBilhetes(await controller().getBilhetes(cpf, total));
      }
    }
    if (bilhetes.length !== 0) {
      getBilhetes();
    }
  }, []);

  if (props.list.length === 0 || props.cpf === undefined) {
    return <></>;
  }

  const createdAt = (row: Bilhete): string => {
    return DateUtils.format(row.created_at);
  };

  const apuracao = (row: Bilhete): string => {
    return DateUtils.format(row.data_apuracao);
  };

  const totalOp = [
    { label: "10", value: 10 },
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
    { label: "Todos", value: null },
  ];

  const leftContents = (
    <>
      <h4 className={styles.padding}>Últimos:</h4>
      <Dropdown
        optionLabel="label"
        optionValue="value"
        value={total}
        options={totalOp}
        onChange={(e) => setTotal(e.value)}
        placeholder="Todos"
      />
    </>
  );

  return (
    <>
      <Loading isLoading={isResponseLoading} />
      <Toast ref={toast} />
      <div className={styles.UserBilhete}>
        <Toolbar left={leftContents} className={styles.row} />
        <div className={styles.row}>
          <DataTable
            showGridlines
            value={bilhetes}
            scrollable
            scrollHeight="70vh"
          >
            <Column field="nome" header="Promoção" />
            <Column field="status" header="Status" />
            <Column field="bilhete" header="Bilhete" />
            <Column header="Data do bilhete" body={createdAt} />
            <Column header="Apuração" body={apuracao} />
          </DataTable>
        </div>
      </div>
    </>
  );
}

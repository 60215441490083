import React, {ReactElement, useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import DateUtils from "../../../../utils/DateUtils";
import styles from "./UserPesquisa.module.scss";
import {Toolbar} from "primereact/toolbar";
import {Dropdown} from "primereact/dropdown";
import {Toast} from "primereact/toast";
import UserPesquisaController from "./UserPesquisaController";
import {Loading} from "../../../../components/loading/Loading";
import Pesquisa from "../../../../interfaces/Pesquisa";
import NumberUtils from "../../../../utils/NumberUtils";
import {Button} from "primereact/button";

interface UserPesquisaProps {
    list: Array<Pesquisa>;
    cpf: string | undefined;
}

export function UserPesquisa(props: UserPesquisaProps): ReactElement {
    const [total, setTotal] = useState(10);
    const [pesquisas, setPesquisa] = useState<Array<Pesquisa>>(props.list);
    const toast = useRef<Toast>(null);
    const [isResponseLoading, setIsResponseLoading] = useState(false);
    const controller = React.useCallback(
        () => new UserPesquisaController(setIsResponseLoading, toast),
        [setIsResponseLoading, toast]
    );

    useEffect(() => {
        async function getPesquisa(): Promise<void> {
            const {cpf} = props;
            if (cpf) {
                setPesquisa(await controller().getPesquisas(cpf, total));
            }
        }

        if (pesquisas.length !== 0) {
            getPesquisa();
        }
    }, []);

    function postPesquisa(id: number): void {
        const cpf = props.cpf;
        if (cpf) {
            controller().postPesquisa(cpf, id);
        }
    }

    if (props.list.length === 0 || props.cpf === undefined) {
        return <></>;
    }

    const validade = (row: Pesquisa): string => {
        return DateUtils.format(row.valid_until);
    };

    const valor = (row: Pesquisa): string => {
        return NumberUtils.money.format(row.cash_value);
    };

    const dataResposta = (row: Pesquisa): string => {
        if (row.updated_at === null) {
            return "Sem resposta";
        }

        return DateUtils.format(row.updated_at);
    };

    const respondido = (row: Pesquisa): string => {
        return row.answered ? "SIM" : "NÃO";
    };

    const link = (row: Pesquisa): ReactElement => {
        return <a href={row.link}>Abrir Pesquisa</a>;
    };

    const acoes = (row: Pesquisa): ReactElement => {
        return (
            <Button disabled={row.answered} onClick={() => postPesquisa(row.id)}>
                Responder e Liberar Crédito
            </Button>
        );
    };

    const totalOp = [
        {label: "10", value: 10},
        {label: "100", value: 100},
        {label: "500", value: 500},
        {label: "1000", value: 1000},
        {label: "Todos", value: null},
    ];

    const leftContents = (
        <>
            <h4 className={styles.padding}>Últimos:</h4>
            <Dropdown
                optionLabel="label"
                optionValue="value"
                value={total}
                options={totalOp}
                onChange={(e) => setTotal(e.value)}
                placeholder="Todos"
            />
        </>
    );

    return (
        <>
            <Loading isLoading={isResponseLoading}/>
            <Toast ref={toast}/>
            <div className={styles.UserPesquisa}>
                <Toolbar left={leftContents} className={styles.row}/>
                <div className={styles.row}>
                    <DataTable
                        showGridlines
                        value={pesquisas}
                        scrollable
                        scrollHeight="70vh"
                    >
                        <Column field="title" header="Promoção" headerClassName={styles.cell} className={styles.cell}/>
                        <Column header="Respondido" body={respondido} headerClassName={styles.cell}
                                className={styles.cell}/>
                        <Column header="Data da resposta" body={dataResposta} headerClassName={styles.cell}
                                className={styles.cell}/>
                        <Column header="Validade/Prazo" body={validade} headerClassName={styles.cell}
                                className={styles.cell}/>
                        <Column header="Valor" body={valor} headerClassName={styles.cell} className={styles.cell}/>
                        <Column header="Link" body={link} headerClassName={styles.cell} className={styles.cell}/>
                        <Column header="Ações" body={acoes} style={{minWidth: "300px"}}/>
                    </DataTable>
                </div>
            </div>
        </>
    );
}

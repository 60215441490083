import { Dispatch, RefObject, SetStateAction } from "react";
import { Toast } from "primereact/toast";
import CupomReplacer from "../../interfaces/CupomReplacer";
import Page from "../../interfaces/Page";
import CupomReplacerService from "../../services/CupomReplacerService";

class CupomReplacerController {
  private readonly dispatchLoading: Dispatch<SetStateAction<boolean>>;
  private readonly toast: RefObject<Toast>;

  constructor(
    dispatchLoading: Dispatch<SetStateAction<boolean>>,
    toast: RefObject<Toast>
  ) {
    this.dispatchLoading = dispatchLoading;
    this.toast = toast;
  }

  async get(
    enable: string,
    filter: string,
    page: number,
    size: number
  ): Promise<Page<CupomReplacer> | null> {
    try {
      this.dispatchLoading(true);
      const response = await CupomReplacerService.getCupomReplacer(
        page,
        size,
        enable,
        filter
      );
      if (response.status === 200) {
        return response.data.data.page;
      }
    } catch (e) {
      console.error(e);
      this.toast.current?.show({ severity: "error", detail: "Erro" });
    } finally {
      this.dispatchLoading(false);
    }
    return null;
  }

  async upsert(cupom: CupomReplacer): Promise<void> {
    try {
      this.dispatchLoading(true);
      const response = await CupomReplacerService.postCupomReplacer(cupom);
      if (response.status === 200) {
        this.toast.current?.show({
          severity: "success",
          detail: "Cupom replacer criado/editado com Sucesso!",
        });
      }
    } catch (e) {
      console.error(e);
      this.toast.current?.show({ severity: "error", detail: "Erro" });
    } finally {
      this.dispatchLoading(false);
    }
    return;
  }

  async delete(cupom: CupomReplacer): Promise<void> {
    try {
      this.dispatchLoading(true);
      const response = await CupomReplacerService.deleteCupomReplacer(cupom);
      if (response.status === 200) {
        this.toast.current?.show({
          severity: "success",
          detail: "Cupom replacer deletado com Sucesso!",
        });
      }
    } catch (e) {
      console.error(e);
      this.toast.current?.show({ severity: "error", detail: "Erro" });
    } finally {
      this.dispatchLoading(false);
    }
    return;
  }
}

export default CupomReplacerController;

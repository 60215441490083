import { Dispatch, RefObject, SetStateAction } from "react";
import { Toast } from "primereact/toast";
import UserService from "../../../../services/UserService";
import Bilhete from "../../../../interfaces/Bilhete";

class UserBilheteController {
  private readonly dispatchLoading: Dispatch<SetStateAction<boolean>>;
  private readonly toast: RefObject<Toast>;

  constructor(
    dispatchLoading: Dispatch<SetStateAction<boolean>>,
    toast: RefObject<Toast>
  ) {
    this.dispatchLoading = dispatchLoading;
    this.toast = toast;
  }

  async getBilhetes(cpf: string, size: number | null): Promise<Array<Bilhete>> {
    try {
      this.dispatchLoading(true);
      const response = await UserService.getBilhetes(cpf, size);
      if (response.status === 200) {
        return response.data.data.bilhetes.content;
      }
    } catch (e) {
      console.error(e);
      this.toast.current?.show({ severity: "error", detail: "Erro" });
    } finally {
      this.dispatchLoading(false);
    }
    return [];
  }
}

export default UserBilheteController;

import React, {ReactElement} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";

import RoutesEnum from "./properties/RoutesEnum";
import {LoginPage} from "./pages/login/LoginPage";
import {MenuBO} from "./components/menu_bo/MenuBO";
import {UserPage} from "./pages/user/UserPage";
import {ChatPage} from "./pages/chat/ChatPage";
import {CupomReplacerPage} from "./pages/cupom_replacer/CupomReplacerPage";
import {NotasPage} from "./pages/notas/NotasPage";
import {DepoimentoPage} from "./pages/depoimento/DepoimentoPage";
import {ReportPage} from "./pages/report/ReportPage";
import {SaquePage} from "./pages/saque/SaquePage";
import { SorteioPage } from "./pages/sorteio/SorteioPage";
import { ApuracaoPage } from "./pages/sorteio/apuracao/ApuracaoPage";
import { PremioSorteioPage } from "./pages/sorteio/premio/PremioSorteioPage";
import { ApuracaoSorteioPage } from "./pages/sorteio/apuracao/ApuracaoSorteioPage";
import { ApuracaoDocsPage } from "./pages/sorteio/apuracao/ApuracaoDocsPage";
import { ApuracaoInfoSCPCPage } from "./pages/sorteio/apuracao/ApuracaoInfoSCPCPage";

function App(): ReactElement {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<LoginPage/>}/>
                    <Route path={RoutesEnum.Login} element={<MenuBO/>}/>
                    <Route
                        path={`${RoutesEnum.User}/:cpf`}
                        element={<UserPage enable={true}/>}
                    />
                    <Route path={RoutesEnum.User} element={<UserPage enable={true}/>}/>
                    <Route path={RoutesEnum.Chat} element={<ChatPage/>}/>
                    <Route path={`${RoutesEnum.Chat}/:cpf`} element={<ChatPage/>}/>
                    <Route
                        path={RoutesEnum.CupomReplacer}
                        element={<CupomReplacerPage/>}
                    />
                    <Route path={RoutesEnum.Notas} element={<NotasPage/>}/>
                    <Route path={RoutesEnum.Depoimentos} element={<DepoimentoPage/>}/>
                    <Route path={RoutesEnum.Report} element={<ReportPage/>}/>
                    <Route path={`${RoutesEnum.Report}/:cpf`} element={<ReportPage/>}/>
                    <Route path={`${RoutesEnum.Saque}`} element={<SaquePage/>}/>
                    <Route path={RoutesEnum.Sorteio} element={<SorteioPage />} />
                    <Route path={`${RoutesEnum.Sorteio}/:idSorteio/apuracoes`} element={<ApuracaoPage />} />
                    <Route path={`${RoutesEnum.Sorteio}/:idSorteio/apuracoes/:idApuracao`} element={<ApuracaoSorteioPage />} />
                    <Route path={`${RoutesEnum.Sorteio}/:idSorteio/apuracoes/:idApuracao/premios`} element={<PremioSorteioPage />} />
                    <Route path={`${RoutesEnum.Sorteio}/:idSorteio/apuracoes/:idApuracao/documentos`} element={<ApuracaoDocsPage />} />
                    <Route path={`${RoutesEnum.Sorteio}/:idSorteio/apuracoes/:idApuracao/informacoes`} element={<ApuracaoInfoSCPCPage />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;

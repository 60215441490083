type TagSeverityType = 
    null | "success" | "warning" | "info" | "danger";

const getStatus = (status: string): string => {
    switch (status) {
        case "analise":
            return "Em análise"
        case "cancelado":
            return "Cancelado pelo usuário"
        case "respondido":
            return "Respondido"
    }

    return "Desconhecido"
}

const getStatusTag = (status: string): TagSeverityType => {
    switch (status) {
        case "analise":
            return "danger"
        case "cancelado":
            return "info"
        case "respondido":
            return "success"
    }

    return "warning"
}

const ReportUtils = {
    getStatus,
    getStatusTag
}

export default ReportUtils

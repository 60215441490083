import React, { ReactElement } from "react";
import styles from "./MenuItem.module.scss";
import { Tooltip } from "primereact/tooltip";

interface ItemProps {
  label: string;
  icon: string;
  route: string;
}

export function MenuItem(props: ItemProps): ReactElement {
  return (
    <>
      <Tooltip target={`.${styles.Item}`} mouseTrack mouseTrackLeft={10} />
      <div
        className={styles.Item}
        data-pr-tooltip={props.label}
        data-pr-position="right"
      >
        <a href={props.route}>
          <i className={`pi ${props.icon}`} />
        </a>
      </div>
    </>
  );
}

enum RoutesEnum {
    Login = "/login",
    User = "/user",
    Chat = "/chat",
    CupomReplacer = "/replacer",
    Notas = "/notas",
    Depoimentos = '/depoimentos',
    Report = '/report',
    Saque = '/saque',
    Sorteio = '/sorteios'
}

export default RoutesEnum;

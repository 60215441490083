import token from "../services/TokeService";

const changeRoute = (path: string, params: string): void => {
  window.history.replaceState(
    null,
    `${path}/${params}`,
    `${path}/${params}?token=${token()}`
  );
};

const openLink = (url: string): void => {
  window.open(url, "_blank")?.focus();
};

const RouteUtils = {
  changeRoute,
  openLink,
};

export default RouteUtils;

import {Dispatch, RefObject, SetStateAction} from "react";
import {Toast} from "primereact/toast";
import Depoimento from "../../interfaces/Depoimento";
import DepoimentoService from "../../services/DepoimentoService";

class DepoimentoController {
    private readonly dispatchLoading: Dispatch<SetStateAction<boolean>>;
    private readonly toast: RefObject<Toast>;

    constructor(
        dispatchLoading: Dispatch<SetStateAction<boolean>>,
        toast: RefObject<Toast>
    ) {
        this.dispatchLoading = dispatchLoading;
        this.toast = toast;
    }

    async get(): Promise<Array<Depoimento> | undefined> {
        try {
            this.dispatchLoading(true);
            const response = await DepoimentoService.getList();
            if (response.status === 200) {
                return response.data.data;
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }
        return;
    }
}

export default DepoimentoController;

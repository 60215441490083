export type EstadoBrasileiro = 
  | 'AC' // Acre
  | 'AL' // Alagoas
  | 'AP' // Amapá
  | 'AM' // Amazonas
  | 'BA' // Bahia
  | 'CE' // Ceará
  | 'ES' // Espírito Santo
  | 'GO' // Goiás
  | 'MA' // Maranhão
  | 'MT' // Mato Grosso
  | 'MS' // Mato Grosso do Sul
  | 'MG' // Minas Gerais
  | 'PA' // Pará
  | 'PB' // Paraíba
  | 'PR' // Paraná
  | 'PE' // Pernambuco
  | 'PI' // Piauí
  | 'RJ' // Rio de Janeiro
  | 'RN' // Rio Grande do Norte
  | 'RS' // Rio Grande do Sul
  | 'RO' // Rondônia
  | 'RR' // Roraima
  | 'SC' // Santa Catarina
  | 'SP' // São Paulo
  | 'SE' // Sergipe
  | 'TO' // Tocantins
  | 'DF' // Distrito Federal;

export class EstadosUtils {

  private siglas: string[];
  private nomes: string[];

  constructor() {
    this.siglas = [
      'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'ES', 'GO', 'MA', 'MT', 'MS',
      'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR',
      'SC', 'SP', 'SE', 'TO', 'DF'
    ]
    this.nomes = [
      'Acre',
      'Alagoas',
      'Amapá',
      'Amazonas',
      'Bahia',
      'Ceará',
      'Espírito Santo',
      'Goiás',
      'Maranhão',
      'Mato Grosso',
      'Mato Grosso do Sul',
      'Minas Gerais',
      'Pará',
      'Paraíba',
      'Paraná',
      'Pernambuco',
      'Piauí',
      'Rio de Janeiro',
      'Rio Grande do Norte',
      'Rio Grande do Sul',
      'Rondônia',
      'Roraima',
      'Santa Catarina',
      'São Paulo',
      'Sergipe',
      'Tocantins',
      'Distrito Federal'
    ]
  }

  findNomeBySigla(sigla: EstadoBrasileiro): string {
    const value = this.siglas.findIndex(element => element == sigla);
    if (value == -1) {
      return '';
    }
    return this.nomes[value];
  }

  getSiglas(): string[] {
    return this.siglas;
  }

  getNomes(): string[] {
    return this.nomes;
  }

}
import {Dispatch, RefObject, SetStateAction} from "react";
import {Toast} from "primereact/toast";
import ChatService from "../../services/ChatService";
import ChatStatus from "../../interfaces/ChatStatus";
import ChatMessage from "../../interfaces/ChatMessage";
import ChatStatusContador from "../../interfaces/ChatStatusContador";

class ChatPageController {
    private readonly dispatchLoading: Dispatch<SetStateAction<boolean>>;
    private readonly toast: RefObject<Toast>;

    constructor(
        dispatchLoading: Dispatch<SetStateAction<boolean>>,
        toast: RefObject<Toast>
    ) {
        this.dispatchLoading = dispatchLoading;
        this.toast = toast;
    }

    async getUserList(estado: string, userFilter: string | undefined | null): Promise<Array<ChatStatus>> {
        try {
            this.dispatchLoading(true);
            const response = userFilter ? await ChatService.getUserListByFilterUser(estado, userFilter) : await ChatService.getUserList(estado)
            if (response.status === 200) {
                return response.data.list;
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }
        return [];
    }

    async getChatUser(cpf: string): Promise<Array<ChatMessage>> {
        try {
            const response = await ChatService.getChatUser(cpf);
            if (response.status === 200) {
                return response.data.list;
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        }
        return [];
    }

    async getChatStatus(): Promise<Array<ChatStatusContador>> {
        try {
            const response = await ChatService.getChatUserStatus();
            if (response.status === 200) {
                return response.data.list;
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        }
        return [];
    }
}

export default ChatPageController;

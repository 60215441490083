import styles from "./ChatCard.module.scss";
import { Card } from "primereact/card";
import { Badge } from "primereact/badge";
import ChatMessage from "../../../../interfaces/ChatMessage";
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { InputTextarea } from "primereact/inputtextarea";

interface ChatCardProps {
  msg: ChatMessage;
  pauseUpdate: Dispatch<SetStateAction<boolean>>;
}

export default function ChatCard(props: ChatCardProps): ReactElement {
  const isUser = props.msg.sender === "USER";
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(props.msg.message);

  useEffect(() => {
    setValue(props.msg.message);
  }, [props.msg]);

  const start = (): void => {
    props.pauseUpdate(true);
    setEdit(true);
  };

  const close = (): void => {
    props.pauseUpdate(false);
    setValue(props.msg.message);
    setEdit(false);
  };

  const save = (): void => {
    props.pauseUpdate(false);
    setEdit(false);
  };

  return (
    <>
      <div className={styles.ChatCard}>
        <div className={isUser ? styles.user : styles.dnn}>
          <div className="p-overlay-badge">
            <Card className={styles.body}>
              {edit ? (
                <>
                  <InputTextarea
                    rows={5}
                    cols={50}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                  <Badge
                    className={styles.cancelar}
                    value="CANCELAR"
                    severity="danger"
                    onClick={close}
                  />
                  {!isUser ? (
                    <Badge
                      className={styles.salvar}
                      value="SALVAR"
                      severity="success"
                      onClick={save}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  {value}
                  <Badge
                    className={styles.time}
                    value={props.msg.date_text.split(" ")[1]}
                    severity={isUser ? "info" : "success"}
                  />
                  {!isUser ? (
                    <Badge
                      className={styles.editar}
                      value="EDITAR"
                      severity={isUser ? "info" : "success"}
                      onClick={start}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { ReactElement, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import UserController from "../../UserController";
import UserLog from "../../../../interfaces/UserLog";
import DnnUser from "../../../../interfaces/DnnUser";
import DateUtils from "../../../../utils/DateUtils";
import styles from "./UserActionLog.module.scss";

interface UserActionLogProps {
  controller: UserController;
  user: DnnUser;
}

export function UserActionLogTable(props: UserActionLogProps): ReactElement {
  const [userLogs, setUserLogs] = useState<Array<UserLog>>([]);

  async function loadLog(): Promise<void> {
    const data = await props.controller.getLogUser(props.user.cpf ?? "");
    if (data.length > 0) {
      setUserLogs(data);
    }
  }

  useEffect(() => {
    loadLog();
  }, [props.user]);

  const createdAt = (row: UserLog): string => {
    return DateUtils.formatFullTime(row.created_at);
  };

  const message = (row: UserLog): ReactElement => {
    return <p className={styles.info}>{row.message}</p>;
  };

  const cupom = (row: UserLog): ReactElement => {
    return <p className={styles.info}>{row.cupom}</p>;
  };

  const component = (row: UserLog): ReactElement => {
    return <p className={styles.info}>{row.component}</p>;
  };

  const status = (row: UserLog): ReactElement => {
    return <p className={styles.info}>{row.status}</p>;
  };

  const sessionId = (row: UserLog): ReactElement => {
    return <p className={styles.info}>{row.sessionId}</p>;
  };

  const eventType = (row: UserLog): ReactElement => {
    return <p className={styles.info}>{row.eventType}</p>;
  };

  const pageList = (row: UserLog): ReactElement => {
    return <p className={styles.info}>{row.pageList}</p>;
  };

  return (
    <>
      <div className={styles.UserActionLog}>
        <DataTable value={userLogs} scrollable scrollHeight="90vh">
          <Column body={createdAt} header="created_at" className={styles.box} />
          <Column body={status} header="status" className={styles.box} />
          <Column body={sessionId} header="sessionId" className={styles.box} />
          <Column body={component} header="component" className={styles.box} />
          <Column body={eventType} header="eventType" className={styles.box} />
          <Column body={pageList} header="pageList" className={styles.box} />
          <Column body={message} header="message" className={styles.box} />
          <Column body={cupom} header="cupom" className={styles.box} />
        </DataTable>
      </div>
    </>
  );
}

import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import CupomReplacer from "../../../interfaces/CupomReplacer";
import CupomReplacerController from "../CupomReplacerController";
import styles from "./FormReplacer.module.scss";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { Inplace, InplaceDisplay, InplaceContent } from "primereact/inplace";
import "./Prime.css";
import RouteUtils from "../../../utils/RouteUtils";

interface FormReplacerProps {
  setDisplay: Dispatch<SetStateAction<boolean>>;
  display: boolean;
  cupom: CupomReplacer | null;
  controller: CupomReplacerController;
}

export function FormReplacer(props: FormReplacerProps): ReactElement {
  const [label, setLabel] = useState("Cupom Replacer");
  const [findStart, setFindStart] = useState(props?.cupom?.find_start ?? "");
  const [findLenght, setFindLenght] = useState(props?.cupom?.find_lenght ?? "");
  const [find, setFind] = useState(props?.cupom?.find ?? "");
  const [replaceStart, setReplaceStart] = useState(
    props?.cupom?.replace_start ?? ""
  );
  const [replaceLenght, setReplaceLenght] = useState(
    props?.cupom?.replace_lenght ?? ""
  );
  const [replace, setReplace] = useState(props?.cupom?.replace ?? "");

  const [totalSize, setTotalSize] = useState(props?.cupom?.total_size ?? "");

  const [enable, setEnable] = useState(props?.cupom?.enable ?? false);

  const options = [
    { name: "Ativado", value: true },
    { name: "Desativado", value: false },
  ];

  const [priority, setPriority] = useState(props?.cupom?.priority ?? "");

  function buildCupom(): CupomReplacer {
    return {
      id: props.cupom?.id ?? null,
      enable: enable,
      find_start: parseInt(`${findStart}`),
      find_lenght: parseInt(`${findLenght}`),
      find: find,
      total_size: parseInt(`${totalSize}`),
      replace: replace,
      replace_lenght: parseInt(`${replaceLenght}`),
      replace_start: parseInt(`${replaceStart}`),
      priority: parseInt(`${priority}`),
      createdAt: "",
    };
  }

  const save = (): void => {
    const cupom = buildCupom();

    props.controller.upsert(cupom).then((_) => props.setDisplay(false));
  };

  function Possibilidades(): ReactElement {
    const list = props.cupom?.replace.split(",");

    const match = props.cupom?.replace.match("(\\d{44})");
    if (match?.length === 0) {
      return <></>;
    }

    if (match && list) {
      const chave = match[0];
      const cnpjs = list.map((it) => {
        it = it.replaceAll("[", "");
        if (it.includes("]")) {
          it = it.substring(0, it.indexOf("]"));
        }
        return it.trim();
      });
      return (
        <div className={styles.column}>
          {cnpjs.map((elem, index) => {
            const start = props.cupom?.replace_start ?? 0;
            const end = (props.cupom?.replace_lenght ?? 0) + start;
            const candidato =
              chave.substring(0, start - 1) + elem + chave.substring(end - 1);
            return (
              <div key={index} className={styles.row}>
                <Button
                  label={elem}
                  aria-label="Submit"
                  onClick={() =>
                    RouteUtils.openLink(
                      `https://satsp.fazenda.sp.gov.br/COMSAT/Public/ConsultaPublica/ConsultaCupomFiscalEletronico.aspx?id=${candidato}`
                    )
                  }
                />
                <Button
                  icon="pi pi-check"
                  className={styles.icon}
                  onClick={() => {
                    setReplace(elem);
                    setEnable(true);
                  }}
                />
              </div>
            );
          })}
        </div>
      );
    }

    return <></>;
  }

  const footer = (
    <div className={styles.actions}>
      <Button
        label="Cancelar"
        className={`p-button-danger ${styles.btn}`}
        icon="pi pi-times"
        onClick={() => props.setDisplay(false)}
      />
      <Inplace>
        <InplaceDisplay>
          <Button
            label="Possibilidades"
            className={`p-button-info ${styles.btn}`}
            icon="pi pi-cog"
          />
        </InplaceDisplay>
        <InplaceContent>
          <Possibilidades />
        </InplaceContent>
      </Inplace>
      <Button
        label="Salvar"
        className={`p-button-success ${styles.btn}`}
        icon="pi pi-check"
        onClick={save}
      />
    </div>
  );

  useEffect(() => {
    if (props?.cupom) {
      setLabel("Editar");
    } else {
      setLabel("Criar");
    }

    setFindStart(props?.cupom?.find_start ?? "");
    setFindLenght(props?.cupom?.find_lenght ?? "");
    setFind(props?.cupom?.find ?? "");
    setReplaceStart(props?.cupom?.replace_start ?? "");
    setReplaceLenght(props?.cupom?.replace_lenght ?? "");
    setReplace(props?.cupom?.replace ?? "");
    setTotalSize(props?.cupom?.total_size ?? "");
    setPriority(props?.cupom?.priority ?? "");
    setEnable(props?.cupom?.enable ?? false);
  }, [props.display, props.cupom]);

  return (
    <Dialog
      header={label}
      visible={props.display}
      style={{ width: "60vw" }}
      onHide={() => props.setDisplay(false)}
      className={styles.form}
      footer={footer}
    >
      <div className="grid p-fluid">
        <div className="col-12 md:col-4">
          <div className={`p-inputgroup ${styles.input}`}>
            <span className="p-inputgroup-addon">find_start</span>
            <InputText
              placeholder="find_start"
              value={findStart as string}
              onChange={(e) => setFindStart(e.target.value)}
            />
            <span className="p-inputgroup-addon">find_lenght</span>
            <InputText
              placeholder="find_lenght"
              value={findLenght as string}
              onChange={(e) => setFindLenght(e.target.value)}
            />
            <span className="p-inputgroup-addon">find</span>
            <InputText
              placeholder="find"
              value={find}
              onChange={(e) => setFind(e.target.value)}
            />
          </div>
        </div>

        <div className="col-12 md:col-4">
          <div className={`p-inputgroup ${styles.input}`}>
            <span className="p-inputgroup-addon">replace_start</span>
            <InputText
              placeholder="replace_start"
              value={replaceStart as string}
              onChange={(e) => setReplaceStart(e.target.value)}
            />
            <span className="p-inputgroup-addon">replace_lenght</span>
            <InputText
              placeholder="replace_lenght"
              value={replaceLenght as string}
              onChange={(e) => setReplaceLenght(e.target.value)}
            />
          </div>
        </div>

        <div className="col-12 md:col-4">
          <div className={`p-inputgroup ${styles.input}`}>
            <span className="p-inputgroup-addon">replace</span>
            <InputText
              placeholder="replace"
              value={replace}
              onChange={(e) => setReplace(e.target.value)}
            />
          </div>
        </div>

        <div className="col-12 md:col-4 ">
          <div className={`p-inputgroup ${styles.input}`}>
            <span className="p-inputgroup-addon">total_size</span>
            <InputText
              placeholder="total_size"
              value={totalSize as string}
              onChange={(e) => setTotalSize(e.target.value)}
            />
            <span className="p-inputgroup-addon">priority</span>
            <InputText
              placeholder="priority"
              value={priority as string}
              onChange={(e) => setPriority(e.target.value)}
            />

            <SelectButton
              value={enable}
              options={options}
              onChange={(e) => setEnable(e.value)}
              optionLabel="name"
              className={styles.select}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}

import { Dispatch, RefObject, SetStateAction } from "react";
import Sorteio from "../../interfaces/Sorteio";
import SorteioService from "../../services/SorteioService";
import { Toast } from "primereact/toast";
import Page from "../../interfaces/Page";

export default class SorteioController {

  private readonly dispatchLoading: Dispatch<SetStateAction<boolean>>;
  private readonly toast: RefObject<Toast>;

  constructor(
    dispatchLoading: Dispatch<SetStateAction<boolean>>,
    toast: RefObject<Toast>
  ) {
    this.dispatchLoading = dispatchLoading;
    this.toast = toast;
  }

  async getSorteios(
    page: number, 
    size: number, 
    sort: string
  ): Promise<Page<Sorteio> | null> {
    try {
      this.dispatchLoading(true);
      const response = await SorteioService.getAllSorteios(page, size, sort);
      if (response.status === 200) {
        const sorteiosResponse = response.data;
        return sorteiosResponse;
      }
    } catch(e) {
      console.error(e);
      this.toast.current?.show({severity: "error", detail: "Erro"});
    } finally {
      this.dispatchLoading(false);
    }
    return null;
  }

  async createSorteio(sorteio: Sorteio): Promise<void> {
    try {
      this.dispatchLoading(true);
      const response = await SorteioService.postSorteio(sorteio);
      if (response.status === 201) {
        this.toast.current?.show({
          severity: "success",
          detail: "Sorteio criado com sucesso!"
        });
      }
    } catch(e) {
      console.error(e);
      this.toast.current?.show({severity: "error", detail: "Erro"});
    } finally {
      this.dispatchLoading(false)
    }
  }

  async updateSorteio(sorteio: Sorteio): Promise<void> {
    try {
      this.dispatchLoading(true);
      const response = await SorteioService.putSorteio(sorteio);
      if (response.status === 200) {
        this.toast.current?.show({
          severity: "success",
          detail: "Sorteio editado com sucesso!"
        });
      }
    } catch(e) {
      console.error(e);
      this.toast.current?.show({severity: "error", detail: "Erro"});
    } finally {
      this.dispatchLoading(false)
    }
  }

  async removeSorteio(idSorteio: number): Promise<void> {
    try {
      this.dispatchLoading(true);
      const response = await SorteioService.deleteSorteio(idSorteio);
      if (response.status === 204) {
        this.toast.current?.show({
          severity: "success",
          detail: "Sorteio removido com sucesso!",
        });
      }
    } catch(e) {
      console.error(e);
      this.toast.current?.show({severity: "error", detail: "Erro"});
    } finally {
      this.dispatchLoading(false)
    }
  }
 
}


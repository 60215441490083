import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styles from "./ApuracaoDocsPage.module.scss";
import { Toast } from 'primereact/toast';
import ApuracaoController from './ApuracaoController';
import { useNavigate, useParams } from 'react-router-dom';
import Apuracao from '../../../interfaces/Apuracao';
import Sorteio from '../../../interfaces/Sorteio';
import Ganhador from '../../../interfaces/Ganhador';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Loading } from '../../../components/loading/Loading';
import { Button } from 'primereact/button';
import RoutesEnum from '../../../properties/RoutesEnum';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export function ApuracaoDocsPage(): ReactElement {

  const [apuracao, setApuracao] = useState<Apuracao | null>(null);
  const [sorteio, setSorteio] = useState<Sorteio | null>(null);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [ganhadores, setGanhadores] = useState<Ganhador[]>([]);

  const toast = useRef<Toast>(null);
  const ata = useRef<HTMLDivElement | null>(null);
  const recibo = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();
  const { idSorteio, idApuracao } = useParams();
  const idSorteioValue = idSorteio ? parseInt(idSorteio) : NaN;
  const idApuracaoValue = idApuracao ? parseInt(idApuracao) : NaN;

  const controller = new ApuracaoController(setIsResponseLoading, toast);

  function formatarData(data: string | undefined): string {
    if (data != undefined) {
      return format(
        data,
        'dd/MM/yyyy',
        { locale: ptBR }
      );
    }
    return '';
  }

  function desformatarNumerosSorteados(numerosSorteados: string | null | undefined): string {
    if (numerosSorteados == null ||
      numerosSorteados == undefined
    ) {
      return '';
    }
    let numerosDesformatados = numerosSorteados.slice(1, -1);
    numerosDesformatados = numerosDesformatados.replace(/'/g, '');
    return numerosDesformatados;
  }

  async function getSorteio(): Promise<void> {
    const sorteioResponse =
      await controller.getSorteioFromApuracao(idSorteioValue);
    if (sorteioResponse != undefined)
      setSorteio(sorteioResponse);
  }

  async function getGanhadores(): Promise<void> {
    await controller.buscarGanhadoresApuracao(idApuracaoValue)
      .then((data) => {
        setGanhadores(data);
      });
  }

  async function getApuracao(): Promise<void> {
    await controller.buscarApuracaoPeloId(idApuracaoValue, false)
      .then((data) => {
        if (data != undefined)
          setApuracao(data);
      });
  }

  useEffect(() => {
    getSorteio();
    getApuracao();
    getGanhadores();
  }, []);

  function downloadAta(): void {
    const input = ata.current;
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('ATA DA APURAÇÃO DOS PRÊMIOS.pdf');
      });
    }
  }

  function downloadRecibo(): void {
    const input = recibo.current;
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'landscape'
        });
        const imgWidth = 297;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('RECIBO DE ENTREGA DOS PRÊMIOS.pdf');
      });
    }
  }

  return (
    <div className={styles.main}>
      <Loading isLoading={isResponseLoading} />
      <Toast ref={toast} />
      <div className={styles.botoes}>
        <Button
          className={styles.btnVoltar}
          icon="pi pi-angle-left"
          onClick={() => {
            navigate(`${RoutesEnum.Sorteio}/${idSorteioValue}/apuracoes`)
          }}
          rounded
        />
        <Button 
          label="Ata"
          icon="pi pi-download"
          severity="info"
          onClick={downloadAta}
          rounded
        />
        <Button 
          label="Recibo"
          icon="pi pi-download"
          severity="info"
          onClick={downloadRecibo}
          rounded
        />
      </div>
      <div className={styles.documentos}>
        <div 
          className={styles.ata}
          ref={ata}
        >
          <h3 className={styles.titulo}>
            ATA DA APURAÇÃO DA DISTRIBUIÇÃO GRATUITA DE PRÊMIOS, REFERENTE À PROMOÇÃO {sorteio?.nome}, 
            REALIZADA PELA EMPRESA BENCHMKT INTELIGÊNCIA E PESQUISAS DE MERCADO LTDA
          </h3>
          <p>
            No dia {formatarData(apuracao?.data_apuracao)}, às 11 horas, na sala de conferência virtual Google Meet, no link https://meet.google.com/wse-jygm-qfz, 
            realizou-se a apuração dos prêmios ofertados na promoção {sorteio?.nome}, autorizada pela SECAP sob o nº.{sorteio?.nrCertificadoSecap}. 
            Na oportunidade estavam presentes Eduardo Zanoni Marques, Júlio Esslinger Viégas e Harry Haim Ballas.
          </p> 
          <p>
            No horário previsto, deu-se o início da apuração, obedecendo ao critério estabelecido no regulamento aprovado, que ocorreu da seguinte maneira: 
            consultamos o resultado da Loteria Federal de {formatarData(apuracao?.dataExtracaoLoteriaFederal)} (concurso {apuracao?.numeroExtracaoLoteriaFederal}), 
            cujos números foram {desformatarNumerosSorteados(apuracao?.numerosSorteadosPelaExtracaoLoteriaFederal)}. 
            A partir deste resultado, calculamos o número da sorte sorteado, cujo valor foi {apuracao?.numeroDaSorteSorteado}. 
            Na sequência consultamos os ganhadores, que são os números da sorte mais próximos ao número da sorte sorteado. 
            Por fim, verificamos se os premiados atendiam o critério de participação, não havendo sido desclassificado nenhum dos ganhadores.
          </p>
          <p>
            Os contemplados foram, na ordem de sorteio:&nbsp;
            {ganhadores.map(item => `${item.posicao}º) ${item.nome}, CPF ${item.cpf}`).join("; ")}.
          </p>  
          <p>
            Após encerrada a promoção, lavrou-se a presente ata que segue assinada para os efeitos legais.
          </p>
          <p className={styles.alignEnd}>
            Campinas, {formatarData(apuracao?.data_apuracao)}.
          </p>

          <div className={styles.linhaAssinatura}></div>
          <p className={styles.nomeAssinatura}>
            Representante da Empresa Promotora - Eduardo Zanoni Marques - 049.090.719-97
          </p>

          <div className={styles.linhaAssinatura}></div>
          <p className={styles.nomeAssinatura}>
            1ª Testemunha - Júlio Esslinger Viégas - 325.079.058-32
          </p>

          <div className={styles.linhaAssinatura}></div>
          <p className={styles.nomeAssinatura}>
            2ª Testemunha - Harry Haim Ballas - 064.289.778-69
          </p>
        </div>

        <div 
          className={styles.recibo}
          ref={recibo}
        >
          <DataTable 
            value={ganhadores} 
            showGridlines
            size="small"
          >
            <Column 
              field="premio"
              header="Descrição do Prêmio" 
              headerClassName={styles.header} 
              bodyClassName={styles.content}
            />
            <Column 
              field="nome" 
              header="Nome do contemplado" 
              headerClassName={styles.header}
              bodyClassName={styles.content}
            />
            <Column 
              field="cpf" 
              header="CPF" 
              headerClassName={styles.header} 
              bodyClassName={styles.content}
            />
            <Column 
              field="cidade" 
              header="Endereço do contemplado"
              headerClassName={styles.header}
              bodyClassName={styles.content}
            />
          </DataTable>
        </div>
      </div>
    </div>
    
  )
}
import { Dispatch, RefObject, SetStateAction } from "react";
import { Toast } from "primereact/toast";
import PremioSorteioService from "../../../services/PremioSorteioService";
import PremioSorteio from "../../../interfaces/PremioSorteio";

export default class PremioSorteioController {

  private readonly dispatchLoading: Dispatch<SetStateAction<boolean>>;
  private readonly toast: RefObject<Toast>;

  constructor(
    dispatchLoading: Dispatch<SetStateAction<boolean>>,
    toast: RefObject<Toast>
  ) {
    this.dispatchLoading = dispatchLoading;
    this.toast = toast;
  }

  async getPremiosByIdApuracao(
    idSorteio: number, 
    idApuracao: number
  ): Promise<PremioSorteio[]> {
    try {
      this.dispatchLoading(true);
      const response = await PremioSorteioService
        .getAllPremiosByIdApuracao(idSorteio, idApuracao);
      if (response.status === 200) {
        const apuracoesResponse = response.data
        return apuracoesResponse;
      }
    } catch(e) {
      console.error(e);
      this.toast.current?.show({severity: "error", detail: "Erro"});
    } finally {
      this.dispatchLoading(false);
    }
    return [];
  }

  async createPremioByIdApuracao(
    idSorteio: number, 
    idApuracao: number, 
    premio: PremioSorteio
  ): Promise<void> {
    try {
      this.dispatchLoading(true);
      const response = await PremioSorteioService
        .postPremioBySorteioApuracaoId(idSorteio, idApuracao, premio);
      if (response.status === 201) {
        this.toast.current?.show({
          severity: "success",
          detail: "Prêmio criado com sucesso!"
        });
      }
    } catch(e) {
      console.error(e);
      this.toast.current?.show({severity: "error", detail: "Erro"});
    } finally {
      this.dispatchLoading(false)
    }
  }

  async updatePremioByIdApuracao(
    idSorteio: number, 
    idApuracao: number, 
    premio: PremioSorteio
  ): Promise<void> {
    try {
      this.dispatchLoading(true);
      const response = await PremioSorteioService
        .putPremioBySorteioApuracaoId(idSorteio, idApuracao, premio);
      if (response.status === 200) {
        this.toast.current?.show({
          severity: "success",
          detail: "Prêmio editado com sucesso!"
        });
      }
    } catch(e) {
      console.error(e);
      this.toast.current?.show({severity: "error", detail: "Erro"});
    } finally {
      this.dispatchLoading(false)
    }
  }

  async removePremio(
    idSorteio:number, 
    idApuracao: number, 
    idPremio: number
  ): Promise<void> {
    try {
      this.dispatchLoading(true);
      const response = await PremioSorteioService
        .deletePremio(idSorteio, idApuracao, idPremio);
      if (response.status === 204) {
        this.toast.current?.show({
          severity: "success",
          detail: "Prêmio removido com sucesso!",
        });
      }
    } catch(e) {
      console.error(e);
      this.toast.current?.show({severity: "error", detail: "Erro"});
    } finally {
      this.dispatchLoading(false)
    }
  }

}


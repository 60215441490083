import { Dispatch, RefObject, SetStateAction } from "react";
import { Toast } from "primereact/toast";
import Apuracao from "../../../interfaces/Apuracao";
import ApuracaoService from "../../../services/ApuracaoService";
import SorteioService from "../../../services/SorteioService";
import Sorteio from "../../../interfaces/Sorteio";
import Ganhador from "../../../interfaces/Ganhador";
import Captura from "../../../interfaces/Captura";
import CapturaService from "../../../services/CapturaService";

export default class ApuracaoController {

  private readonly dispatchLoading: Dispatch<SetStateAction<boolean>>;
  private readonly toast: RefObject<Toast>;

  constructor(
    dispatchLoading: Dispatch<SetStateAction<boolean>>,
    toast: RefObject<Toast>
  ) {
    this.dispatchLoading = dispatchLoading;
    this.toast = toast;
  }

  async getApuracoesByIdSorteio(idSorteio: number): Promise<Apuracao[]> {
    try {
      this.dispatchLoading(true);
      const response = await ApuracaoService
        .getAllApuracoesByIdSorteio(idSorteio);
      if (response.status === 200) {
        const apuracoesResponse = response.data
        return apuracoesResponse;
      }
    } catch(e) {
      console.error(e);
      this.toast.current?.show({severity: "error", detail: "Erro"});
    } finally {
      this.dispatchLoading(false);
    }
    return [];
  }

  async createApuracaoByIdSorteio(
    idSorteio: number, 
    apuracao: Apuracao
  ): Promise<void> {
    try {
      this.dispatchLoading(true);
      const response = await ApuracaoService
        .postApuracaoBySorteioId(idSorteio, apuracao);
      if (response.status === 201) {
        this.toast.current?.show({
          severity: "success",
          detail: "Apuração criada com sucesso!"
        });
      }
    } catch(e) {
      console.error(e);
      this.toast.current?.show({severity: "error", detail: "Erro"});
    } finally {
      this.dispatchLoading(false)
    }
  }

  async updateApuracaoByIdSorteio(
    idSorteio: number, 
    apuracao: Apuracao
  ): Promise<void> {
    try {
      this.dispatchLoading(true);
      const response = await ApuracaoService
        .putApuracaoBySorteioId(idSorteio, apuracao);
      if (response.status === 200) {
        this.toast.current?.show({
          severity: "success",
          detail: "Apuração editada com sucesso!"
        });
      }
    } catch(e) {
      console.error(e);
      this.toast.current?.show({severity: "error", detail: "Erro"});
    } finally {
      this.dispatchLoading(false)
    }
  }

  async removeApuracao(idSorteio:number, idApuracao: number): Promise<void> {
    try {
      this.dispatchLoading(true);
      const response = await ApuracaoService
        .deleteApuracao(idSorteio, idApuracao);
      if (response.status === 204) {
        this.toast.current?.show({
          severity: "success",
          detail: "Apuracao removida com sucesso!",
        });
      }
    } catch(e) {
      console.error(e);
      this.toast.current?.show({severity: "error", detail: "Erro"});
    } finally {
      this.dispatchLoading(false)
    }
  }

  async getSorteioFromApuracao(idSorteio: number): Promise<Sorteio | undefined> {
    try {
      const response = await SorteioService.getSorteio(idSorteio);
      if (response.status === 200)
        return response.data;
    } catch(e) {
      console.error(e);
      this.toast.current?.show({severity: "error", detail: "Erro"});
    }
  }

  async getCsvNumerosApuracao(idApuracao: number): Promise<void> {
    try {
      this.dispatchLoading(true);
      const response = await ApuracaoService.downloadCsvNumerosApuracao(idApuracao);

      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        console.log(response)
        link.href = url;
        link.setAttribute('download', 'data.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        this.toast.current?.show({
          severity: "success",
          detail: "Números baixados com sucesso!",
        });
      }

    } catch(e) {
      console.error(e);
      this.toast.current?.show({severity: "error", detail: "Erro"});
    } finally {
      this.dispatchLoading(false);
    }
  }

  async buscarApuracaoPeloId(idApuracao: number, useLoading = true): Promise<Apuracao | undefined> {
    try {
      if(useLoading)
        this.dispatchLoading(true);
      const response = await ApuracaoService.getApuracaoById(idApuracao);
      if (response.status === 200) {
        return response.data;
      }
    } catch(e) {
      console.error(e);
      this.toast.current?.show({severity: "error", detail: "Erro"});
    } finally {
      if(useLoading)
        this.dispatchLoading(false);
    }
  }

  async processarApuracao(
    apuracao: Apuracao
  ): Promise<void> {
    try {
      this.dispatchLoading(true);
      const response = await ApuracaoService
        .processarApuracao(apuracao.id!, apuracao);
      if (response.status === 200) {
        this.toast.current?.show({
          severity: "success",
          detail: "Apuração processada com sucesso!"
        });
        this.toast.current?.show({
          severity: "success",
          detail: "Os dados da apuração e dos ganhadores foram salvos!"
        });
      }
    } catch(e) {
      console.error(e);
      this.toast.current?.show({severity: "error", detail: "Erro"});
    } finally {
      this.dispatchLoading(false)
    }
  }

  async buscarGanhadoresApuracao(
    apuracaoId: number
  ): Promise<Ganhador[]> {
    try {
      this.dispatchLoading(true);
      const response = await ApuracaoService
        .getGanhadoresApuracao(apuracaoId);
      if (response.status === 200) {
        return response.data;
      }
    } catch(e) {
      console.error(e);
      this.toast.current?.show({severity: "error", detail: "Erro"});
    } finally {
      this.dispatchLoading(false);
    }
    return [];
  }

  async getCapturasUrl(): Promise<Captura[]> {
    try {
      this.dispatchLoading(true);
      const response = await CapturaService.getCapturasUrl();
      if (response.status === 200) {
        return response.data;
      }
    } catch(e) {
      console.error(e);
      this.toast.current?.show({severity: "error", detail: "Erro"});
    } finally {
      this.dispatchLoading(false);
    }
    return [];
  }

}


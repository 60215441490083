import ChatAjudaInterface from "./ChatAjudaInterface";

type Ajuda =
  | "como_funciona"
  | "qto_vale"
  | "notas_validas"
  | "tempo_validacao_chave_acesso"
  | "consulte_notas"
  | "para_que_cpf"
  | "chave_acesso"
  | "cancelar_conta"
  | "saque_invalidado"
  | "quebrando_notas"
  | "cadastrar_dados_bancarios"
  | "cupom_sem_chave_acesso"
  | "problema_leitura_qrcode"
  | "nota_sem_cpf"
  | "nota_sem_cpf2"
  | "recomendo_avise_estab"
  | "maximo_indicacao"
  | "mande_email_suporte"
  | "como_usamos_dados"
  | "trocar_senha"
  | "como_eh_feito_saque"
  | "captura_quebrada"
  | "informe_ir"
  | "limite_envio"
  | "proativo_notas_seq"
  | "proativo_notas_sem_cpf"
  | "como_participar_sorteio"
  | "como_enviar_nota"
  | "para_participar_envie_nota"
  | "qrcode_foto"
  | "chave_777"
  | "quero_pesquisa";

const ChatAjudaMap: Record<Ajuda, ChatAjudaInterface> = {
  como_funciona: {
    id: "como_funciona",
    tema: "Como funciona?",
    msg:
      "Em resumo funciona assim:||" +
      "Você ganha dinheiro por nos enviar seus cupons/notas fiscais||" +
      "Você recebe R$0,10 centavos por cada nota válida||" +
      "A cada 20 notas válidas você ganha R$1,00 pela última nota||" +
      "E a cada 200 notas válidas você ganha R$10,00 pela última nota||" +
      "No site (www.dinheironanota.com.br) tem mais informações sobre quem somos e como funciona o app",
  },
  qto_vale: {
    id: "qto_vale",
    tema: "Quanto vale cada nota/cupom?",
    msg:
      "Você recebe R$0,10 centavos por cada nota válida||" +
      "A cada 20 notas válidas você ganha R$1,00 pela última nota||" +
      "E a cada 200 notas válidas você ganha R$10,00 pela última nota",
  },
  notas_validas: {
    id: "notas_validas",
    tema: "Que tipo/quais notas são válidas?",
    msg:
      "São aceitas notas de compra de produtos feitas por você, para você, sua casa, família e animais de estimação||" +
      "por exemplo, compras em supermercados, farmácias, lojas online, petshops, bares, padarias, etc||" +
      "lembrando que as notas devem ter o seu CPF",
  },
  tempo_validacao_chave_acesso: {
    id: "tempo_validacao_chave_acesso",
    tema: "Qual o tempo de validação cupons enviados pelo qrcode, código de barras ou chave de acesso?",
    msg:
      "A validação das notas dependem do tempo de disponibilização dos dados na Secretaria da Fazenda.||" +
      "Você pode consultar o status das notas na tela com a listagem das notas enviadas",
  },
  consulte_notas: {
    id: "consulte_notas",
    tema: "Qual o número da minha nota? Por que meu cupom ainda não foi validado?",
    msg: 'Por favor, acesse no app a opção "Consulte suas notas/cupons" para acompanhar o status de validação e a numeração de cada nota',
  },
  para_que_cpf: {
    id: "para_que_cpf",
    tema: "Precisa do cpf na nota?",
    msg: "Precisamos do seu CPF na nota para garantir que a compra foi feita por você",
  },
  chave_acesso: {
    id: "chave_acesso",
    tema: "O que é a chave de acesso?",
    msg:
      "A chave de acesso é um número de 44 dígitos.||" +
      "Ela normalmente fica após a lista de itens comprados, próximo ao qrcode e/ou código de barras||" +
      "Nas notas fiscais eletrônicas (NFe), ela fica abaixo do código de barras, no canto superior direito.",
  },
  cancelar_conta: {
    id: "cancelar_conta",
    tema: "Como cancelar minha conta? Quero apagar minha conta.",
    msg:
      "Para cancelar sua conta você precisa enviar um email para suporte@dinheironanota.com.br com o seu cpf solicitando o cancelamento||" +
      "Informo que, no momento do cancelamento, todas as suas informações serão apagadas||" +
      "Peço, se possível, que você nos conte porque quer cancelar a conta. Isso é super importante para melhorarmos nosso app",
  },
  saque_invalidado: {
    id: "saque_invalidado",
    tema: "Invalidar o saque",
    msg:
      "Olá << nome_usuario >>||" +
      "Escrevo para te dizer que identificamos um problema nas suas notas fiscais||" +
      "Suas compras estão todas concentradas em um único estabelecimento (<< estabelecimento >>)||" +
      "Várias delas, inclusive, são sequenciais||Por conta disso, todas as notas deste estabelecimento foram invalidadas e sua solicitação de saque foi cancelada||" +
      "Mesmo essas notas tendo sido validadas automaticamente, só aceitamos notas de compras que estejam em conformidade com as regras e que representem o consumo médio de uma pessoa||" +
      "Caso existam padrões atípicos nas notas, nos reservamos o direito de invalidar essas notas||" +
      "Peço que nos envie apenas notas de compras que você efetivamente realizou",
  },
  quebrando_notas: {
    id: "quebrando_notas",
    tema: "Usuario enviando várias notas com poucos itens",
    msg:
      "Olá << nome_usuario >>||" +
      "Escrevo para te dizer que identificamos o envio de notas fiscais fora das regras do aplicativo||" +
      "Vimos que você está enviando várias notas sequenciais com poucos produtos||" +
      "Peço que envie apenas notas suas e que não divida sua compra em diversas notas pequenas||" +
      "Caso contrário suas notas poderão ser canceladas",
  },
  cadastrar_dados_bancarios: {
    id: "cadastrar_dados_bancarios",
    tema: "Como cadastrar minha conta/dados bancarios/banco para saque?",
    msg:
      "Os seus dados bancários são cadastrados no momento de solicitar o saque||" +
      "O saque pode ser solicitado assim que você atingir o saldo de R$ 10,00",
  },
  cupom_sem_chave_acesso: {
    id: "cupom_sem_chave_acesso",
    tema: "Está dando erro para ler a nota/cupom/qrcode (cupom sem chave de acesso)",
    msg:
      "Acredito que o cupom que você leu o código de barras não possui a chave de acesso||" +
      "Nesse caso, a opção é enviá-lo por foto (opção cupom comum)",
  },
  problema_leitura_qrcode: {
    id: "problema_leitura_qrcode",
    tema: "Está dando erro para ler a nota/cupom/qrcode (geral) | Leitor qrcode nao funciona",
    msg:
      "A leitura do qrcode e do código de barras as vezes não funciona por causa da qualidade da impressão do cupom||" +
      "Nesse caso a opção é digitar a chave de acesso",
  },
  nota_sem_cpf: {
    id: "nota_sem_cpf",
    tema: "Minha nota tem cpf e foi marcado como nota sem cpf",
    msg:
      "Verifiquei aqui e infelizmente seu cpf não consta nos dados da sua nota que foram enviados para a Secretaria da Fazenda||" +
      "Você pode consultar a nota no site da Secretaria da Fazenda usando as informações disponíveis na tela do cupom||" +
      "Recomendo que você avise o estabelecimento sobre o problema",
  },
  nota_sem_cpf2: {
    id: "nota_sem_cpf2",
    tema: "Minha nota não gerou credito (usuario não sabe que a nota não temm cpf)",
    msg:
      "as últimas notas que você enviou não continham seu cpf||" +
      "só geram crédito as notas com CPF",
  },
  recomendo_avise_estab: {
    id: "recomendo_avise_estab",
    tema: "Nota possui algum problema na emissão (chave de acesso inválida, impressão do cupom cortada, etc)",
    msg: "Recomendo que você avise o estabelecimento sobre o problema",
  },
  maximo_indicacao: {
    id: "maximo_indicacao",
    tema: "Por que não recebi pela indicação?",
    msg:
      "Você já atingiu o valor máximo recebido por indicação, que é de R$ 5,00||" +
      "Agora, apenas seus amigos receberão o prêmio",
  },
  mande_email_suporte: {
    id: "mande_email_suporte",
    tema: "Mande email para o suporte",
    msg: "Por favor, mande a foto do cupom para suporte@dinheironanota.com.br para avaliarmos",
  },
  como_usamos_dados: {
    id: "como_usamos_dados",
    tema: "Como vocês usam os dados | Como eu sei se é confiável?",
    msg:
      "Usamos os dados anonimizados de todos os usuários para criar relatórios sobre comportamento de vendas||" +
      "Por exemplo, quais marcas vendem mais, qual o preço médio, etc||" +
      "Os relatórios sempre usam dados anonimizados, então não é possível identificar nenhuma informação de um usuário ||" +
      "Para mais informações sobre nós, por favor acesse nosso site||" +
      "https://www.dinheironanota.com.br/",
  },
  trocar_senha: {
    id: "trocar_senha",
    tema: "Quero trocar a senha | Como faço a troca da senha? | Trocar senha",
    msg:
      "A troca da senha é feita no app||" +
      'Acesse a tela de login e selecione a opção "Esqueci a senha"||' +
      "Você receberá um email com as instruções para concluir a troca da senha",
  },
  como_eh_feito_saque: {
    id: "como_eh_feito_saque",
    tema: "Como é feito o saque? | Como eu saco meu dinheiro?",
    msg:
      "O saque é feito através de uma transferência bancária ou PIX para uma conta que você seja o titular||" +
      "Importante ressaltar que você deve ser o titular dessa conta||" +
      "Caso contrário a solicitação de saque será recusada",
  },
  captura_quebrada: {
    id: "captura_quebrada",
    tema: "Por que minhas notas não estão sendo validadas? Não está validando minhas notas.",
    msg:
      "Estamos com problemas no acesso aos dados do seu estado||" +
      "Acredito que breve o problema estará resolvido||" +
      "Pode continuar enviando os cupons. Assim que resolvermos o acesso, eles serão todos analisados.",
  },
  informe_ir: {
    id: "informe_ir",
    tema: "Como acesso o informe de imposto de renda? | Como declaro no imposto de renda?",
    msg:
      "O sistema cashback da Varejo 360 depende do consumo de um produto, que geram um decréscimo patrimonial||" +
      "Por isso ele não está sujeito à tributação pelo imposto de renda||" +
      "Aproveito para dizer que a Varejo 360 não informa o CPF dos usuários e nem os valores recebidos por eles à Receita Federal, devido ao tratamento fiscal conferido ao cashback",
  },
  limite_envio: {
    id: "limite_envio",
    tema: "Quantas notas posso enviar? Qual o limite de envio?",
    msg: "Por favor, acesse as regras de envio que estão na tela de envio de nota fiscal.",
  },
  proativo_notas_seq: {
    id: "proativo_notas_seq",
    tema: "Usuario no limiar de ser considerado inválido",
    msg:
      "Olá << nome_usuario >>||" +
      "Vimos que você enviou várias notas sequenciais de um mesmo estabelecimento << estabelecimento >>||" +
      "Peço que não divida sua compra em diversas notas pequenas e que envie apenas notas de compras que você realizou||" +
      "Caso contrário suas notas poderão ser canceladas",
  },
  proativo_notas_sem_cpf: {
    id: "proativo_notas_sem_cpf",
    tema: "Envio de muitas notas sem cpf",
    msg:
      "Olá << nome_usuario >>||" +
      "escrevo para te dizer que identificamos o envio de muitas notas fiscais sem cpf||" +
      "Peço que envie apenas notas de compras que você realizou e que tenham o seu cpf",
  },
  como_participar_sorteio: {
    id: "como_participar_sorteio",
    tema: "Como participar do sorteio",
    msg:
      "para participar do sorteio você deve:||" +
      "- acessar o botão Sorteios que está na tela inicial||" +
      "- selecionar o sorteio mais recente||" +
      "- ativar sua participação no sorteio",
  },
  como_enviar_nota: {
    id: "como_enviar_nota",
    tema: "Como enviar nota",
    msg:
      'na tela inicial, selecione o botão "Enviar nota/cupom fiscal"||' +
      "em seguida selecione o tipo de nota que você quer enviar||" +
      "o app apresentará as opções de envio disponíveis",
  },
  para_participar_envie_nota: {
    id: "para_participar_envie_nota",
    tema: "Usuário não sacou que precisa enviar a nota",
    msg: "para participar do app você precisa nos enviar as notas das suas compras",
  },
  qrcode_foto: {
    id: "qrcode_foto",
    tema: "Usuário mandou qrcode por foto",
    msg:
      "notei que você enviou a foto de uma nota com qrcode||" +
      "essas notas devem ser enviadas utilizando as outras opções disponíveis no app, tudo bem?",
  },
  chave_777: {
    id: "chave_777",
    tema: "Usuário reclamando de chave inválida e a chave tem uma sequencia de 777777",
    msg:
      "essa nota tem uma chave de acesso inválida||" +
      "você pode perceber que existe uma sequencia de 777777||" +
      "peço que avise o estabelecimento do problema",
  },
  quero_pesquisa: {
    id: "quero_pesquisa",
    tema: "Usuário pedindo para participar de pesquisas",
    msg:
      "as pesquisas são disponibilizadas de tempos em tempos||" +
      "logo devemos habilitar novas pesquisas para você||" +
      "é importante continuar enviando as notas para ser qualificada para  a pesquisa",
  },
};

const ChatAjudaContent = {
  list: [
    ChatAjudaMap.como_funciona,
    ChatAjudaMap.qto_vale,
    ChatAjudaMap.notas_validas,
    ChatAjudaMap.tempo_validacao_chave_acesso,
    ChatAjudaMap.consulte_notas,
    ChatAjudaMap.para_que_cpf,
    ChatAjudaMap.chave_acesso,
    ChatAjudaMap.cancelar_conta,
    ChatAjudaMap.saque_invalidado,
    ChatAjudaMap.quebrando_notas,
    ChatAjudaMap.cadastrar_dados_bancarios,
    ChatAjudaMap.cupom_sem_chave_acesso,
    ChatAjudaMap.problema_leitura_qrcode,
    ChatAjudaMap.nota_sem_cpf,
    ChatAjudaMap.nota_sem_cpf2,
    ChatAjudaMap.recomendo_avise_estab,
    ChatAjudaMap.mande_email_suporte,
    ChatAjudaMap.como_usamos_dados,
    ChatAjudaMap.trocar_senha,
    ChatAjudaMap.como_eh_feito_saque,
    ChatAjudaMap.captura_quebrada,
    ChatAjudaMap.informe_ir,
    ChatAjudaMap.limite_envio,
    ChatAjudaMap.proativo_notas_seq,
    ChatAjudaMap.proativo_notas_sem_cpf,
    ChatAjudaMap.como_participar_sorteio,
    ChatAjudaMap.como_enviar_nota,
    ChatAjudaMap.para_participar_envie_nota,
    ChatAjudaMap.qrcode_foto,
    ChatAjudaMap.chave_777,
    ChatAjudaMap.quero_pesquisa,
  ].sort((a, b) => a.id.localeCompare(b.id)),
  map: ChatAjudaMap,
};

export default ChatAjudaContent;

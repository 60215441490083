import api from "./Api";
import {AxiosResponse} from "axios";
import token from "./TokeService";

const getUserList = (estado: string): Promise<AxiosResponse> => {
    return api.get(`/chat_user_list?token=${token()}&estado=${estado}`);
};

const getUserListByFilterUser = (estado: string, userFilter: string): Promise<AxiosResponse> => {
    return api.get(`/chat_user_list?token=${token()}&estado=${estado}&disable=${userFilter}`);
};

const getChatUser = (cpf: string): Promise<AxiosResponse> => {
    return api.get(`/message_list?token=${token()}&cpf=${cpf}`);
};

const sendMessage = (body: {
    cpf: string;
    message: string;
}): Promise<AxiosResponse> => {
    return api.post(`/send_message?token=${token()}`, body);
};

const getChatUserStatus = (): Promise<AxiosResponse> => {
    return api.get(`/chat_user_status?token=${token()}`);
};

const ChatService = {
    getUserList,
    getChatUser,
    sendMessage,
    getUserListByFilterUser,
    getChatUserStatus,
};

export default ChatService;

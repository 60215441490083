import React, { ReactElement } from "react";
import styles from "./LoginPage.module.scss";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Card } from "primereact/card";
import Logo from "../../assets/logo.png";

type Inputs = {
  email: string;
  password: string;
};

export function LoginPage(): ReactElement {
  const { handleSubmit, control } = useForm<Inputs>();
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSubmit: SubmitHandler<Inputs> = async (data): Promise<void> => {};

  return (
    <div className={styles.containerBody}>
      <div className={styles.login}>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            <div>
              <img alt="Card" src={Logo} />
            </div>
            <Controller
              control={control}
              name="email"
              rules={{ required: true, pattern: emailRegex }}
              defaultValue={""}
              render={({ field, fieldState: { error } }) => (
                <div className={`${styles.field} ${styles.width_100}`}>
                  <span className={`${styles.width_100} p-float-label`}>
                    <InputText
                      id="email"
                      type="email"
                      {...field}
                      className={`${styles.width_100} ${error ? "p-invalid block" : ""}`}
                    />
                    <label htmlFor="email">E-mail *</label>
                  </span>
                  {error && (
                    <small className="p-error block">
                      Deve ser um e-mail válido.
                    </small>
                  )}
                </div>
              )}
            />
            <Controller
              control={control}
              name="password"
              rules={{ required: true, minLength: 8 }}
              defaultValue={""}
              render={({ field, fieldState: { error } }) => (
                <div className={`${styles.field} ${styles.width_100}`}>
                  <span className={`${styles.width_100} p-float-label`}>
                    <Password
                      inputId="password"
                      toggleMask
                      feedback={false}
                      {...field}
                      className={`${styles.width_100} ${error ? "p-invalid block" : ""}`}
                    />
                    <label htmlFor="password">Senha *</label>
                  </span>
                  {error && (
                    <small className="p-error block">
                      A senha deve conter no mínimo 8 caracteres.
                    </small>
                  )}
                </div>
              )}
            />
            <div className={styles.width_100}>
              <Button type="submit" label="Entrar" className={styles.width_100} />
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
}

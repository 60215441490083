import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Loading } from "../../components/loading/Loading";
import { Toast } from "primereact/toast";
import CupomReplacerController from "./CupomReplacerController";
import CupomReplacer from "../../interfaces/CupomReplacer";
import styles from "./CupomReplacerPage.module.scss";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { FormReplacer } from "./form_replacer/FormReplacer";
import { ConfirmDialog } from "primereact/confirmdialog"; // To use <ConfirmDialog> tag
import { confirmDialog } from "primereact/confirmdialog";
import DateUtils from "../../utils/DateUtils"; // To use confirmDialog method
import "./Prime.css";

export function CupomReplacerPage(): ReactElement {
  const toast = useRef<Toast>(null);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(20);
  const [total, setTotal] = useState(0);
  const [first, setFirst] = useState(0);
  const [enable, setEnable] = useState("false");
  const [filter, setFilter] = useState("");
  const [replacer, setReplacer] = useState<CupomReplacer | null>(null);

  const [displayForm, setDisplayForm] = useState(false);

  const [source, setSource] = useState<Array<CupomReplacer>>([]);

  const controller = React.useCallback(
    () => new CupomReplacerController(setIsResponseLoading, toast),
    [setIsResponseLoading, toast]
  );

  async function getCupomReplacerList(
    page: number,
    rows: number,
    enable: string
  ): Promise<void> {
    const data = await controller().get(enable, filter, page, rows);
    if (data != null) {
      setSource(data.content);
      setTotal(data.totalElements);
    }
  }

  useEffect(() => {
    getCupomReplacerList(page, rows, enable);
  }, [setSource, controller, displayForm]);

  const onBasicPageChange = (event: PaginatorPageChangeEvent): void => {
    console.log(event);
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
    getCupomReplacerList(event.page, event.rows, enable);
  };

  const editCupom = (cupom: CupomReplacer): void => {
    setReplacer(cupom);
    setDisplayForm(true);
  };

  const enableFormat = (row: CupomReplacer): string => {
    if (row.enable) {
      return "SIM";
    }

    return "NÃO";
  };

  function deletarCupomReplacer(isOk: boolean, replacer: CupomReplacer): void {
    if (!isOk) {
      confirmDialog({
        message: `Deseja excluir o cupom replacer (${replacer.replace})?`,
        header: "Confirme o cancelamento",
        icon: "pi pi-info-circle",
        acceptClassName: "p-button-danger",
        accept: () => {
          deletarCupomReplacer(true, replacer);
        },
        acceptLabel: "Confirmar",
        rejectLabel: "Voltar",
      });
      return;
    }

    controller()
      .delete(replacer)
      .then((_) => {
        getCupomReplacerList(page, rows, enable);
      });
  }

  const actionsFormat = (row: CupomReplacer): ReactElement => {
    return (
      <div className={styles.actions}>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          aria-label="Cancel"
          onClick={() => deletarCupomReplacer(false, row)}
        />
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-info"
          aria-label="Filter"
          onClick={() => editCupom(row)}
        />
      </div>
    );
  };

  const enableSelect = [
    { label: "Ativado", value: "true" },
    { label: "Desativado", value: "false" },
    { label: "Todos", value: "" },
  ];

  const leftButtons = (
    <div className={styles.toolbarLeft}>
      <Button
        label="Novo"
        icon="pi pi-plus"
        className="mr-2"
        onClick={() => {
          setReplacer(null);
          setDisplayForm(true);
        }}
      />
      <Button
        label="Atualizar"
        icon="pi pi-refresh"
        className="p-button-success"
        onClick={() => getCupomReplacerList(page, rows, enable)}
      />
    </div>
  );

  const rightContent = (
    <div className={styles.toolbarRigth}>
      <Button
        icon="pi pi-search"
        onClick={() => getCupomReplacerList(page, rows, enable)}
        className="mr-2"
      />
      <InputText value={filter} onChange={(e) => setFilter(e.target.value)} />
      <Dropdown
        value={enable}
        options={enableSelect}
        onChange={(e) => {
          setEnable(e.value);
          getCupomReplacerList(page, rows, e.value);
        }}
        placeholder="Status"
      />
    </div>
  );

  const createdAt = (row: CupomReplacer): string => {
    return DateUtils.format(row.createdAt);
  };

  return (
    <>
      <Loading isLoading={isResponseLoading} />
      <Toast ref={toast} />
      <ConfirmDialog />
      <FormReplacer
        setDisplay={setDisplayForm}
        display={displayForm}
        controller={controller()}
        cupom={replacer}
      />
      <div className={styles.cupomReplacerPage}>
        <div className={styles.tab}>
          <Paginator
            first={first}
            rows={rows}
            totalRecords={total}
            rowsPerPageOptions={[20, 50, 100]}
            onPageChange={onBasicPageChange}
            leftContent={leftButtons}
            rightContent={rightContent}
          ></Paginator>
          <DataTable
            value={source}
            responsiveLayout="scroll"
            className={styles.table}
            showGridlines
            rowHover
          >
            <Column body={actionsFormat} header="Ações" />
            <Column body={createdAt} header="Data" />
            <Column body={enableFormat} header="Ativado" />
            <Column field="find" header="find" />
            <Column field="total_size" header="total_size" />
            <Column field="replace" header="replace" />
            <Column field="priority" header="priority" />
          </DataTable>
        </div>
      </div>
    </>
  );
}

import React, { 
  ReactElement, 
  useEffect, 
  useRef, 
  useState 
} from "react";
import styles from "./PremioSorteioPage.module.scss"
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../components/loading/Loading";
import PremioSorteio from "../../../interfaces/PremioSorteio";
import PremioSorteioController from "./PremioSorteioController";
import { PremioSorteioForm } from "../forms/PremioSorteioForm";
import { addDays, format } from "date-fns";
import { ptBR } from 'date-fns/locale';
import RoutesEnum from "../../../properties/RoutesEnum";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export function PremioSorteioPage(): ReactElement {
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [premios, setPremios] = useState<PremioSorteio[]>([]);
  const [formVisible, setFormVisible] = useState(false);
  const [acaoForm, setAcaoForm] = useState('');
  const [premioForm, setPremioForm] = useState<PremioSorteio | null>(null)

  const toast = useRef<Toast>(null);

  const navigate = useNavigate();

  const { idSorteio, idApuracao } = useParams();
  const idSorteioValue = idSorteio ? parseInt(idSorteio) : NaN;
  const idApuracaoValue = idApuracao ? parseInt(idApuracao) : NaN;
  const controller = new PremioSorteioController(setIsResponseLoading, toast);

  async function getPremios(): Promise<void> {
    const premiosResponse = await controller
      .getPremiosByIdApuracao(idSorteioValue, idApuracaoValue);
    setPremios(premiosResponse);
  }

  function changeFormVisibility(visible: boolean): void {
    setFormVisible(visible);
  }

  useEffect(() => {
    getPremios();
  }, []);

  function onEditPremio(premio: PremioSorteio): void {
    setAcaoForm('Editar');
    setPremioForm(premio);
    setFormVisible(true);
  }

  function deletePremio(idPremio: number): void {
    controller.removePremio(
      idSorteioValue,
      idApuracaoValue,
      idPremio
    ).then(() => {
      getPremios();
    });
  }

  function dialogConfirm(premio: PremioSorteio): void {
    confirmDialog({
      message: `Deseja realmente remover o prêmio?`,
      header: "Confirme a remoção",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-rounded",
      accept: () => deletePremio(premio.id as number),
      acceptLabel: "Confirmar",
      rejectLabel: "Cancelar",
    });
  }

  function resgatadoColumn(premio: PremioSorteio): string {
    return premio.resgatado ? 'Sim' : "Não";
  }

  function onNovoPremio(): void {
    setAcaoForm('Criar');
    setPremioForm({
      id: null,
      ordem: premios.length + 1,
      numeroDaSorteContemplado: '',
      tutorial: 'https://youtu.be/UzhKm-Z1RNE',
      descricao: 'R$ 500,00 em cartão virtual pré-pago (sem função de saque e transferência)',
      userInfo: '',
      valor: '500',
      validade: format(
        addDays(Date(), 180), 
        'yyyy-MM-dd HH:mm:ss',
        {locale: ptBR}
      ),
      resgatado: false,
      tipo: 'cartao',
      cartaoNome: 'DINHEIRO NA NOTA',
      cartaoNumero: '',
      cartaoCsc: '',
      cartaoValidade: '',
      codigo: null
    })
    setFormVisible(true);
  }

  function actionButtons(premio: PremioSorteio): ReactElement {
    return (
      <div className={styles.actionsTable}>
        <Button 
          icon="pi pi-pencil" 
          className="p-button-rounded p-button-info"
          onClick={() => onEditPremio(premio)}   
        />
        <Button 
          icon="pi pi-trash" 
          className="p-button-rounded p-button-danger" 
          onClick={() => dialogConfirm(premio)}
        />
      </div>
    )
  }

  return (
    <>
      <h1>Prêmios</h1>
      <ConfirmDialog />
      <Loading isLoading={isResponseLoading} />
      <Toast ref={toast} />
      <div className={styles.toolbar}>
        <div className={styles.leftToolbar}>
          <Button 
            className="p-button-rounded" 
            icon="pi pi-angle-left"
            onClick={() => navigate(`${RoutesEnum.Sorteio}/${idSorteioValue}/apuracoes`)}
          />
          <Button
            label="Novo"
            icon="pi pi-plus"
            className="p-button-rounded"
            onClick={onNovoPremio}
          />
        </div>
      </div>
      < PremioSorteioForm 
        acaoForm={acaoForm}
        visible={formVisible}
        setVisible={changeFormVisibility}
        premioForm={premioForm as PremioSorteio}
        idSorteio={idSorteioValue}
        idApuracao={idApuracaoValue}
        controller={controller}
        getPremios={getPremios}
      />
      <main>
        <DataTable value={premios} showGridlines>
          <Column 
            field="ordem" 
            header="Ordem" 
          />
          <Column 
            field="numeroDaSorteContemplado" 
            header="Número da sorte" 
          />
          <Column 
            field="valor" 
            header="Valor" 
          />
          <Column 
            field="validade" 
            header="Validade" 
          />
          <Column 
            field="tipo" 
            header="Tipo" 
          />
          <Column 
            body={resgatadoColumn}
            header="Resgatado" 
          />
          <Column 
            body={actionButtons}
            header="Ações" 
          />
        </DataTable>
      </main>
    </>
  )
}
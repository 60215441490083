import React, { 
  ChangeEvent, 
  FormEvent, 
  ReactElement, 
  useEffect, 
  useState 
} from "react";
import styles from "./ApuracaoForm.module.scss";
import Apuracao from "../../../interfaces/Apuracao";
import { ptBR } from 'date-fns/locale';
import { format, set } from "date-fns";
import ApuracaoController from "../apuracao/ApuracaoController";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Nullable } from "primereact/ts-helpers";

interface ApuracaoFormProps {
	visible: boolean;
	setVisible: (valor: boolean) => void;
  apuracaoForm: Apuracao | undefined;
  acaoForm: string;
  idSorteio: number;
  controller: ApuracaoController;
  getApuracoes: () => Promise<void>;
}

export function ApuracaoForm(props: ApuracaoFormProps): ReactElement {
  const [id, setId] = useState<number | null>(null); 
  const [dataInicialParticipacao, setDataInicialParticipacao] = 
    useState<Nullable<Date>>(null);
  const [dataFinalParticipacao, setDataFinalParticipacao] = 
    useState<Nullable<Date>>(null);
  const [dataInicialEnvioNotas, setDataInicialEnvioNotas] = 
    useState<Nullable<Date>>(null);
  const [dataFinalEnvioNotas, setDataFinalEnvioNotas] = 
    useState<Nullable<Date>>(null);
  const [dataExtracao, setDataExtracao] = 
    useState<Nullable<Date>>(null);
  const [dataApuracao, setDataApuracao] = 
    useState<Nullable<Date>>(null);
  const [numeroExtracao, setNumeroExtracao] = useState('');
  const [numerosSorteados, setNumerosSorteados] = useState('');
  const [numeroDaSorte, setNumeroDaSorte] = useState('');

  function formatarNumerosSorteados(numerosSorteados: string): string {
    let numerosFormatadosArray = numerosSorteados.split(',');
    numerosFormatadosArray = numerosFormatadosArray.map(numero => {
      return `'${numero}'`
    });
    const numerosFormatados = `[${numerosFormatadosArray.join()}]`
    return numerosFormatados;
  }

  function setEndOfDay(data: Date): Date {
    return set(data, {
      hours: 23,
      minutes: 59,
      seconds: 59
    });
  }

  function setNullIfEmpty(text: string): string | null {
    const result = text.trim();
    if (result.length > 0 && result != "['']") {
      return text;
    }
    return null;
  }

  function buildApuracao(): Apuracao {
    return {
      id: id,
      dataInicialParticipacao: format(
        dataInicialParticipacao as Date, 
        'yyyy-MM-dd HH:mm:ss', 
        { locale: ptBR }
      ),
      dataFinalParticipacao: format(
        setEndOfDay(dataFinalParticipacao as Date), 
        'yyyy-MM-dd HH:mm:ss', 
        { locale: ptBR }
      ),
      dataInicialEnvioNotas: format(
        dataInicialEnvioNotas as Date, 
        'yyyy-MM-dd HH:mm:ss', 
        { locale: ptBR }
      ),
      dataFinalEnvioNotas: format(
        setEndOfDay(dataFinalEnvioNotas as Date), 
        'yyyy-MM-dd HH:mm:ss', 
        { locale: ptBR }
      ),
      dataExtracaoLoteriaFederal: format(
        dataExtracao as Date, 
        'yyyy-MM-dd HH:mm:ss', 
        { locale: ptBR }
      ),
      data_apuracao: format(
        dataApuracao as Date, 
        'yyyy-MM-dd HH:mm:ss', 
        { locale: ptBR }
      ),
      numeroExtracaoLoteriaFederal: setNullIfEmpty(numeroExtracao),
      numerosSorteadosPelaExtracaoLoteriaFederal: 
        setNullIfEmpty(formatarNumerosSorteados(numerosSorteados)),
      numeroDaSorteSorteado: setNullIfEmpty(numeroDaSorte)
    }
  }

  function handleSubmit(e: FormEvent): void {
    e.preventDefault();
    const apuracao = buildApuracao();
    if(props.acaoForm === 'Criar') {
			props.controller.createApuracaoByIdSorteio(
        props.idSorteio,
        apuracao
      ).then(() => {
        props.setVisible(false);
        props.getApuracoes();
      })
		} else if (props.acaoForm === 'Editar') {
			props.controller.updateApuracaoByIdSorteio(
        props.idSorteio,
        apuracao
      ).then(() => {
        props.setVisible(false);
        props.getApuracoes();
      })
		}
  }

  function regexNumerosSorteados(e: ChangeEvent<HTMLInputElement>): void {
    const pattern = /^[0-9,]*$/;
    const isValid = pattern.test(e.target.value);
    if(isValid)
      setNumerosSorteados(e.target.value);
  }

  function regexInteiros(e: ChangeEvent<HTMLInputElement>): void {
    const pattern = /^[0-9]*$/;
    const isValid = pattern.test(e.target.value);
    if(isValid)
      setNumeroExtracao(e.target.value);
  }

  function regexNumeroDaSorte(e: ChangeEvent<HTMLInputElement>): void {
    const pattern = /^[0-9]*[/]?[0-9]*$/;
    const isValid = pattern.test(e.target.value);
    if(isValid)
      setNumeroDaSorte(e.target.value);
  }

  useEffect(() => {
    if(props.apuracaoForm) {
      const {
        id,
        dataInicialParticipacao,
        dataFinalParticipacao,
        dataInicialEnvioNotas,
        dataFinalEnvioNotas,
        dataExtracaoLoteriaFederal,
        data_apuracao,
        numeroExtracaoLoteriaFederal,
        numerosSorteadosPelaExtracaoLoteriaFederal,
        numeroDaSorteSorteado
      } = props.apuracaoForm;

      setId(id);
      setDataInicialParticipacao(new Date(dataInicialParticipacao as string));
      setDataFinalParticipacao(new Date(dataFinalParticipacao as string));
      setDataInicialEnvioNotas(new Date(dataInicialEnvioNotas as string));
      setDataFinalEnvioNotas(new Date(dataFinalEnvioNotas as string));
      setDataExtracao(new Date(dataExtracaoLoteriaFederal as string));
      setDataApuracao(new Date(data_apuracao as string));
      setNumeroExtracao(
        numeroExtracaoLoteriaFederal ? numeroExtracaoLoteriaFederal : ''
      );
      setNumerosSorteados(
        numerosSorteadosPelaExtracaoLoteriaFederal ? 
        numerosSorteadosPelaExtracaoLoteriaFederal : ''
      );
      setNumeroDaSorte(
        numeroDaSorteSorteado ? numeroDaSorteSorteado : ''
      );
    }
  }, [props.apuracaoForm])

  return (
    <>
      <Dialog 
        className={styles.modal}
        header={`${props.acaoForm} apuração`}
        onHide={() => props.setVisible(false)}
        visible={props.visible}
      >
        <form onSubmit={handleSubmit} >
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor="data_inicio">Data inicial de participação</label>
              <Calendar 
                id="data_inicio" 
                placeholder="dia / mês / ano" 
                showIcon
                locale="pt-br" 
                dateFormat="dd/mm/yy"
                value={dataInicialParticipacao}
                onChange={(e) => {
                  setDataInicialParticipacao(e.target.value as Date)}
                }
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="data_fim">Data final de participação</label>
              <Calendar 
                id="data_fim" 
                placeholder="dia / mês / ano" 
                showIcon
                locale="pt-br"
                dateFormat="dd/mm/yy"
                value={dataFinalParticipacao}
                onChange={(e) => {
                  setDataFinalParticipacao(e.target.value as Date)}
                }
                required
              />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor="data_inicio">Data inicial de envio de notas</label>
              <Calendar 
                id="data_inicio" 
                placeholder="dia / mês / ano" 
                showIcon
                locale="pt-br" 
                dateFormat="dd/mm/yy"
                value={dataInicialEnvioNotas}
                onChange={(e) => {
                  setDataInicialEnvioNotas(e.target.value as Date)}
                }
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="data_fim">Data final de envio de notas</label>
              <Calendar 
                id="data_fim" 
                placeholder="dia / mês / ano" 
                showIcon
                locale="pt-br"
                dateFormat="dd/mm/yy"
                value={dataFinalEnvioNotas}
                onChange={(e) => {
                  setDataFinalEnvioNotas(e.target.value as Date)}
                }
                required
              />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor="data_extracao">Data de extração</label>
              <Calendar 
                id="data_extracao" 
                placeholder="dia / mês / ano" 
                showIcon
                locale="pt-br"
                dateFormat="dd/mm/yy"
                value={dataExtracao}
                onChange={(e) => {
                  setDataExtracao(e.target.value as Date)}
                }
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="data_apuracao">Data de apuração</label>
              <Calendar 
                id="data_apuracao" 
                placeholder="dia / mês / ano" 
                showIcon 
                locale="pt-br"
                dateFormat="dd/mm/yy"
                value={dataApuracao}
                onChange={(e) => {
                  setDataApuracao(e.target.value as Date)}
                }
                required
              />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor="numero_extracao">Número de extração</label>
              <InputText 
                id="numero_extracao" 
                type="text" 
                placeholder="Número de extração" 
                value={numeroExtracao}
                onChange={regexInteiros}
              />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor="numeros_sorteados">Números sorteados</label>
              <InputText 
                id="numeros_sorteados" 
                type="text" 
                placeholder="Números sorteados" 
                value={numerosSorteados}
                onChange={regexNumerosSorteados}
              />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label htmlFor="numero_sorte">Número da sorte</label>
              <InputText 
                id="numero_sorte" 
                type="text" 
                placeholder="Número da sorte" 
                value={numeroDaSorte}
                onChange={regexNumeroDaSorte}
              />
            </div>
          </div>
          <div className={styles.buttonsFormContainer}>
						<Button 
							label={props.acaoForm} 
							className="p-button-rounded p-button-info"
							type="submit"
						/>
						<Button 
							label="Cancelar" 
							className="p-button-rounded p-button-danger" 
							type="button"
							onClick={() => props.setVisible(false)}
						/>
					</div>
        </form>
      </Dialog>
    </>
  )
}
import {AxiosResponse} from "axios";
import api from "./Api";
import token from "./TokeService";

const getReportList = (
    estado: string | undefined,
    userFiler: string | undefined,
    level: string | undefined,
): Promise<AxiosResponse> => {
    return api.get(`/reports/all?token=${token()}&estado=${estado ?? ''}&user=${userFiler ?? ''}&level=${level ?? ''}`);
}

const getReportsResumeByCPF = (
    cpf: string,
): Promise<AxiosResponse> => {
    return api.get(`/reports/find/cpf?token=${token()}&cpf=${cpf}`);
}

const getReportById = (
    id: number,
): Promise<AxiosResponse> => {
    return api.get(`/reports/find/id?token=${token()}&id=${id}`);
}

const sendAnswer = (body: any): Promise<AxiosResponse> => {
    return api.post(`/reports?token=${token()}`, body);
}

const getLevels = (): Promise<AxiosResponse> => {
    return api.get(`/reports/find/levels?token=${token()}`);
}

const ReportService = {
    getReportList,
    sendAnswer,
    getReportsResumeByCPF,
    getReportById,
    getLevels,
}

export default ReportService;


import React, {ReactElement, useRef, useState} from "react";
import DateUtils from "../../../../utils/DateUtils";
import MissaoUser from "../../../../interfaces/MissaoUser";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Tag} from "primereact/tag";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import UserMissaoController from "./UserMissaoController";
import {Loading} from "../../../../components/loading/Loading";
import styles from "./UserMissao.module.scss";

interface UserMissaoProps {
    list: Array<MissaoUser>;
    update: () => void;
}

export function UserMissao(props: UserMissaoProps): ReactElement {
    const toast = useRef<Toast>(null);
    const [isResponseLoading, setIsResponseLoading] = useState(false);
    const controller = React.useCallback(
        () => new UserMissaoController(setIsResponseLoading, toast),
        [setIsResponseLoading, toast]
    );

    function completeMissao(missaoId: string | undefined): void {
        if (missaoId) {
            controller().completeMissao(missaoId, props.update);
        }
    }


    if (props.list.length === 0) {
        return <></>;
    }

    const start = (row: MissaoUser): string => {
        return DateUtils.format(row.start ?? row.missao?.start ?? "");
    };

    const end = (row: MissaoUser): string => {
        return DateUtils.format(row.end ?? row.missao?.end ?? "");
    };

    const dataAceite = (row: MissaoUser): string => {
        if (row.accept_date) {
            return DateUtils.format(row.accept_date);
        }
        return "";
    };

    const allUsers = (row: MissaoUser): ReactElement => {
        if (row.missao?.all_users === true) {
            return <Tag severity="success" value="SIM"/>;
        }

        return <Tag severity="danger" value="NÃO"/>;
    };

    const complete = (row: MissaoUser): ReactElement => {
        if (row.complete === true) {
            return <Tag severity="success" value="SIM"/>;
        }

        return <Tag severity="danger" value="NÃO"/>;
    };

    const progresso = (row: MissaoUser): string => {
        return `${row.progress ?? 0} de ${row.missao?.total ?? 0}`;
    };

    const btnComplete = (row: MissaoUser): ReactElement => {
        if (row.complete === true) {
            return <Tag severity="success" value="COMPLETADA"/>;
        }

        return <Button
            label="Completar"
            onClick={() => completeMissao(row.id)}
        />;
    };

    return (
        <>
            <Loading isLoading={isResponseLoading}/>
            <Toast ref={toast}/>
            <DataTable
                showGridlines
                value={props.list}
                scrollable
                scrollHeight="70vh"
                className={styles.missaoTable}
            >
                <Column header="MissaoUser ID" field="id" headerClassName={styles.cell} className={styles.cell}/>
                <Column header="Tipo" field="missao.tag_type" headerClassName={styles.cell} className={styles.cell}/>
                <Column header="Título" field="missao.title" headerClassName={styles.cell} className={styles.cell}/>
                <Column header="Objetivo" field="missao.objective" headerClassName={styles.cell}
                        className={styles.cell}/>
                <Column header="Progresso" body={progresso} headerClassName={styles.cell} className={styles.cell}/>
                <Column header="Completa" body={complete} headerClassName={styles.cell} className={styles.cell}/>
                <Column header="Aceito em" body={dataAceite} headerClassName={styles.cell} className={styles.cell}/>
                <Column header="Estados" field="missao.estados" headerClassName={styles.cell} className={styles.cell}/>
                <Column header="Todos usuários" body={allUsers} headerClassName={styles.cell} className={styles.cell}/>
                <Column header="Começo" body={start} headerClassName={styles.cell} className={styles.cell}/>
                <Column header="Fim" body={end} headerClassName={styles.cell} className={styles.cell}/>
                <Column header="Ação" body={btnComplete} headerClassName={styles.cell} className={styles.cell}/>
            </DataTable>
        </>
    );
}

import {Dispatch, RefObject, SetStateAction} from "react";
import {Toast} from "primereact/toast";
import ReportService from "../../services/ReportService";
import ReportStatus from "../../interfaces/ReportStatus";
import ReportResume from "../../interfaces/ReportResume";
import ReportUser from "../../interfaces/ReportUser";
import ReportLevel from "../../interfaces/ReportLevel";

class ReportController {
    private readonly dispatchLoading: Dispatch<SetStateAction<boolean>>;
    private readonly toast: RefObject<Toast>;

    constructor(
        dispatchLoading: Dispatch<SetStateAction<boolean>>,
        toast: RefObject<Toast>
    ) {
        this.dispatchLoading = dispatchLoading;
        this.toast = toast;
    }

    async getReportList(
        estado: string | undefined,
        userFiler: string | undefined,
        level: string | undefined,
    ): Promise<Array<ReportStatus>> {
        try {
            this.dispatchLoading(true);
            const response = await ReportService.getReportList(estado, userFiler, level)
            if (response.status === 200) {
                return response.data.data;
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }
        return [];
    }

    async getLevels(): Promise<Array<ReportLevel>> {
        try {
            this.dispatchLoading(true);
            const response = await ReportService.getLevels()
            if (response.status === 200) {
                return response.data.data;
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }
        return [];
    }

    async getReportResumeByCPF(
        cpf: string,
    ): Promise<Array<ReportResume>> {
        try {
            this.dispatchLoading(true);
            const response = await ReportService.getReportsResumeByCPF(cpf)
            if (response.status === 200) {
                return response.data.data;
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }
        return [];
    }

    async getReportById(
        id: number,
    ): Promise<ReportUser | undefined> {
        try {
            this.dispatchLoading(true);
            const response = await ReportService.getReportById(id)
            if (response.status === 200) {
                return response.data.data;
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }
        return;
    }

    async sendAnswer(id: number, message: string): Promise<boolean> {
        try {
            this.dispatchLoading(true);
            const response = await ReportService.sendAnswer({
                id: id,
                answer: message,
            });
            if (response.status !== 200) {
                this.toast.current?.show({severity: "error", detail: "Erro"});
                return false;
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
            return false;
        } finally {
            this.dispatchLoading(false);
        }
        return true;
    }
}

export default ReportController;

import api from "./Api";
import {AxiosResponse} from "axios";
import token from "./TokeService";

const completeMissao = (missaoId: string): Promise<AxiosResponse> => {
    return api.post(`/missao/complete?token=${token()}`, {
        missao_user_id: missaoId
    });
};

const MissaoUserService = {
    completeMissao,
};

export default MissaoUserService;

import React, {ReactElement, useRef, useState} from "react";
import styles from "./UserInfo.module.scss";
import {Card} from "primereact/card";
import {Tag} from "primereact/tag";
import {Button} from "primereact/button";
import {Calendar} from "primereact/calendar";
import DnnUser from "../../../interfaces/DnnUser";
import {EmptyBox} from "../../../components/empty_box/EmptyBox";
import {Toast} from "primereact/toast";
import NumberUtils from "../../../utils/NumberUtils";
import {useNavigate} from "react-router-dom";
import UserController from "../UserController";
import {Loading} from "../../../components/loading/Loading";
import DateUtils from "../../../utils/DateUtils";
import {ConfirmDialog} from "primereact/confirmdialog"; // To use <ConfirmDialog> tag
import {confirmDialog} from "primereact/confirmdialog"; // To use confirmDialog method
import {Dialog} from "primereact/dialog";
import PixInfo from "../../../interfaces/PixInfo";
import { Nullable } from "primereact/ts-helpers";

interface CardCancelamentoProps {
    show: boolean | undefined;
    data: string | undefined;
}

export function UserInfo(props: DnnUser): ReactElement {
    const today = new Date();
    const [mes, setMes] = useState<Nullable<Date>>(today);
    const toast = useRef<Toast>(null);
    const [isResponseLoading, setIsResponseLoading] = useState(false);
    const navigate = useNavigate();
    const controller = new UserController(setIsResponseLoading, toast);
    const [showCardCancelamento, setShowCardCancelamento] = useState(
        props.solicitouCancelamentoConta
    );
    const [pixInfoShow, setpixInfoShow] = useState<boolean>(false);
    const [pixInfo, setpixInfo] = useState<PixInfo | undefined>();

    if (props._id === undefined) {
        return (
            <>
                <Toast ref={toast}/>
                <EmptyBox msg="Não há dados"/>
            </>
        );
    }

    function copy(value: string): void {
        navigator.clipboard.writeText(value.toString() ?? "").then(() => {
            return toast.current?.show({
                severity: "success",
                summary: "Copiado",
                detail: value,
            });
        });
    }

    async function findPix(): Promise<void> {
        const data = await controller.getPixInfo(props.cpf ?? "");
        if (data) {
            setpixInfo(data);
            setpixInfoShow(true);
        }
    }

    async function cancelarSolicitacaoExclusao(isOk: boolean): Promise<void> {
        if (!isOk) {
            confirmDialog({
                message:
                    "Confirme o cancelamento da solicitação do usuário em deletar a conta. (não pode ser desfeito)",
                header: "Confirme o cancelamento",
                icon: "pi pi-info-circle",
                acceptClassName: "p-button-danger",
                accept: () => {
                    cancelarSolicitacaoExclusao(true);
                },
                acceptLabel: "Confirmar",
                rejectLabel: "Voltar",
            });
            return;
        }

        if (props._id) {
            setShowCardCancelamento(
                await controller.cancelarSolicitacaoExclusao(props._id)
            );
        }
    }

    const CardCancelamento = (props: CardCancelamentoProps): ReactElement => {
        if (props.show) {
            return (
                <Card
                    className={styles.bodyCancelamento}
                    title="Cancelamento Agendado"
                    footer={
                        <div className={styles.row}>
                            <Button
                                label="Cancelar Operação"
                                icon="pi pi-exclamation-triangle"
                                className="p-button-danger"
                                onClick={() => cancelarSolicitacaoExclusao(false)}
                            />
                        </div>
                    }
                >
                    <div className={styles.column}>
                        <span>Cancelamento de conta solicitado pelo usuário!</span>
                        <h3>Será concluida em: {DateUtils.format(props.data ?? "")}</h3>
                    </div>
                </Card>
            );
        }

        return <></>;
    };

    return (
        <>
            <ConfirmDialog/>
            {props.enable ? <Loading isLoading={isResponseLoading}/> : <></>}
            <Toast ref={toast}/>
            <Dialog
                header={`Informações do PIX: ${props.cpf}`}
                visible={pixInfoShow}
                className={styles.DialogPix}
                onHide={() => setpixInfoShow(false)}
                dismissableMask={true}
            >
                {pixInfo?.msg ? (
                    <div>
                        <div className={styles.row}>
                            <span>Pix Não Encontrado!</span>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className={styles.row}>
                            <label>Banco:</label>
                            <span>{pixInfo?.banco}</span>
                        </div>
                        <div className={styles.row}>
                            <label>Nome:</label>
                            <span>{pixInfo?.nome}</span>
                        </div>
                        <div className={styles.row}>
                            <label>Conta:</label>
                            <span>{pixInfo?.conta}</span>
                        </div>
                        <div className={styles.row}>
                            <label>Tipo:</label>
                            <span>{pixInfo?.tipo}</span>
                        </div>
                        <div className={styles.row}>
                            <label>Agência:</label>
                            <span>{pixInfo?.agencia}</span>
                        </div>
                    </div>
                )}
            </Dialog>
            <div className={styles.UserInfo}>
                <div className={styles.rowCard}>
                    <CardCancelamento
                        show={showCardCancelamento}
                        data={props.cancelamentoContaData}
                    />
                    <Card
                        className={styles.bodyCard}
                        title={
                            <div className={styles.rowInfo}>
                                <Button
                                    onClick={() => copy(props.cpf ?? "")}
                                    className="p-button-secondary p-button-text p-button-lg"
                                    style={{color: "black", padding: 0}}
                                >
                                    {props.cpf}
                                    <i className="pi pi-copy"/>
                                </Button>

                                {(props.email_count ?? 0) > 1 ? <Tag severity="danger">Email não é único!</Tag> : <></>}
                            </div>
                        }
                        subTitle={props.nome}
                    >
                        <div className={styles.column}>
                            <div className={styles.rowInfo}>
                                <label>Estado:</label>
                                <span>{props.estado}</span>
                            </div>
                            <div className={styles.rowInfo}>
                                <label>Email:</label>
                                <Button
                                    onClick={() => copy(props.email ?? "")}
                                    className="p-button-secondary p-button-text"
                                    style={{color: "black", padding: 0}}
                                >
                                    {props.email}
                                    <i className="pi pi-copy"/>
                                </Button>
                            </div>
                            <div className={styles.rowInfo}>
                                <label>Device:</label>
                                <span>
                  {props.device} ({props.so})
                </span>
                            </div>
                            {props.enable ? (
                                <div className={styles.rowInfo}>
                                    <Button
                                        label="Chat"
                                        icon="pi pi-envelope"
                                        onClick={() =>
                                            navigate(`/chat/${props.cpf}`, {replace: false})
                                        }
                                    />
                                    <Button
                                        className={styles.margin}
                                        label="PIX"
                                        icon="pi pi-money-bill"
                                        onClick={findPix}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                            <div className={styles.rowInfo}>
                                <Tag
                                    severity="info"
                                    icon="pi pi-copy"
                                    onClick={() => copy(props._id ?? "")}
                                    className={styles.tagClick}
                                >
                                    <span>{props._id}</span>
                                </Tag>
                            </div>
                        </div>
                    </Card>
                    <Card
                        className={styles.bodyCard}
                        title="Saldo"
                        footer={
                            <div className={styles.row}>
                                <Button
                                    label="Atualizar Saldo"
                                    icon="pi pi-wallet"
                                    onClick={() => controller.updateSaldo(props.cpf ?? "")}
                                />
                            </div>
                        }
                    >
                        <div className={styles.rowSaldo}>
                            <Tag
                                className={styles.saldo}
                                value={NumberUtils.money.format(props.saldo ?? 0)}
                                rounded={true}
                                severity="info"
                            />
                        </div>
                        <div>
                            {props.user_disable ? <Tag severity="danger">Cashback desabilitado</Tag> : <></>}
                        </div>
                    </Card>
                    <Card
                        className={styles.bodyCard}
                        title="Notas"
                        footer={
                            <div className={styles.row}>
                                <Button
                                    label="Buscar"
                                    icon="pi pi-search"
                                    onClick={() =>
                                        controller.goToNotas(
                                            props.cpf ?? "",
                                            (mes as Date).getFullYear(),
                                            (mes as Date).getMonth()
                                        )
                                    }
                                />
                                <Button
                                    label="Pelo Ano"
                                    icon="pi pi-search"
                                    className={styles.margin}
                                    onClick={() =>
                                        controller.goToNotasByYear(
                                            props.cpf ?? "",
                                            (mes as Date).getFullYear()
                                        )
                                    }
                                />
                            </div>
                        }
                    >
                        <div className={styles.column}>
                            <Calendar
                                id="monthpicker"
                                value={mes}
                                onChange={(e) => setMes(e.value)}
                                view="month"
                                dateFormat="mm/yy"
                                showIcon
                            />
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
}

import React, { ReactElement, ReactNode } from "react";
import styles from "./Modal.module.scss";

interface ModalProps {
  id: string;
  title: string;
  children: ReactNode
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}

export function Modal(props: ModalProps ): ReactElement {
  if (props.isOpen) {
    return (
      <div 
        id={props.id} 
        className={styles.modal}
      >
        <div className={styles.header}>
          <h3>{props.title}</h3>
        </div>
        <div>
          {props.children}
        </div>
      </div>
    )
  }
  return <></>
}
import styles from "./ChatForm.module.scss";
import { Button } from "primereact/button";
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useRef,
  useState,
} from "react";
import { Toast } from "primereact/toast";
import { Loading } from "../../../../components/loading/Loading";
import ChatFormController from "./ChatFormController";
import KeyEvent from "../../../../utils/KeyEvent";
import { useHotkeys } from "react-hotkeys-hook";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import { Editor } from "primereact/editor";
import { OverlayPanel } from "primereact/overlaypanel";
import { EmojiStyle } from "emoji-picker-react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import ChatAjudaInterface from "../../chat_ajuda/ChatAjudaInterface";
import ChatAjudaContent from "../../chat_ajuda/ChatAjudaContent";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import store from "store2";
import { Modal } from "../../../../components/modal/Modal";

interface PropsChatForm {
  cpf: string;
  nome: string;
  set: Dispatch<SetStateAction<string>>;
  setTab: Dispatch<SetStateAction<number>>;
  value: string;
}

export function ChatForm(props: PropsChatForm): ReactElement {
  const toast = useRef<Toast>(null);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const controller = React.useCallback(
    () => new ChatFormController(setIsResponseLoading, toast),
    [setIsResponseLoading, toast]
  );
  const [displayBasic, setDisplayBasic] = useState(false);
  const [estabelecimento, setEstabelecimento] = useState("");
  const op = useRef<OverlayPanel>(null);
  const formRef = useRef<Editor>(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [autocomplete, setAutocomplete] =
    useState<Array<ChatAjudaInterface> | null>(null);
  const [selectedAutocomplete, setSelectedAutocomplete] =
    useState<ChatAjudaInterface | null>(null);

  const storage = "options";
  const count = "options_count";

  function onClickEmoji(emojiData: EmojiClickData): void {
    const textHTML = props.value;

    props.set(
      `${textHTML.substring(
        0,
        textHTML.lastIndexOf("</p>")
      )}<img width="25" height="25" src=${emojiData.getImageUrl(
        EmojiStyle.GOOGLE
      )} alt=${emojiData.names}></p>`
    );
    op.current?.hide();
  }

  function removeFromCache(row: ChatAjudaInterface): void {
    const list = store.get(storage, []) as Array<ChatAjudaInterface>;
    store.set(
      storage,
      list.filter((elem) => elem.msg !== row.msg),
      true
    );
    setDisplayModal(false);
  }

  function cache(temp: string): void {
    temp = temp.replaceAll("\n", "");
    if (
      ChatAjudaContent.list.filter(
        (elem) => elem.msg.toLowerCase() === temp.toLowerCase()
      ).length === 0
    ) {
      const list = store.get(count, []) as Array<ChatAjudaInterface>;
      const total = list.filter(
        (elem) => elem.msg.toLowerCase() === temp.toLowerCase()
      ).length;
      list.push({ id: "", tema: "", msg: temp });
      store.set(count, list.slice(0, 100), true);

      if (total > 3) {
        const list = store.get(storage, []) as Array<ChatAjudaInterface>;

        if (
          list.filter((elem) => elem.msg.toLowerCase() === temp.toLowerCase())
            .length === 0
        ) {
          list.push({ id: "", tema: "", msg: temp });
          store.set(storage, list.slice(0, 500), true);
        }
      }
    }
  }

  async function send(value?: string | null): Promise<void> {
    setDisplayModal(false);
    const temp = value ?? props.value;

    cache(temp);

    const text = temp.replaceAll("<< nome_usuario >>", props.nome).trim();

    if (text.length === 0) {
      return;
    }

    if (text.indexOf("<< estabelecimento >>") !== -1) {
      setEstabelecimento("");
      setDisplayBasic(true);
      return;
    }

    const list = text.split("||");
    let success = true;

    for (const msg of list) {
      const result = await controller().sendChat(props.cpf, msg);
      success = success && result;
    }

    if (success) {
      props.set("");
      const editor = document.getElementsByClassName("ql-editor")[0];
      if (editor) {
        editor.innerHTML = "";
      }
      setDisplayModal(false);
    }
  }

  const onChangeForm = (): void => {
    const saved = store.get(storage, []) as Array<ChatAjudaInterface>;

    const filter = props.value.replaceAll("\n", "");
    if (filter.length === 0) {
      setDisplayModal(false);
      return;
    }
    const options = ChatAjudaContent.list.filter((elem) =>
      elem.msg.toLowerCase().startsWith(filter.toLowerCase())
    );

    const local = saved.filter((elem: ChatAjudaInterface) =>
      elem.msg.toLowerCase().startsWith(filter.toLowerCase())
    );

    if (options.length !== 0 || local.length !== 0) {
      local.forEach((elem) => options.push(elem));
      setAutocomplete(options.reverse());
      setSelectedAutocomplete(null);
      setDisplayModal(true);
    } else {
      setDisplayModal(false);
    }
  };

  function focus(): void {
    (
      document.querySelector(".ql-editor.ql-blank") as HTMLElement | null
    )?.focus();
  }

  const onSelectAutoComplete = (e: { value: ChatAjudaInterface }): void => {
    setSelectedAutocomplete(e.value);
    setDisplayModal(false);
    const text = e.value as ChatAjudaInterface;
    props.set(text.msg);
    focus();
  };

  function unfocus(): void {
    (document.querySelector("button.p-button") as HTMLElement | null)?.focus();
  }

  function onEnter(e: KeyEvent): void {
    if (displayModal && e.key === "ArrowUp") {
      const index =
        selectedAutocomplete !== null
          ? autocomplete?.lastIndexOf(selectedAutocomplete)
          : -1;
      if (index === -1) {
        setSelectedAutocomplete(
          autocomplete?.at(autocomplete.length - 1) ?? null
        );
        return;
      }

      const moveTo = (index ?? 1) - 1;

      if (moveTo >= 0) {
        setSelectedAutocomplete(autocomplete?.at(moveTo) ?? null);
      }

      return;
    }

    if (displayModal && e.key === "ArrowDown") {
      const index =
        selectedAutocomplete !== null
          ? autocomplete?.lastIndexOf(selectedAutocomplete)
          : -1;
      if (index === -1) {
        setSelectedAutocomplete(autocomplete?.at(0) ?? null);
        return;
      }

      const moveTo = (index ?? 1) + 1;

      if (moveTo < (autocomplete?.length ?? 0)) {
        setSelectedAutocomplete(autocomplete?.at(moveTo) ?? null);
      }

      return;
    }

    if (displayModal && e.key === "Enter") {
      setDisplayModal(false);
      if (selectedAutocomplete) {
        unfocus();
        onSelectAutoComplete({ value: selectedAutocomplete });
      }
      return;
    }

    onChangeForm();

    if (e.key === "Enter" && !e.shiftKey) {
      send();
    }
  }

  function sendText(ajuda: ChatAjudaInterface): void {
    props.set(`${props.value} ${ajuda.msg}`);
  }

  function sendNome(): void {
    props.set(`${props.value} ${props.nome.split(" ")[0]} `);
  }

  useHotkeys(
    "ctrl+i,shift+i",
    () => sendText(ChatAjudaContent.map.saque_invalidado),
    {
      enableOnFormTags: ["TEXTAREA"],
    }
  );
  useHotkeys(
    "ctrl+m,shift+m",
    () => sendText(ChatAjudaContent.map.proativo_notas_seq),
    {
      enableOnFormTags: ["TEXTAREA"],
    }
  );
  useHotkeys("space", () => sendNome(), {
    enableOnFormTags: ["TEXTAREA"],
  });

  const header = (
    <>
      <span className={`ql-formats ${styles.spanButtons}`}>
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
        <button className="ql-link" aria-label="Insert Link"></button>
        <button onClick={() => props.set("")}>
          <i className="pi pi-trash"></i>
        </button>
        <div className={styles.left}>
          <button onClick={(e) => op.current?.toggle(e)}>
            <i className="pi pi-star"></i>
          </button>
          <button
            onClick={() => props.setTab(1)}
            aria-haspopup
            aria-controls="overlay_panel"
          >
            <i className="pi pi-question-circle"></i>
          </button>
        </div>
      </span>
    </>
  );

  const renderFooter = (): ReactElement => {
    return (
      <div>
        <Button
          label="Confirmar"
          icon="pi pi-check"
          onClick={() => {
            setDisplayBasic(false);
            send(
              props.value.replaceAll("<< estabelecimento >>", estabelecimento)
            );
          }}
          autoFocus
        />
      </div>
    );
  };

  return (
    <>
      <Loading isLoading={isResponseLoading} />
      <Toast ref={toast} />
      <div className={styles.ChatForm}>
        <OverlayPanel ref={op} id="overlay_panel" className={styles.emojiPanel}>
          <EmojiPicker onEmojiClick={onClickEmoji}></EmojiPicker>
        </OverlayPanel>
        <Modal
          id={'modal_sugestoes'}
          title={'Sugestões'}
          isOpen={displayModal}
          setOpen={setDisplayModal}
        >
          <DataTable
            className={styles.autocomplete}
            value={autocomplete ?? ChatAjudaContent.list}
            selectionMode="single"
            rows={5}
            selection={selectedAutocomplete}
            onSelectionChange={(e) => onSelectAutoComplete({value: e.value as ChatAjudaInterface})}
            showHeaders={false}
          >
            <Column headerClassName={styles.hide} field="msg" />
            <Column
              headerClassName={styles.hide}
              body={(row: ChatAjudaInterface) => {
                if (row.id.length === 0) {
                  return (
                    <Button onClick={() => removeFromCache(row)}>
                      <i className="pi pi-trash"></i>
                    </Button>
                  );
                }
                return <></>;
              }}
            />
          </DataTable>
        </Modal>
        <Dialog
          header="Estabelecimento"
          visible={displayBasic}
          footer={renderFooter()}
          onHide={() => setDisplayBasic(false)}
        >
          <InputText
            value={estabelecimento}
            onChange={(e) => setEstabelecimento(e.target.value)}
          />
        </Dialog>
        <div className={styles.column}>
          <Editor
            ref={formRef}
            headerTemplate={header}
            className={styles.inputForm}
            value={props.value}
            //TODO: Quando migrar o chat e ativar os EMOJIs, usar o e.htmlValue
            onTextChange={(e) => props.set(e.textValue ?? "")}
            onChange={(e) => console.log(e.target)}
            onKeyUp={onEnter}
            disabled={isResponseLoading}
            autoComplete="on"
            aria-autocomplete="list"
          />
        </div>
        <div className={styles.columnSend}>
          <Button className={styles.sendbutton} onClick={() => send()}>
            <i className="pi pi-send"></i>
          </Button>
        </div>
      </div>
    </>
  );
}

export default ChatForm;

const money = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "BRL",
});

const NumberUtils = {
  money,
};

export default NumberUtils;

import React, { ReactElement, useEffect, useRef, useState } from "react";
import styles from "./ApuracaoInfoSCPC.module.scss";
import Apuracao from "../../../interfaces/Apuracao";
import Sorteio from "../../../interfaces/Sorteio";
import { Toast } from "primereact/toast";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../components/loading/Loading";
import { Button } from "primereact/button";
import RoutesEnum from "../../../properties/RoutesEnum";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InfoSCPC, ItemInfoSCPC } from "./InfoSCPC";
import ApuracaoController from "./ApuracaoController";
import PremioSorteioController from "../premio/PremioSorteioController";
import PremioSorteio from "../../../interfaces/PremioSorteio";
import Captura from "../../../interfaces/Captura";
import { TabPanel, TabView } from "primereact/tabview";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export function ApuracaoInfoSCPCPage(): ReactElement {

  const [apuracao, setApuracao] = useState<Apuracao | null>(null);
  const [sorteio, setSorteio] = useState<Sorteio | null>(null);
  const [premios, setPremios] = useState<PremioSorteio[]>([]);
  const [capturas, setCapturas] = useState<Captura[]>([]);
  const [itens, setItens] = useState<ItemInfoSCPC[]>([]);
  const [isResponseLoading, setIsResponseLoading] = useState(false);

  const toast = useRef<Toast>(null);
  const termo = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();
  const { idSorteio, idApuracao } = useParams();
  const idSorteioValue = idSorteio ? parseInt(idSorteio) : NaN;
  const idApuracaoValue = idApuracao ? parseInt(idApuracao) : NaN;

  const infoSCPC = new InfoSCPC();
  const controller = new ApuracaoController(setIsResponseLoading, toast);
  const controllerPremios = new PremioSorteioController(setIsResponseLoading, toast);

  async function getSorteio(): Promise<void> {
    await controller.getSorteioFromApuracao(idSorteioValue)
      .then((data) => {
        if (data != undefined) {
          setSorteio(data);
        }
      });
  }

  async function getApuracao(): Promise<void> {
    await controller.buscarApuracaoPeloId(idApuracaoValue)
      .then((data) => {
        if (data != undefined)
          setApuracao(data);
      });
  }

  async function getPremios(): Promise<void> {
    await controllerPremios.getPremiosByIdApuracao(
      idSorteioValue, 
      idApuracaoValue
    ).then((data) => {
      setPremios(data);
    });
  }

  async function getCapturas(): Promise<void> {
    await controller.getCapturasUrl().then((data) => {
      setCapturas(data);
    });
  }

  useEffect(() => {
    getSorteio();
    getApuracao();
    getPremios();
    getCapturas();
    setItens(infoSCPC.getItens());
  }, []);

  useEffect(() => {
    if (sorteio != null && apuracao != null) {
      infoSCPC.setSorteio(sorteio);
      infoSCPC.setApuracao(apuracao);
    }
    infoSCPC.setPremios(premios);
    infoSCPC.setCapturas(capturas);
    infoSCPC.atualizaItens();
    setItens(infoSCPC.getItens());
    console.log(sorteio)
  }, [sorteio, apuracao, premios]);

  function copiarDescricao(item: ItemInfoSCPC): void {
    navigator.clipboard.writeText(item.descricao).then(() => {
      toast.current?.show({
        severity: "success",
        detail: `Copiado com sucesso!`
      });
    }).catch(erro => {
      console.log(erro);
      toast.current?.show({
        severity: "error",
        detail: `Falha ao copiar`
      });
    })
  }

  function formatData(data: string | undefined): string {
    console.log(data)
    if (data) {
      return format(data, "dd 'de' MMMM 'de' yyyy", {locale: ptBR});
    }
    return '';
  }

  function downloadTermo(): void {
    const doc = termo.current;
    if (doc) {
      html2canvas(doc).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('TERMO DE MANDATARIA.pdf');
      });
    }
  }

  return (
    <main className={styles.main}>
      <Loading isLoading={isResponseLoading} />
      <Toast ref={toast} />
      <div className={styles.headerContainer}>
        <Button
          icon="pi pi-angle-left"
          onClick={() => {
            navigate(`${RoutesEnum.Sorteio}/${idSorteioValue}/apuracoes`)
          }}
          rounded
        />
        <h1>Checklist - Autorizar promoção comercial - {sorteio?.nome}</h1>
      </div>
      <TabView>
        <TabPanel header="Dados" contentClassName={styles.abaDados}>
          <DataTable value={itens} showGridlines>
            <Column
              field="item" 
              header="Item"
              body={(rowData) => (
                <div style={
                  { whiteSpace: 'pre-line', 
                    textAlign: 'start', 
                    padding: '0 10px',
                    fontSize: '12px'
                  }
                }>
                  {rowData.item}
                </div>
              )}
            />
            <Column
              field="descricao" 
              header="Descrição"
              style={{maxWidth: '500px'}}
              body={(rowData) => (
                <div style={
                  { whiteSpace: 'pre-line', 
                    textAlign: 'start', 
                    padding: '0 10px',
                    maxHeight: '300px',
                    overflowY: 'auto',
                    fontSize: '12px'
                  }
                }>
                  {rowData.descricao}
                </div>
              )}
            />
            <Column
              field="status" 
              header="Status"
              bodyStyle={{fontSize: '12px'}}
              
            />
            <Column
              field="comentarios" 
              header="Comentários"
              style={{maxWidth: '500px'}}
              body={(rowData) => (
                <div style={
                  { whiteSpace: 'pre-line', 
                    textAlign: 'start', 
                    padding: '0 10px',
                    maxHeight: '300px',
                    overflowY: 'auto',
                    fontSize: '12px'
                  }
                }>
                  {rowData.comentarios}
                </div>
              )}
            />
            <Column
              header="Ações"
              body={(item: ItemInfoSCPC) => (
                <div style={{
                  padding: '10px 0'
                }}>
                  <Button 
                  label="Copiar"
                  onClick={() => copiarDescricao(item)}
                  rounded
                />
                </div>
              )}
            />
          </DataTable>
        </TabPanel>
        <TabPanel header="Documentos" contentClassName={styles.abaDocumentos}>
          <div className={styles.botoes}>
            <Button 
              label="Termo de mandatária"
              icon="pi pi-download"
              severity="info"
              onClick={downloadTermo}
              rounded
            />
          </div>
          <div 
            className={styles.termo}
            ref={termo}
          >
            <h3 className={styles.titulo} >
              TERMO DE MANDATÁRIA
            </h3>
            <br/>
            <p className={styles.ident}>
              A pessoa jurídica BENCHMKT INTELIGÊNCIA E PESQUISAS DE MERCADO LTDA, registrada no CNPJ/MF, sob o nº 26.350.495/0001-72, 
              estabelecida no(a) AVENIDA OROSIMBO MAIA 360, SALA 509, CENTRO, CAMPINAS/SP, CEP 13010-211, telefone/fax nº +55 19 3291 5331, 
              representada neste ato por seu(s) representante(s) legal(is) abaixo assinado(s), DECLARA, para fins processuais junto à 
              SECAP – Secretaria de Avaliação, Planejamento, Energia e Loteria, como pessoa jurídica mandatária da promoção comercial 
              denominada “{sorteio?.nome}” a realizar-se no 
              período de {formatData(apuracao?.dataInicialParticipacao)} a {formatData(apuracao?.dataFinalParticipacao)} em que é 
              (são) parte(s) a(s) pessoa(s) jurídica(s) constante(s) do Plano de Operação, que:
            </p> 
            <p className={styles.ident} >
              a) responderá solidariamente com a(s) pessoa(s) jurídica(s) aderente(s), pelas obrigações de qualquer natureza 
              referentes à elaboração e execução do plano de operação, pela aquisição, conservação, entrega do(s) prêmio(s) e 
              pela prestação de contas; e
            </p>
            <p className={styles.ident} >
              b) manterá em sua sede, à disposição da fiscalização, pelo prazo estabelecido na legislação pertinente, todos 
              os documentos relativos à promoção comercial.
            </p>
            <br/>
            <p>
              Campinas, {formatData(sorteio?.createdAt)}
            </p>
            <br/>
            <p>
              Assina digitalmente o presente TERMO DE MANDATÁRIA, nos termos da Medida Provisória nº 2.200-02, de 24 de agosto de 2001.
            </p>
            <br/>
            <p>
              <b>Representante Legal da Empresa</b>
            </p>
            <p>
              Nome: EDUARDO ZANONI MARQUES
            </p>
            <p>
              CPF: 049.090.719-97
            </p>
            <p>
              Cargo/Função: SÓCIO ADMINISTRADOR
            </p>
          </div>
        </TabPanel>
      </TabView>
      
      
      
    </main>
  );
}
import {AxiosResponse} from "axios";
import api from "./Api";
import token from "./TokeService";

const fecharLote = (): Promise<AxiosResponse> => {
    return api.post(`/solicitacao_saque/fechar_lote?token=${token()}`);
};

const SaqueService = {
    fecharLote
}

export default SaqueService;

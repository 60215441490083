import { Loading } from "../../../components/loading/Loading";
import { Toast } from "primereact/toast";
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import ChatController from "../ChatController";
import ChatCard from "./chat_card/ChatCard";
import { EmptyBox } from "../../../components/empty_box/EmptyBox";
import ChatStatus from "../../../interfaces/ChatStatus";
import ChatMessage from "../../../interfaces/ChatMessage";
import { Chip } from "primereact/chip";
import ScrollToBottom from "react-scroll-to-bottom";
import ChatForm from "./chat_form/ChatForm";
import styles from "./ChatBox.module.scss";
import { Toolbar } from "primereact/toolbar";
import { ChatAjudaButton } from "../chat_ajuda/ChatAjudaButton";
import DateUtils from "../../../utils/DateUtils";
import ChatAjudaContent from "../chat_ajuda/ChatAjudaContent";

interface ChatBoxProps {
  user?: ChatStatus | null;
  set: Dispatch<SetStateAction<string>>;
  setTab: Dispatch<SetStateAction<number>>;
  value: string;
}

export function ChatBox(props: ChatBoxProps): ReactElement {
  const toast = useRef<Toast>(null);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [pauseUpdate, setPauseUpdate] = useState(false);
  const controller = React.useCallback(
    () => new ChatController(setIsResponseLoading, toast),
    [setIsResponseLoading, toast]
  );
  const [source, setSource] = useState<Array<ChatMessage>>([]);
  const listDate: Array<string> = [];

  useEffect(() => {
    async function getChatUser(): Promise<void> {
      if (pauseUpdate) return;

      if (props.user) {
        const data = await controller().getChatUser(props.user.cpf);
        if (data != null) {
          setSource(data);
        }
      }
    }
    getChatUser();

    const interval = setInterval(getChatUser, 2000);
    return () => clearInterval(interval);
  }, [controller, props.user, props]);

  const isVisible = (pos: DOMRect): boolean =>
    pos.top >= 0 && pos.bottom <= window.innerHeight * 0.2;

  const getDate = (value: string): string => value.split(" ")[0];

  useEffect(() => {
    listDate.forEach((date) => {
      const element = document.getElementById(`chip_${getDate(date)}`);
      if (element) {
        element.setAttribute("class", styles.hideChip);
      }
    });

    source.forEach((msg, index) => {
      const element = document.getElementById(`msg_${index}`);
      if (element) {
        if (isVisible(element.getBoundingClientRect())) {
          const chip = document.getElementById(
            `chip_${getDate(msg.date_text)}`
          );
          if (chip) {
            chip.setAttribute("class", styles.fixedChipDate);
          }
        }
      }
    });
  });

  if (!props.user) {
    return <EmptyBox msg="Escolha um CPF" />;
  }

  const toolBarContent = (
    <>
      {ChatAjudaContent.list.map((item, index) => {
        return (
          <>
            <ChatAjudaButton
              set={props.set}
              value={props.value}
              type={item}
              key={`btn_${index}`}
            />
          </>
        );
      })}
    </>
  );

  let lastDate = DateUtils.formatDate(new Date());
  const buildDateChips = (
    nowDate: string,
    className: string,
    id?: string
  ): ReactElement => {
    if (listDate.indexOf(nowDate) === -1) {
      listDate.push(nowDate);
    }

    if (lastDate !== nowDate) {
      lastDate = nowDate;
      return (
        <div className={className} id={id} key={id}>
          <Chip className={styles.chip} template={nowDate} />
        </div>
      );
    }

    return <></>;
  };

  const buildChat = (msg: ChatMessage, index: number): ReactElement => {
    const nowDate = getDate(msg.date_text);
    return (
      <div id={`msg_${index}`} key={`msg_${index}`}>
        {buildDateChips(nowDate, styles.chipDate)}
        <ChatCard msg={msg} pauseUpdate={setPauseUpdate} />
      </div>
    );
  };

  const buildHideChips = (msg: ChatMessage): ReactElement => {
    const nowDate = getDate(msg.date_text);
    return buildDateChips(nowDate, styles.hideChip, `chip_${nowDate}`);
  };

  return (
    <>
      <Loading isLoading={isResponseLoading} />
      <Toast ref={toast} />
      <div className={styles.ChatBox}>
        <div>
          {source.map(buildHideChips)}
          <ScrollToBottom
            className="custom-scroll"
            followButtonClassName="pi pi-arrow-down custom-scroll-icon"
          >
            {source.map(buildChat)}
          </ScrollToBottom>
        </div>
        <div className={styles.row}>
          <ChatForm
            cpf={props.user.cpf}
            nome={props.user.name}
            value={props.value}
            set={props.set}
            setTab={props.setTab}
          />
          <Toolbar className={styles.toolbar} left={toolBarContent} />
        </div>
      </div>
    </>
  );
}

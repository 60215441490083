import React, { 
	FormEvent, 
	ReactElement, 
	useEffect, 
	useState 
} from "react"
import styles from "./SorteioForm.module.scss"
import Sorteio from "../../../interfaces/Sorteio";
import SorteioController from "../SorteioController";
import { format } from "date-fns";
import { ptBR } from 'date-fns/locale';
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Editor } from "primereact/editor";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Nullable } from "primereact/ts-helpers";
import { EstadosUtils } from "../../../utils/EstadosUtils";

interface SorteioFormProps {
	visible: boolean;
	setVisible: (valor: boolean) => void;
	sorteioForm: Sorteio | undefined;
	setSorteioForm: (sorteio: Sorteio) => void;
	controller: SorteioController;
	getSorteios: (page: number, size: number) => Promise<void>;
	page: number;
	size:number;
	acaoForm: string;
}

export function SorteioForm(props: SorteioFormProps): ReactElement {
	const [id, setId] = useState<number | null>(null)
	const [nome, setNome] = useState('');
	const [dataInicio, setDataInicio] = 
		useState<Nullable<Date>>(null);
	const [regulamento, setRegulamento] = useState('');
	const [descricao, setDescricao] = useState('');
	const [secap, setSecap] = useState('');
	const [estados, setEstados] = useState(['']);

	const estadosUtils = new EstadosUtils();
	const estadosBr = estadosUtils.getSiglas();

	useEffect(() => {
		if (props.sorteioForm) {
			const {
				id,
				nome,
				dataInicio,
				regulamento, 
				descricaoPremios, 
				nrCertificadoSecap, 
				estados 
			} = props.sorteioForm;
			
			setId(id);
			setNome(nome);
			setDataInicio(new Date(dataInicio));
			setRegulamento(regulamento);
			setDescricao(descricaoPremios);
			setSecap(nrCertificadoSecap);
			setEstados(estados.split(','));
		}
	}, [props.sorteioForm]);

	function buildSorteio(): Sorteio {
		return {
			id: id,
			nome: nome,
			dataInicio: format(
				dataInicio as Date, 
				'yyyy-MM-dd HH:mm:ss', 
				{ locale: ptBR }
			),
			regulamento: regulamento,
			descricaoPremios: descricao,
			nrCertificadoSecap: secap,
			estados: estados.join(),
			ativa: true,
			tipo: 'sorteio',
			quantidadeElementosSorteaveis: 100000,
			quantidadeSeries: 100,
			createdAt: ''
		}
	}

	function handleSubmit(event: FormEvent): void {
		event.preventDefault();
		const sorteio = buildSorteio();
		if(props.acaoForm === 'Criar') {
			props.controller.createSorteio(sorteio).then(() => {
				props.setVisible(false);
				props.getSorteios(props.page, props.size);
			});
		} else if (props.acaoForm === 'Editar') {
			props.controller.updateSorteio(sorteio).then(() => {
				props.setVisible(false);
				props.getSorteios(props.page, props.size);
			});
		}
	}

	function handleCheckboxChange(e: CheckboxChangeEvent): void {
		const estadosArray = [...estados];

		if (e.checked)
			estadosArray.push(e.value);
		else 
			estadosArray.splice(estados.indexOf(e.value), 1);

		setEstados(estadosArray);
	}

	return (
		<>
			<Dialog 
				className={styles.modal}
				header={`${props.acaoForm} sorteio`}
				onHide={() => props.setVisible(false)}
				visible={props.visible}
			>
				<form onSubmit={handleSubmit}>
					<div className={styles.formRow}>
						<div className={styles.formGroup}>
							<label 
								className={styles.inputNome} 
								htmlFor="nome"
							>
								Nome
							</label>
							<InputText 
								value={nome} 
								className={styles.inputNome} 
								id="nome"
								type="text" 
								placeholder="Nome do sorteio"
								onChange={(e) => setNome(e.target.value)}
								required
							/>
						</div>
						<div className={styles.formGroup}>
							<label 
								className={styles.inputDataInicial} 
								htmlFor="data_inicio"
							>
								Data de Início
							</label>
							<Calendar 
								value={dataInicio} 
								className={styles.inputDataInicial} 
								inputId="data_inicio"
								placeholder="dia / mês / ano"
								showIcon
								dateFormat="dd/mm/yy"
								onChange={(e) => setDataInicio(e.value)}
								locale="pt-br"
								required
							/>
						</div>
					</div>
					<div className={styles.formRow}>
						<div className={styles.formGroup}>
							<label htmlFor="regulamento">Regulamento</label>
							<InputText 
								value={regulamento} 
								id="regulamento" 
								type="text" 
								placeholder="Regulamento"
								onChange={(e) => setRegulamento(e.target.value)}
								required
							/>
						</div>
					</div>
					<div className={styles.formRow}>
						<div className={styles.formGroup}>
							<p 
								className={styles.label} 
								role="label"
							>
								Descricao
							</p>
							<Editor 
								style={{height:'250px'}}
								value={descricao} 
								id="descricao" 
								placeholder="Descrição do sorteio"
								onTextChange={(e) => {
									setDescricao(e.htmlValue as string)}
								}
								required
							/>
						</div>
					</div>
					<div className={styles.formRow}>
						<div className={styles.formGroup}>
							<label 
								htmlFor="secap" 
								className="mt-2"
							>
								Secap
							</label>
							<InputText 
								value={secap} 
								id="secap" 
								type="text" 
								placeholder="Secap" 
								onChange={(e) => setSecap(e.target.value)}
								required
							/>
						</div>
					</div>
					<div className={styles.formRow}>
						<div className={styles.formGroup}>
							<p 
								className={styles.label} 
								role="label"
							>
								Estados participantes
							</p>
							<div className={styles.checkboxStatesContainer}>
								{estadosBr.map(estado => {
									return (
										<div 
											key={estado} 
											className={styles.checkboxState}
										>
											<Checkbox 
												inputId={estado} 
												value={estado}
												checked={
													estados.includes(estado)
												}
												onChange={handleCheckboxChange}
											/>
											<label 
												htmlFor={estado}
											>
												{estado}
											</label>
										</div>
									)
								})}
							</div>
						</div>
					</div>
					<div className={styles.buttonsFormContainer}>
						<Button 
							label={props.acaoForm} 
							className="p-button-rounded p-button-info"
							type="submit"
						/>
						<Button 
							label="Cancelar" 
							className="p-button-rounded p-button-danger" 
							type="button"
							onClick={() => props.setVisible(false)}
						/>
					</div>
				</form>
			</Dialog>
		</>
	)
}
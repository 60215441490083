import React, { ReactElement } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Compartilhamento from "../../../../interfaces/Compartilhamento";
import DateUtils from "../../../../utils/DateUtils";

interface UserCompartilhamentoProps {
  list: Array<Compartilhamento>;
}

export function UserCompartilhamento(
  props: UserCompartilhamentoProps
): ReactElement {
  if (props.list.length === 0) {
    return <></>;
  }
  const createdAt = (row: Compartilhamento): string => {
    return DateUtils.format(row.created_at);
  };
  const premiado = (row: Compartilhamento): string => {
    return row.premiado.toString();
  };

  return (
    <>
      <DataTable
        showGridlines
        value={props.list}
        scrollable
        scrollHeight="70vh"
      >
        <Column header="Data" body={createdAt} />
        <Column field="convidado" header="Convidado" />
        <Column header="Premiado" body={premiado} />
      </DataTable>
    </>
  );
}

import React, { 
  ReactElement, 
  useEffect, 
  useRef, 
  useState 
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./Apuracao.module.scss";
import { ApuracaoForm } from "../forms/ApuracaoForm";
import RoutesEnum from "../../../properties/RoutesEnum";
import Apuracao from "../../../interfaces/Apuracao";
import ApuracaoController from "./ApuracaoController";
import { addDays, addMonths, format, getDay, startOfMonth, subDays } from "date-fns";
import { ptBR } from 'date-fns/locale';
import { Loading } from "../../../components/loading/Loading";
import Sorteio from "../../../interfaces/Sorteio";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MenuItem } from "primereact/menuitem";
import { TieredMenu } from "primereact/tieredmenu";

export function ApuracaoPage(): ReactElement {

  const [sorteio, setSorteio] = useState<Sorteio | null>(null)
  const [formVisible, setFormVisible] = useState(false);
  const [apuracoes, setApuracoes] = useState<Apuracao[]>([]);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [acaoForm, setAcaoForm] = useState('');
  const [apuracaoForm, setApuracaoForm] = useState<Apuracao | null>(null);
  const menu = useRef<TieredMenu>(null);
  
  const navigate = useNavigate();

  const toast = useRef<Toast>(null);

  const { idSorteio } = useParams();
  const idSorteioValue = idSorteio ? parseInt(idSorteio) : NaN;

  const controller = new ApuracaoController(setIsResponseLoading, toast);

  function changeFormVisibility(visible: boolean): void {
    setFormVisible(visible);
  }

  async function getSorteio(): Promise<void> {
    const sorteioResponse =
      await controller.getSorteioFromApuracao(idSorteioValue);
    if (sorteioResponse != undefined)
      setSorteio(sorteioResponse);
  }

  async function getApuracoes(): Promise<void> {
    const apuracoesResponse = 
      await controller.getApuracoesByIdSorteio(idSorteioValue);
    setApuracoes(apuracoesResponse);
  }

  useEffect(() => {
    getApuracoes();
    getSorteio();
  }, []);

  function segundaQuartaDoMesSequinte(): string {
    let segundaQuarta = '';
    const dataInicio = sorteio?.dataInicio;
    if (dataInicio != undefined) {
      const inicioProxMes = startOfMonth(addMonths(dataInicio, 1));
      const diaDaSemana = getDay(inicioProxMes);
      // 3 representa o valor da quarta-feira na semana (começa de 0 no domingo)
      const qtdDiasAtePrimeiraQuarta = (3 - diaDaSemana + 7) % 7;
      const primeiraQuarta = addDays(inicioProxMes, qtdDiasAtePrimeiraQuarta);
      segundaQuarta = format(
        addDays(primeiraQuarta, 7),
        'yyyy-MM-dd HH:mm:ss', 
        {locale: ptBR}
      );
    }
    return segundaQuarta;
  }

  function terceiraTercaDoMesSeguinte(): string {
    let terceiraTerca = '';
    const dataInicio = sorteio?.dataInicio;
    if (dataInicio != undefined) {
      const inicioProxMes = startOfMonth(addMonths(dataInicio, 1));
      const diaDaSemana = getDay(inicioProxMes);
      // 2 representa o valor da terça-feira na semana (começa de 0 no domingo)
      const qtdDiasAtePrimeiraTerca = (2 - diaDaSemana + 7) % 7;
      const primeiraTerca = addDays(inicioProxMes, qtdDiasAtePrimeiraTerca);
      terceiraTerca = format(
        addDays(primeiraTerca, 14),
        'yyyy-MM-dd HH:mm:ss', 
        {locale: ptBR}
      );
    }
    return terceiraTerca;
  }

  function onNovaApuracao(): void {
    setAcaoForm('Criar');
    setApuracaoForm({
      id: null,
      dataInicialParticipacao: format(
        sorteio?.dataInicio as string,
        'yyyy-MM-dd HH:mm:ss',
        {locale: ptBR}
      ),
      dataFinalParticipacao: format(
        subDays(startOfMonth(addMonths(sorteio?.dataInicio as string, 1)), 1),
        'yyyy-MM-dd HH:mm:ss',
        {locale: ptBR}
      ),
      dataInicialEnvioNotas: format(
        sorteio?.dataInicio as string,
        'yyyy-MM-dd HH:mm:ss',
        {locale: ptBR}
      ),
      dataFinalEnvioNotas: format(
        addDays(startOfMonth(addMonths(sorteio?.dataInicio as string, 1)), 1),
        'yyyy-MM-dd HH:mm:ss',
        {locale: ptBR}
      ),
      data_apuracao: terceiraTercaDoMesSeguinte(),
      dataExtracaoLoteriaFederal: segundaQuartaDoMesSequinte(),
      numeroExtracaoLoteriaFederal: '',
      numerosSorteadosPelaExtracaoLoteriaFederal: '',
      numeroDaSorteSorteado: ''
    });
    setFormVisible(true);
  }

  function desformatarNumerosSorteados(numerosSorteados: string | null): string {
    if (numerosSorteados) {
      let numerosDesformatados = numerosSorteados.slice(1, -1);
      numerosDesformatados = numerosDesformatados.replace(/'/g, '');
      return numerosDesformatados;
    }
    return '';
  }

  function onEditApuracao(apuracao: Apuracao): void {
    setAcaoForm('Editar');
    const apuracaoToForm = {...apuracao}
    if (apuracaoToForm.numerosSorteadosPelaExtracaoLoteriaFederal) {
      const numerosDesformatados = desformatarNumerosSorteados(
        apuracaoToForm.numerosSorteadosPelaExtracaoLoteriaFederal
      );
      apuracaoToForm
        .numerosSorteadosPelaExtracaoLoteriaFederal = numerosDesformatados;
    }
    setApuracaoForm(apuracaoToForm);
    setFormVisible(true);
  }

  function deleteApuracao(idApuracao: number): void {
    controller.removeApuracao(idSorteioValue, idApuracao)
      .then(() => {
        getApuracoes();
      });
  }

  function dialogConfirm(apuracao: Apuracao): void {
    confirmDialog({
      message: `Deseja realmente remover a apuração?`,
      header: "Confirme a remoção",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-rounded",
      accept: () => deleteApuracao(apuracao.id as number),
      acceptLabel: "Confirmar",
      rejectLabel: "Cancelar",
    });
  }

  function actionButtons(apuracao: Apuracao): ReactElement {
    const items: MenuItem[] = [
      {
        label: 'Iniciar Apuração',
        command: () => {
          navigate(`${RoutesEnum.Sorteio}/${idSorteio}/apuracoes/${apuracao.id}`);
        }
      },
      {
        label: 'Documentos',
        command: () => {
          navigate(`${RoutesEnum.Sorteio}/${idSorteio}/apuracoes/${apuracao.id}/documentos`);
        }
      },
      {
        label: 'Informações SCPC',
        command: () => {
          navigate(`${RoutesEnum.Sorteio}/${idSorteio}/apuracoes/${apuracao.id}/informacoes`);
        }
      },
      {
        label: 'CSV números',
        icon: 'pi pi-download',
        command: () => {
          controller.getCsvNumerosApuracao(apuracao.id as number);
        }
      }
    ];
    return (
      <div className={styles.actionsTable}>
        <Button 
          label="Prêmios" 
          onClick={() => {
            navigate(`${RoutesEnum.Sorteio}/${idSorteio}/apuracoes/${apuracao.id}/premios`)}
          } 
          className="p-button-rounded"
        />
        <Button 
          icon="pi pi-pencil" 
          className="p-button-rounded p-button-info"
          onClick={() => onEditApuracao(apuracao)}   
        />
        <Button 
          icon="pi pi-trash" 
          className="p-button-rounded p-button-danger" 
          onClick={() => dialogConfirm(apuracao)}
        />
        <TieredMenu 
          model={items} 
          popup 
          ref={menu} 
          id="overlay_tmenu" 
        />
        <Button 
          icon="pi pi-ellipsis-v" 
          severity="secondary"
          onClick={(event) => menu.current?.toggle(event)} 
          aria-controls="overlay_tmenu" 
          aria-haspopup 
          rounded
        />
      </div>
    )
  }

  return (
    <>
      <h1>Apurações</h1>
      <ConfirmDialog />
      <Loading isLoading={isResponseLoading} />
      <Toast ref={toast} />
      <div className={styles.toolbar}>
        <div className={styles.leftToolbar}>
          <Button 
            className="p-button-rounded"
            icon="pi pi-angle-left"
            onClick={() => navigate(`${RoutesEnum.Sorteio}`)}
          />
          <Button
            label="Novo"
            icon="pi pi-plus"
            className="p-button-rounded"
            onClick={onNovaApuracao}
          />
        </div>
      </div>
      <ApuracaoForm
        visible={formVisible}
        setVisible={changeFormVisibility}
        apuracaoForm={apuracaoForm as Apuracao}
        acaoForm={acaoForm}
        idSorteio={idSorteioValue}
        controller={controller}
        getApuracoes={getApuracoes}
      />
      <main>
        <DataTable value={apuracoes} showGridlines>
          <Column 
            field="dataInicialParticipacao" 
            header="Data inicial de participação" 
          />
          <Column 
            field="dataFinalParticipacao" 
            header="Data final de participação" 
          />
          <Column 
            field="dataInicialEnvioNotas" 
            header="Data inicial de envio de notas" 
          />
          <Column 
            field="dataFinalEnvioNotas" 
            header="Data final de envio de notas" 
          />
          <Column 
            field="dataExtracaoLoteriaFederal" 
            header="Data de extração" 
          />
          <Column 
            field="data_apuracao" 
            header="Data de apuração" 
          />
          <Column 
            field="numeroExtracaoLoteriaFederal" 
            header="Número de extração" 
          />
          <Column 
            field="numerosSorteadosPelaExtracaoLoteriaFederal" 
            header="Números sorteados" 
          />
          <Column 
            field="numeroDaSorteSorteado" 
            header="Número da sorte" 
          />
          <Column 
            body={actionButtons} 
            header="Ações"
          />
        </DataTable>
      </main>
    </>
  )
}
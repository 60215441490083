import React, {ReactElement, useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import NotasController from "./NotasController";
import {Loading} from "../../components/loading/Loading";
import styles from "./NotasPage.module.scss";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import NotaInfo from "../../interfaces/NotaInfo";
import DateUtils from "../../utils/DateUtils";
import {Tag} from "primereact/tag";
import {Toolbar} from "primereact/toolbar";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

export function NotasPage(): ReactElement {
    const urlParams = new URLSearchParams(window.location.search);
    const toast = useRef<Toast>(null);
    const [isResponseLoading, setIsResponseLoading] = useState(false);

    const [source, setSource] = useState<Array<NotaInfo>>([]);
    const [useLastSaque, setUseLastSaque] = useState("n");
    const [year, setYear] = useState(parseInt(urlParams.get("year") ?? "0"));
    const [month, setMonth] = useState(parseInt(urlParams.get("month") ?? "0"));
    const [cpf, setCPF] = useState(urlParams.get("cpf") ?? "");

    const controller = React.useCallback(
        () => new NotasController(setIsResponseLoading, toast),
        [setIsResponseLoading, toast]
    );

    async function getNotasList(psql?: boolean): Promise<void> {
        const data = await controller().get(year, month, cpf ?? "", useLastSaque, psql);
        if (data) {
            setSource(data);
        }
    }

    async function getNotasListPsql(): Promise<void> {
        getNotasList(true);
    }

    useEffect(() => {
        getNotasList();
    }, [setSource, controller, month, year]);

    const dataNota = (row: NotaInfo): string => {
        return DateUtils.format(row.date ?? "");
    };

    const dataEnvio = (row: NotaInfo): string => {
        return DateUtils.format(row.created_at ?? "");
    };

    const link = (row: NotaInfo): ReactElement => {
        if (row.link) {
            return <a href={row.link}>LINK</a>;
        }

        return <span>{row.chave_acesso}</span>;
    };

    const categoria = (row: NotaInfo): ReactElement => {
        if (row.cnpj) {
            return (
                <div>
                    <Tag severity="success" value={row.cnpj.ce_nivel_1}></Tag>
                    <Tag severity="info" value={row.cnpj.ce_nivel_2}></Tag>
                    <Tag severity="danger" value={row.cnpj.ce_nivel_3}></Tag>
                </div>
            );
        }

        return <span></span>;
    };

    const cnpj = (row: NotaInfo): string => {
        if (row.cnpj?.cnpj) {
            return row.cnpj.cnpj;
        }

        return "";
    };

    const months = [
        {label: "Todos", value: 0},
        {label: "Janeiro", value: 1},
        {label: "Fevereiro", value: 2},
        {label: "Março", value: 3},
        {label: "Abril", value: 4},
        {label: "Maio", value: 5},
        {label: "Junho", value: 6},
        {label: "Julho", value: 7},
        {label: "Agosto", value: 8},
        {label: "Setembro", value: 9},
        {label: "Outubro", value: 10},
        {label: "Novembro", value: 11},
        {label: "Dezembro", value: 12},
    ];

    const leftMenus = (
        <>
            <div className={styles.select}>
        <span className="p-float-label">
          <Dropdown
              inputId="year_dropdown"
              value={year}
              onChange={(e) => setYear(e.value)}
              placeholder={"Ano"}
              options={Array.from(Array(20).keys()).map((x) => x + 2017 + 1)}
          />
          <label htmlFor="year_dropdown">Ano</label>
        </span>
            </div>
            <div className={styles.select}>
        <span className="p-float-label">
          <Dropdown
              inputId="month_dropdown"
              value={month}
              onChange={(e) => setMonth(e.value)}
              placeholder={"Mês"}
              options={months}
          />
          <label htmlFor="month_dropdown">Mês</label>
        </span>
            </div>
            <div className={styles.smallPad}>
        <span className="p-float-label">
          <InputText
              id="input_cpf"
              value={cpf}
              onChange={(e) => setCPF(e.target.value)}
          />
          <label htmlFor="input_cpf">CPF</label>
        </span>
            </div>
            <div className={styles.smallPad}>
                <Button
                    icon="pi pi-search"
                    className="p-button-rounded p-button-success"
                    aria-label="Search"
                    onClick={() => { getNotasList()}}
                />
            </div>
            <div className={styles.smallPad}>
                <Button
                    className="p-button-rounded p-button-success"
                    label=" PSQL "
                    icon="pi pi-search"
                    onClick={getNotasListPsql}
                  />
            </div>
        </>
    );

    return (
        <>
            <Loading isLoading={isResponseLoading}/>
            <Toast ref={toast}/>
            <div className={styles.notasPage}>
                <div className={styles.tab}>
                    <Toolbar left={leftMenus}/>
                    <DataTable
                        value={source}
                        responsiveLayout="scroll"
                        className={styles.table}
                        showGridlines
                        rowHover
                    >
                        <Column field="id" header="ID"/>
                        <Column body={dataNota} header="Data nota"/>
                        <Column body={dataEnvio} header="Data envio"/>
                        <Column field="number" header="Número"/>
                        <Column body={cnpj} header="CNPJ"/>
                        <Column field="nome_emitente" header="Emitente"/>
                        <Column body={categoria} header="Categoria"/>
                        <Column field="source" header="Fonte"/>
                        <Column field="type" header="Tipo"/>
                        <Column field="state" header="Estado"/>
                        <Column field="status" header="Status"/>
                        <Column body={link} header="link"/>
                    </DataTable>
                </div>
            </div>
        </>
    );
}

import styles from "./ChatAjuda.module.scss";

import React, { Dispatch, ReactElement, SetStateAction, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import ChatAjudaInterface from "./ChatAjudaInterface";

interface PropsChatAjuda {
  set: Dispatch<SetStateAction<string>>;
  value: string;
  type: ChatAjudaInterface;
}

export function ChatAjuda(props: PropsChatAjuda): ReactElement {
  const [original, setOriginal] = useState(props.type);
  const [content, setContent] = useState(original.msg);
  const [active, setActive] = useState(false);

  const close = (): void => {
    setActive(false);
    setContent(original.msg);
  };

  const save = (): void => {
    setActive(false);
    original.msg = content;
    setOriginal(original);
  };

  const open = (): void => setActive(true);

  const sendText = (): void => {
    props.set(`${props.value} ${original.msg}`);
  };

  return (
    <>
      <div className={styles.ChatAjuda}>
        {active ? (
          <>
            <InputTextarea
              className={styles.text}
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
            <div className={styles.row}>
              <Button onClick={close}>Cancelar</Button>
              <Button onClick={save}>Salvar (Não é Permanente)</Button>
            </div>
          </>
        ) : (
          <>
            <h4>{original.tema}</h4>
            <div onDoubleClick={open}>{content.replaceAll("||", "\n")}</div>
            <div className={styles.row}>
              <Button onClick={sendText}>{original.id}</Button>
            </div>
          </>
        )}
      </div>
      <hr className={styles.divider} />
    </>
  );
}

import React, {ReactElement, useEffect, useRef, useState} from "react";
import ChatController from "../ChatController";
import ChatStatusContador from "../../../interfaces/ChatStatusContador";
import {Toast} from "primereact/toast";
import {Loading} from "../../../components/loading/Loading";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import styles from "./ChatStatusTab.module.scss";


export function ChatStatusTab(): ReactElement {
    const [list, setList] = useState<Array<ChatStatusContador> | undefined>();
    const toast = useRef<Toast>(null);
    const [isResponseLoading, setIsResponseLoading] = useState(false);

    const controller = React.useCallback(
        () => new ChatController(setIsResponseLoading, toast),
        [setIsResponseLoading, toast]
    );

    async function getChatStatus(): Promise<void> {
        const data = await controller().getChatStatus();
        if (data) {
            setList(data);
        }
    }

    useEffect(() => {
        getChatStatus();
    }, [setList]);

    return (
        <>
            <Loading isLoading={isResponseLoading}/>
            <Toast ref={toast}/>
            <div className={styles.ChatStatusTab}>
                <DataTable
                    scrollable
                    scrollHeight="90vh"
                    value={list}
                    responsiveLayout="scroll"
                    emptyMessage="Carregando..."
                    className={styles.body}
                >
                    <Column field="label" header="ESTADO" bodyClassName={styles.row}/>
                    <Column field="count" header="TOTAL" bodyClassName={styles.row}/>
                </DataTable>
            </div>
        </>
    );

}

import { Dispatch, RefObject, SetStateAction } from "react";
import { Toast } from "primereact/toast";
import UserService from "../../../../services/UserService";
import Pesquisa from "../../../../interfaces/Pesquisa";

class UserPesquisaController {
  private readonly dispatchLoading: Dispatch<SetStateAction<boolean>>;
  private readonly toast: RefObject<Toast>;

  constructor(
    dispatchLoading: Dispatch<SetStateAction<boolean>>,
    toast: RefObject<Toast>
  ) {
    this.dispatchLoading = dispatchLoading;
    this.toast = toast;
  }

  async getPesquisas(
    cpf: string,
    size: number | null
  ): Promise<Array<Pesquisa>> {
    try {
      this.dispatchLoading(true);
      const response = await UserService.getPesquisa(cpf, size);
      if (response.status === 200) {
        return response.data.data.pesquisas.content;
      }
    } catch (e) {
      console.error(e);
      this.toast.current?.show({ severity: "error", detail: "Erro" });
    } finally {
      this.dispatchLoading(false);
    }
    return [];
  }

  async postPesquisa(cpf: string, id: number): Promise<void> {
    try {
      this.dispatchLoading(true);
      const response = await UserService.postPesquisa({
        _id: id,
        cpf: cpf,
      });
      if (response.status === 200) {
        this.toast.current?.show({
          severity: "success",
          detail:
            "Pesquisa marcada como respondida e crédito liberado para o usuário.",
        });
      }
    } catch (e) {
      console.error(e);
      this.toast.current?.show({ severity: "error", detail: "Erro" });
    } finally {
      this.dispatchLoading(false);
    }
    return;
  }
}

export default UserPesquisaController;

import {Dispatch, RefObject, SetStateAction} from "react";
import {Toast} from "primereact/toast";
import NotaInfo from "../../interfaces/NotaInfo";
import NotasService from "../../services/NotaService";

class NotasController {
    private readonly dispatchLoading: Dispatch<SetStateAction<boolean>>;
    private readonly toast: RefObject<Toast>;

    constructor(
        dispatchLoading: Dispatch<SetStateAction<boolean>>,
        toast: RefObject<Toast>
    ) {
        this.dispatchLoading = dispatchLoading;
        this.toast = toast;
    }

    async get(
        year: number,
        month: number,
        cpf: string,
        filtroSaque: string,
        psql?: boolean
    ): Promise<Array<NotaInfo> | undefined> {
        try {
            this.dispatchLoading(true);
            const response = await NotasService.getNotas(
                year,
                month,
                cpf,
                filtroSaque,
                psql??false
            );
            if (response.status === 200) {
                return response.data.list;
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }
        return;
    }
}

export default NotasController;

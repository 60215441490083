import React, { Dispatch, ReactElement, SetStateAction } from "react";
import ChatAjudaInterface from "./ChatAjudaInterface";
import { Button } from "primereact/button";

interface PropsChatAjudaButton {
  set: Dispatch<SetStateAction<string>>;
  value: string;
  type: ChatAjudaInterface;
}

export function ChatAjudaButton(props: PropsChatAjudaButton): ReactElement {
  const sendText = (): void => {
    props.set(`${props.value} ${props.type.msg}`);
  };

  return (
    <>
      <Button label={props.type.id} className="mr-2" onClick={sendText} />
    </>
  );
}

import React, { 
  ChangeEvent, 
  ReactElement, 
  useEffect, 
  useRef, 
  useState 
} from "react";
import styles from "./SorteioPage.module.scss";
import { SorteioForm } from "./forms/SorteioForm";
import { useNavigate } from "react-router-dom";
import RoutesEnum from "../../properties/RoutesEnum";
import Sorteio from "../../interfaces/Sorteio";
import SorteioController from "./SorteioController";
import { Loading } from "../../components/loading/Loading";
import { addDays, addMonths, format, parse } from "date-fns";
import { ptBR } from 'date-fns/locale';
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export function SorteioPage(): ReactElement {

  const [formVisible, setFormVisible] = useState(false);
  const [lastSorteio, setLastSorteio] = useState<Sorteio | null>(null);
  const [sorteios, setSorteios] = useState<Sorteio[]>([]);
  const [sorteiosFiltered, setSorteiosFiltered] = useState<Sorteio[]>([]);
  const [sorteioForm, setSorteioForm] = useState<Sorteio | null>(null);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [totalSorteios, setTotalSorteios] = useState(0);
  const [first, setFirst] = useState(0);
  const [acaoForm, setAcaoForm] = useState('');
  
  const toast = useRef<Toast>(null);
  
  const navigate = useNavigate();
  const controller = new SorteioController(setIsResponseLoading, toast);
  const sort = "id";
  

  function changeFormVisibility(visible: boolean): void {
    setFormVisible(visible);
  }

  async function getSorteios(page: number, size:number): Promise<void> {
    const sorteiosResponse = await controller.getSorteios(page, size, sort);
    if (sorteiosResponse != null) {
      setSorteios(sorteiosResponse.content);
      setSorteiosFiltered(sorteiosResponse.content);
      setTotalSorteios(sorteiosResponse.totalElements);
      setSorteioForm(sorteios[0]);
      setLastSorteio({...sorteiosResponse.content[0]});
    }
  }
  
  useEffect(() => {
    getSorteios(page, size);
  }, []);

  function onEditSorteio(sorteio: Sorteio): void {
    setSorteioForm(sorteio);
    setAcaoForm('Editar');
    setFormVisible(true);
  }

  function deleteSorteio(idSorteio: number): void {
    controller.removeSorteio(idSorteio)
      .then(() => {
        getSorteios(page, size);
      });
  }

  function dialogConfirm(sorteio: Sorteio): void {
    confirmDialog({
      message: `Deseja realmente remover o sorteio "${sorteio.nome}"?`,
      header: "Confirme a remoção",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-rounded",
      accept: () => deleteSorteio(sorteio.id as number),
      acceptLabel: "Confirmar",
      rejectLabel: "Cancelar",
    });
  }

  function actionButtons(sorteio: Sorteio): ReactElement {
    return (
      <div className={styles.actionsTable}>
        <Button 
          label="Apurações" 
          className="p-button-rounded" 
          onClick={() => {
            navigate(`${RoutesEnum.Sorteio}/${sorteio.id}/apuracoes`)
          }} 
        />
        <Button 
          icon="pi pi-pencil" 
          className="p-button-rounded p-button-info"
          onClick={() => onEditSorteio(sorteio)}   
        />
        <Button 
          icon="pi pi-trash" 
          className="p-button-rounded p-button-danger" 
          onClick={() => dialogConfirm(sorteio)}
        />
      </div>
    )
  }

  function onPageChange(event: PaginatorPageChangeEvent): void {
    setPage(event.page);
    setSize(event.rows);
    setFirst(event.first);
    getSorteios(event.page, event.rows);
  }

  function filtrarSorteios(e: ChangeEvent<HTMLInputElement>): void {
    setSorteiosFiltered(sorteios.filter(
      sorteio => {
        const nome = sorteio.nome.toLowerCase();
        return nome.includes(e.target.value.toLowerCase());
      }
    ));
  }

  function prepareFormNovoSorteio(): Sorteio {
    const sorteio = {...lastSorteio as Sorteio}
    sorteio.id = null;
    
    const nameLastSorteio = lastSorteio?.nome.split(" - ") as string[];
    const nameLastSorteioParsed = 
      parse(nameLastSorteio[1], "MMMM 'de' yyyy", new Date(), {locale: ptBR});
    const novaData = addMonths(nameLastSorteioParsed, 1);
    const novaDataStr = format(novaData, "MMMM 'de' yyyy", { locale: ptBR });
    const capitalizedNovaDataStr = 
      novaDataStr.charAt(0).toUpperCase() + novaDataStr.slice(1);

    sorteio.nome = `${nameLastSorteio[0]} - ${capitalizedNovaDataStr}`;
    sorteio.dataInicio = format(
      novaData, 
      'yyyy-MM-dd HH:mm:ss', 
      { locale: ptBR }
    );
    sorteio.regulamento = '';
    sorteio.nrCertificadoSecap = '';

    const data1Desc = format(novaData, 'MMMM', { locale: ptBR });
    const data2Desc = format(
      addDays(addMonths(novaData, 1), 1),
      "dd 'de' MMMM",
      {locale: ptBR}
    )
    sorteio.descricaoPremios = 
    '<div><p>Toda nota/cupom fiscal válida de compras feitas no mês ' + 
    `de ${data1Desc} e enviada até dia ${data2Desc} gerará um bilhete ` +
    'para você participar do sorteio.</p><p>Neste sorteio, os prêmios são:' +
    '</p><ul><li><b>5</b> <a href="https://valepresente.com.br/">' +
    'Cartão virtual da Vale Presente</a> no valor de R$500,00.' +
    '</li></ul></div>'

    return sorteio;
  } 

  function onNovoSorteio(): void {
    const sorteio = prepareFormNovoSorteio();
    setSorteioForm(sorteio);
    setAcaoForm('Criar');
    setFormVisible(true);
  }

	return (
    <>
      <h1>Sorteio</h1>
      <ConfirmDialog />
      <Loading isLoading={isResponseLoading} />
      <Toast ref={toast} />
      <div className={styles.toolbar}>
        <div className={styles.leftToolbar}>
          <Button 
            className="p-button-rounded"
            icon="pi pi-angle-left"
            onClick={() => navigate(-1)}
          />
          <Button
            className="p-button-rounded"
            label="Novo"
            icon="pi pi-plus"
            onClick={onNovoSorteio}
          />
        </div>
        <Paginator
          first={first}
          rows={size}
          rowsPerPageOptions={[20, 50, 100]}
          totalRecords={totalSorteios}
          onPageChange={onPageChange}
        />
        <div className={styles.search}>
          <InputText
            id="filtro"
            className={styles.input}
            placeholder="Filtrar por nome na página"
            onChange={filtrarSorteios}
          />
        </div>
      </div>
      <SorteioForm
        visible={formVisible}
        setVisible={changeFormVisibility}
        sorteioForm={sorteioForm as Sorteio}
        setSorteioForm={setSorteioForm}
        controller={controller}
        getSorteios={getSorteios}
        page={page}
        size={size}
        acaoForm={acaoForm}
      />
      <main>
        <DataTable value={sorteiosFiltered} showGridlines>
          <Column field="nome" header="Nome" />
          <Column field="regulamento" header="Regulamento" />
          <Column field="estados" header="Estados" />
          <Column field="nrCertificadoSecap" header="Secap" />
          <Column body={actionButtons} header="Ações"/>
        </DataTable>
      </main>
    </>
	)
}
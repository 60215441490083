import api from "./Api";
import {AxiosResponse} from "axios";
import token from "./TokeService";
import Apuracao from "../interfaces/Apuracao";

function getAllApuracoesByIdSorteio(idSorteio: number): Promise<AxiosResponse> {
  return api.get(`/promocoes/${idSorteio}/apuracoes?token=${token()}`);
}

function postApuracaoBySorteioId(idSorteio: number, body: Apuracao): Promise<AxiosResponse> {
  return api.post(`/promocoes/${idSorteio}/apuracoes?token=${token()}`, body);
}

function putApuracaoBySorteioId(idSorteio: number, body: Apuracao): Promise<AxiosResponse> {
  return api.post(`/promocoes/${idSorteio}/apuracoes/${body.id}/update?token=${token()}`, body);
}

function deleteApuracao(idSorteio: number, idApuracao: number): Promise<AxiosResponse> {
  return api.post(`/promocoes/${idSorteio}/apuracoes/${idApuracao}/delete?token=${token()}`);
}

function downloadCsvNumerosApuracao(idApuracao: number): Promise<AxiosResponse> {
  return api.get(`/promocoes/apuracoes/${idApuracao}/download-numeros?token=${token()}`);
}

function getApuracaoById(idApuracao: number): Promise<AxiosResponse> {
  return api.get(`/apuracoes/${idApuracao}?token=${token()}`);
}

function processarApuracao(idApuracao: number, body: Apuracao): Promise<AxiosResponse> {
  return api.post(`/apuracoes/${idApuracao}/processar?token=${token()}`, body);
}

function getGanhadoresApuracao(idApuracao: number): Promise<AxiosResponse> {
  return api.get(`/apuracoes/${idApuracao}/ganhadores?token=${token()}`);
}

const ApuracaoService = {
  getAllApuracoesByIdSorteio,
  postApuracaoBySorteioId,
  putApuracaoBySorteioId,
  deleteApuracao,
  downloadCsvNumerosApuracao,
  getApuracaoById,
  processarApuracao,
  getGanhadoresApuracao
};

export default ApuracaoService;

import ReportStatus from "../../../interfaces/ReportStatus";
import React, {
    Dispatch,
    ReactElement,
    SetStateAction,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from "react";
import {Loading} from "../../../components/loading/Loading";
import {Toast} from "primereact/toast";
import styles from "./ReportBox.module.scss";
import {Carousel} from 'primereact/carousel';
import {Chip} from "primereact/chip";
import DateUtils from "../../../utils/DateUtils";
import {Card} from 'primereact/card';
import "./Prime.css";
import {Button} from "primereact/button";
import {ReportForm} from "./report_form/ReportForm";
import ReportCard from "../report_card/ReportCard";
import ReportResume from "../../../interfaces/ReportResume";
import ReportUser from "../../../interfaces/ReportUser";
import ReportController from "../ReportController";
import {Tag} from "primereact/tag";
import ReportUtils from "../ReportUtils";


interface ReportBoxProps {
    user?: ReportStatus | null;
    set: Dispatch<SetStateAction<string>>;
    setTab: Dispatch<SetStateAction<number>>;
    value: string;
}

const ReportBox = React.forwardRef(
    (props: ReportBoxProps, ref) => {
        const toast = useRef<Toast>(null);
        const [isResponseLoading, setIsResponseLoading] = useState(false);
        const [form, setForm] = useState<string>("");
        const [source, setSource] = useState<Array<ReportResume>>([])
        const [selected, setSelected] = useState<ReportResume | undefined>()
        const [report, setReport] = useState<ReportUser | undefined>(undefined)

        const controller = React.useCallback(
            () => new ReportController(setIsResponseLoading, toast),
            [setIsResponseLoading, toast]
        );

        async function getResumeList(): Promise<void> {
            const cpf = props.user?.cpf
            if (!cpf) return

            const data = await controller().getReportResumeByCPF(cpf)

            if (!data) return

            setSource(data)
        }

        async function getReportInfo(): Promise<void> {
            setReport(undefined)

            if (!selected) return

            const data = await controller().getReportById(selected.id)

            if (data) {
                setReport(data)
            }
        }

        useImperativeHandle(ref, () => ({
            clean() {
                setReport(undefined);
                setSelected(undefined);
            },
        }));

        function update(): void {
            getResumeList()
            getReportInfo()
        }

        useEffect(() => {
            getResumeList()
        }, [props.user]);

        useEffect(() => {
            getReportInfo()
        }, [selected, setSelected]);


        const itemTemplate = (report: ReportResume): ReactElement => {
            return (
                <Card className={styles.card}>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <strong>{report.title}</strong>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <Tag
                            severity={ReportUtils.getStatusTag(report.status)}>{ReportUtils.getStatus(report.status)}</Tag>
                    </div>
                    <div className={styles.row}>
                        <Chip
                            className={styles.reportChip}
                            label={DateUtils.formatTime(report.timestamp)}
                        />
                    </div>
                    <div className={styles.row}>
                        <Button
                            className={`p-button p-button-rounded ${styles.expandBtn}`}
                            onClick={() => setSelected(report)}
                        >Abrir</Button>
                    </div>
                </Card>
            );
        };

        return (
            <>
                <Loading isLoading={isResponseLoading}/>
                <Toast ref={toast}/>
                <div className={styles.ReportBox}>
                    <div className={styles.row}>
                        {source.length == 0 ?
                            <></> :
                            <Carousel
                                value={source}
                                numVisible={5} numScroll={5}
                                itemTemplate={itemTemplate}
                                className={styles.carousel}
                            />
                        }
                    </div>
                    <div>
                        <ReportCard value={report}/>
                    </div>
                    <ReportForm
                        set={setForm}
                        value={form}
                        report={selected}
                        callback={update}
                        user={props.user}
                    />
                </div>
            </>
        );
    }
);

ReportBox.displayName = 'ReportBox';

export default ReportBox;

import styles from "./ChatPage.module.scss";
import React, {ReactElement, useEffect, useState} from "react";
import {Allotment} from "allotment";
import "allotment/dist/style.css";
import {ChatList} from "./chat_list/ChatList";
import {ChatBox} from "./chat_box/ChatBox";
import {UserPage} from "../user/UserPage";
import {TabPanel, TabView} from "primereact/tabview";
import {ScrollPanel} from "primereact/scrollpanel";
import ChatStatus from "../../interfaces/ChatStatus";
import {ChatAjuda} from "./chat_ajuda/ChatAjuda";
import {useParams} from "react-router-dom";
import RouteUtils from "../../utils/RouteUtils";
import RoutesEnum from "../../properties/RoutesEnum";
import {Card} from "primereact/card";
import ChatAjudaContent from "./chat_ajuda/ChatAjudaContent";
import {ChatStatusTab} from "./chat_status/ChatStatusTab";

interface ChatPageProps {
    cpf?: string | undefined;
}

export function ChatPage(props: ChatPageProps): ReactElement {
    const [user, setUser] = useState<ChatStatus | null>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [msg, setMsg] = useState("");
    const {cpf} = useParams();

    useEffect(() => {
        if (props.cpf) {
            setUser({
                last_message: {timestamp: 0},
                name: "",
                unread: 0,
                cpf: props.cpf,
            });
            RouteUtils.changeRoute(RoutesEnum.Chat, props.cpf);
            return;
        }
        if (cpf) {
            setUser({
                last_message: {timestamp: 0},
                name: "",
                unread: 0,
                cpf: cpf,
            });
            RouteUtils.changeRoute(RoutesEnum.Chat, cpf);
            return;
        }
    }, []);

    return (
        <div className={styles.containerBody}>
            <div className={styles.ChatPage}>
                <Allotment className={styles.splitter}>
                    <Allotment.Pane
                        minSize={150}
                        className={styles.splitterPanel}
                        preferredSize={250}
                        snap
                    >
                        <ChatList chat={user} setChat={setUser}/>
                    </Allotment.Pane>
                    <Allotment.Pane minSize={500} className={styles.splitterPanel}>
                        <ChatBox
                            user={user}
                            set={setMsg}
                            value={msg}
                            setTab={setActiveIndex}
                        />
                    </Allotment.Pane>
                    <Allotment.Pane
                        minSize={200}
                        className={styles.chatInfo}
                        snap
                        preferredSize={400}
                    >
                        <TabView
                            activeIndex={activeIndex}
                            onTabChange={(e) => setActiveIndex(e.index)}
                            className={styles.chatInfoScroll}
                        >
                            <TabPanel header="INFO">
                                <ScrollPanel>
                                    <UserPage
                                        user={user}
                                        enable={false}
                                        className={styles.chatUserPage}
                                    />
                                </ScrollPanel>
                            </TabPanel>
                            <TabPanel header="AJUDA">
                                <ScrollPanel>
                                    <div className={styles.scroll}>
                                        <Card className={styles.cardAjuda}>
                                            <h4>TAGS:</h4>
                                            <ul>
                                                <li>|| dividir as mensagens</li>
                                                <li>{"<< nome_usuario >>"}</li>
                                                <li>{"<< estabelecimento >>"}</li>
                                            </ul>
                                        </Card>
                                        {ChatAjudaContent.list.map((value, index) => (
                                            <ChatAjuda
                                                set={setMsg}
                                                value={msg}
                                                type={value}
                                                key={index}
                                            />
                                        ))}
                                    </div>
                                </ScrollPanel>
                            </TabPanel>
                            <TabPanel header="STATUS">
                                <ScrollPanel>
                                    <ChatStatusTab/>
                                </ScrollPanel>
                            </TabPanel>
                        </TabView>
                    </Allotment.Pane>
                </Allotment>
            </div>
        </div>
    );
}

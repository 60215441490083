import React, {ReactElement, useRef, useState} from "react";
import styles from "./UserActions.module.scss";
import {Button} from "primereact/button";
import {Toolbar} from "primereact/toolbar";
import {Toast} from "primereact/toast";
import UserController from "../UserController";
import {Loading} from "../../../components/loading/Loading";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";

interface UserActionsProps {
    cpf?: string | null;
    id?: string | null;
    email?: string | null;
}

enum Actions {
    GenTempPassword,
    SendTempPassword,
    CadastrarCompartilhamento,
    AlterarEmailUsuario,
}

export function UserActions(props: UserActionsProps): ReactElement {
    const toast = useRef<Toast>(null);
    const [isResponseLoading, setIsResponseLoading] = useState(false);
    const controller = new UserController(setIsResponseLoading, toast);

    const [cpfConfirmation, setCpfConfirmation] = useState("");
    const [displayConfirmation, setDisplayConfirmation] = useState(false);

    const [displayPassword, setDisplayPassword] = useState(false);
    const [temPassword, setTemPassword] = useState("");

    const [action, setAction] = useState<Actions>();

    const [cpfConvidado, setCpfConvidado] = useState("");
    const [displayCpfConvidado, setDisplayCpfConvidado] = useState(false);

    const [emailNew, setEmailNew] = useState("");
    const [emailAtual, setEmailAtual] = useState("");
    const [displayEmailDialog, setDisplayEmailDialog] = useState(false);

    async function genTempPassword(isOk: boolean): Promise<void> {
        if (!isOk) {
            setCpfConfirmation("");
            setAction(Actions.GenTempPassword);
            setDisplayConfirmation(true);
            return;
        }

        if (props.cpf) {
            setTemPassword((await controller.genTempPassword(props.cpf)) ?? "");
            setDisplayPassword(true);
        }
    }

    async function sendTempPassword(isOk: boolean): Promise<void> {
        if (!isOk) {
            setCpfConfirmation("");
            setAction(Actions.SendTempPassword);
            setDisplayConfirmation(true);
            return;
        }

        if (props.cpf) {
            await controller.sendTempPassword(props.cpf);
        }
    }

    async function refreshMissaoUser(): Promise<void> {
        if (props.cpf) {
            await controller.refreshMissaoUser(props.cpf);
        }

        return;
    }

    async function cadastrarCompartilhamento(isOk: boolean): Promise<void> {
        if (!isOk) {
            setCpfConvidado("");
            setAction(Actions.CadastrarCompartilhamento);
            setDisplayCpfConvidado(true);
            return;
        }

        if (props.id) {
            await controller.cadastrarCompartilhamento(cpfConvidado, props.id);
        }
    }

    async function alterarEmailUsuario(isOk: boolean): Promise<void> {
        if (!isOk) {
            setEmailNew("");
            setEmailAtual("");
            setAction(Actions.AlterarEmailUsuario);
            setDisplayEmailDialog(true);
            return;
        }

        if (props.id && props.email) {
            await controller.alterarEmailUsuario(
                props.id,
                emailAtual,
                emailNew,
                props.email
            );
        }
    }

    const renderFooter = (): ReactElement => {
        return (
            <div>
                <Button
                    label="Confirmar"
                    icon="pi pi-check"
                    className={styles.expanded}
                    onClick={() => {
                        switch (action) {
                            case Actions.GenTempPassword: {
                                setDisplayConfirmation(false);
                                genTempPassword(cpfConfirmation.includes(props.cpf ?? ""));
                                break;
                            }
                            case Actions.SendTempPassword: {
                                setDisplayConfirmation(false);
                                sendTempPassword(cpfConfirmation.includes(props.cpf ?? ""));
                                break;
                            }
                            case Actions.CadastrarCompartilhamento: {
                                setDisplayCpfConvidado(false);
                                cadastrarCompartilhamento(true);
                                break;
                            }
                            case Actions.AlterarEmailUsuario: {
                                setDisplayEmailDialog(false);
                                alterarEmailUsuario(true);
                                break;
                            }
                            default:
                                break;
                        }
                    }}
                    autoFocus
                />
            </div>
        );
    };

    const renderCloseDialog = (): ReactElement => {
        return (
            <div>
                <Button
                    label="Confirmar"
                    icon="pi pi-check"
                    onClick={() => {
                        setDisplayPassword(false);
                    }}
                    autoFocus
                    className={styles.expanded}
                />
            </div>
        );
    };

    function copy(value: string): void {
        navigator.clipboard.writeText(value.toString() ?? "").then(() => {
            return toast.current?.show({
                severity: "success",
                summary: "Copiado",
                detail: value,
            });
        });
    }

    const leftContents = (
        <>
            <Button
                label="Email com senha temporária"
                icon="pi pi-envelope"
                onClick={() => sendTempPassword(false)}
            />
            <div className="p-toolbar-separator mr-2"/>
            <Button
                label="Gerar senha temporária"
                icon="pi pi-eye"
                onClick={() => genTempPassword(false)}
            />
            <div className="p-toolbar-separator mr-2"/>
            <Button
                label="Cadastrar compartilhamento"
                icon="pi pi-dollar"
                onClick={() => cadastrarCompartilhamento(false)}
            />
            <div className="p-toolbar-separator mr-2"/>
            <Button
                label="Alterar email usuário"
                icon="pi pi-user-edit"
                onClick={() => alterarEmailUsuario(false)}
            />
            <div className="p-toolbar-separator mr-2"/>
            <Button
                label="Atualizar Missões"
                icon="pi pi-spinner"
                onClick={() => refreshMissaoUser()}
            />
        </>
    );

    return (
        <>
            <Dialog
                header="Senha Gerada"
                visible={displayPassword}
                footer={renderCloseDialog()}
                onHide={() => setDisplayPassword(false)}
                dismissableMask
            >
                <div className={styles.columnDialog}>
                    <div className={styles.row}>
                        <span>Envie a seguinte senha para o usuário:</span>
                        <Button
                            onClick={() => copy(temPassword)}
                            className="p-button-secondary p-button-text p-button-lg"
                            style={{color: "black", padding: 0}}
                        >
                            {temPassword}
                            <i className="pi pi-copy"/>
                        </Button>
                    </div>
                    <span>Válida por 48 horas a partir deste momento.</span>
                </div>
            </Dialog>

            <Dialog
                header="Alterar Email"
                visible={displayEmailDialog}
                footer={renderFooter()}
                onHide={() => setDisplayEmailDialog(false)}
                style={{width: "500px"}}
            >
                <div className={styles.columnDialog}>
                    <div className={styles.row}>
                        <span className={styles.label}>Email atual:</span>
                        <InputText
                            value={emailAtual}
                            onChange={(e) => setEmailAtual(e.target.value)}
                            className={styles.expanded}
                        />
                    </div>
                    <div className={styles.row}>
                        <span className={styles.label}>Email novo:</span>
                        <InputText
                            value={emailNew}
                            onChange={(e) => setEmailNew(e.target.value)}
                            className={styles.expanded}
                        />
                    </div>
                </div>
            </Dialog>

            <Dialog
                header="Confirmar CPF"
                visible={displayConfirmation}
                footer={renderFooter()}
                onHide={() => setDisplayConfirmation(false)}
                dismissableMask
            >
                <InputText
                    value={cpfConfirmation}
                    onChange={(e) => setCpfConfirmation(e.target.value)}
                    className={styles.expanded}
                />
            </Dialog>

            <Dialog
                header="Digite o cpf do usuario que foi convidado"
                visible={displayCpfConvidado}
                footer={renderFooter()}
                onHide={() => setDisplayCpfConvidado(false)}
                dismissableMask
            >
                <InputText
                    value={cpfConvidado}
                    onChange={(e) => setCpfConvidado(e.target.value)}
                    className={styles.expanded}
                />
            </Dialog>

            <Dialog
                header="Digite o cpf do usuario que foi convidado"
                visible={displayCpfConvidado}
                footer={renderFooter()}
                onHide={() => setDisplayCpfConvidado(false)}
                dismissableMask
            >
                <InputText
                    value={cpfConvidado}
                    onChange={(e) => setCpfConvidado(e.target.value)}
                    className={styles.expanded}
                />
            </Dialog>

            <Loading isLoading={isResponseLoading}/>
            <Toast ref={toast}/>
            <div className={styles.UserActions}>
                <Toolbar className={styles.toolbar} left={leftContents}/>
            </div>
        </>
    );
}

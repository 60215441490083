import { format } from "date-fns";
import { ptBR } from 'date-fns/locale';
import Apuracao from "../../../interfaces/Apuracao";
import Sorteio from "../../../interfaces/Sorteio";
import { EstadoBrasileiro, EstadosUtils } from "../../../utils/EstadosUtils";
import PremioSorteio from "../../../interfaces/PremioSorteio";
import { ToWords } from 'to-words';
import Captura from "../../../interfaces/Captura";

const toWords = new ToWords({
  localeCode: 'pt-BR'
});

export interface ItemInfoSCPC {
  item: string;
  descricao: string;
  status: string;
  comentarios: string;
}

export class InfoSCPC {

  private itens!: ItemInfoSCPC[];
  private sorteio: Sorteio;
  private apuracao: Apuracao;
  private premios: PremioSorteio[];
  private capturas: Captura[];
  private estadosUtils = new EstadosUtils();

  constructor() {
    this.sorteio = {
      id: null,
      ativa: false,
      dataInicio: '',
      descricaoPremios: '',
      estados: '',
      nome: '',
      nrCertificadoSecap: '',
      quantidadeElementosSorteaveis: 0,
      quantidadeSeries: 0,
      regulamento: '',
      tipo: '',
      createdAt: ''
    };
    this.apuracao = {
      id: null,
      data_apuracao: '',
      dataExtracaoLoteriaFederal: '',
      dataFinalParticipacao: '',
      dataInicialParticipacao: '',
      dataInicialEnvioNotas: '',
      dataFinalEnvioNotas: '',
      numeroDaSorteSorteado: '',
      numeroExtracaoLoteriaFederal: '',
      numerosSorteadosPelaExtracaoLoteriaFederal: '',
    };
    this.premios = [{
      id: null,
      cartaoCsc: '',
      cartaoNome: '',
      cartaoNumero: '',
      cartaoValidade: '',
      codigo: '',
      descricao: '',
      numeroDaSorteContemplado: '',
      ordem: 0,
      resgatado: false,
      tipo: '',
      tutorial: '',
      userInfo: '',
      validade: '',
      valor: '',
    }];
    this.capturas = [];
    this.atualizaItens();
  }

  setSorteio(sorteio: Sorteio): void {
    this.sorteio = sorteio;
  }
  
  setApuracao(apuracao: Apuracao): void {
    this.apuracao = apuracao;
  }

  setPremios(premios: PremioSorteio[]): void {
    this.premios = premios;
  }

  setCapturas(capturas: Captura[]): void {
    this.capturas = capturas;
  }

  atualizaItens(): void {
    this.itens = [
      {
        item: "Dados gerais - CNPJ", 
        descricao: "26.350.495/0001-72", 
        status: "OK", 
        comentarios: "Nenhum"
      },
      {
        item: "Dados gerais - Nome da Promoção", 
        descricao: this.sorteio.nome, 
        status: "OK", 
        comentarios: "Nenhum"
      },
      {
        item: "Dados gerais - Modalidade", 
        descricao: "Assemelhado a sorteio", 
        status: "OK", 
        comentarios: "Nenhum"
      },
      {
        item: "Dados gerais - Filantrópico", 
        descricao: "Não", 
        status: "OK", 
        comentarios: "Nenhum"
      },
      {
        item: "Dados gerais - Data de início", 
        descricao: this.sorteio.dataInicio != '' ? format(this.sorteio.dataInicio, 'dd/MM/yyyy') : '', 
        status: "OK", 
        comentarios: `Início deve ser pelo menos 10 dias da submissão da promoção.

          Formato deve ser:
          dd/mm/aaaa 00:00 a dd/mm/aaaa 23:59`
      },
      {
        item: "Regras gerais - Mês de participação do sorteio", 
        descricao: this.apuracao.dataInicialParticipacao != '' ? format(this.apuracao.dataInicialParticipacao, "MMMM 'de' yyyy", {locale: ptBR}) : '', 
        status: "OK", 
        comentarios: "Formato: mmm de aaaa (Exemplo: novembro de 2021)"
      },
      {
        item: "Regras gerais - Dia final da promoção", 
        descricao: this.apuracao.dataFinalParticipacao != '' ? format(this.apuracao.dataFinalParticipacao, "dd 'de' MMMM 'de' yyyy", {locale: ptBR}) : '',
        status: "OK", 
        comentarios: "Formato: dd de mmm de aaaa (Exemplo: 02 de dezembro de 2021)"
      },
      {
        item: "Regras gerais - Qtde de prêmios", 
        descricao: `${toWords.convert(this.premios.length).toLocaleLowerCase()}`, 
        status: "OK", 
        comentarios: ""
      },
      {
        item: "Regras gerais - Descrição dos prêmios", 
        descricao: `${this.premios[0] ? this.premios[0].descricao : ''}`, 
        status: "OK", 
        comentarios: "Nenhum"
      },
      {
        item: `Regras gerais - Sorteio da Loteria Federal 
          (Acertar na plataforma e no texto)`, 
        descricao: this.apuracao.dataExtracaoLoteriaFederal != '' ? format(this.apuracao.dataExtracaoLoteriaFederal, "dd/MMM/yyyy", {locale: ptBR}) : '', 
        status: "OK", 
        comentarios: `Escolher sempre a 2ª quarta-feira do mês

          Formato: dd/mmm/aaaa`
      },
      {
        item: "Regras gerais - Critérios de participação", 
        descricao: `Poderão participar da PROMOÇÃO todas as pessoas cadastradas no APLICATIVO Dinheiro na Nota, disponível na plataforma Android no link https://play.google.com/store/apps/details?id=br.com.sofist.dinheironanota ou iOS no link https://apps.apple.com/br/app/dinheiro-na-nota-cashback/id1612678600 E que residam nos estados do ${this.formatNomesEstadosSorteio()}.

          Para participar, as pessoas deverão enviar os dados de suas notas fiscais de compras pelo aplicativo.

          Serão consideradas notas válidas aquelas que atenderem aos critérios de aceite definidos no aplicativo e explicitados abaixo.

          As pessoas deverão confirmar que desejam participar da promoção, através de aceite no aplicativo. O aceite poderá ser feito a qualquer momento durante a validade da PROMOÇÃO. 

          A qualquer momento a pessoa poderá desistir de participar da PROMOÇÃO. Nesse caso, ela se tornará inelegível à premiação.

          Para essa PROMOÇÃO, serão elegíveis apenas as notas de compras realizadas no mês de ${this.getDataInicialEnvioNotas()} enviadas pelo aplicativo até o dia ${this.getDataFinalEnvioNotas()}.

          A cada nota enviada pelo usuário do APLICATIVO e validada pela equipe do APLICATIVO, será gerado um NÚMERO DA SORTE para o usuário. Esses números estarão disponíveis para consulta dentro do aplicativo.

          Em todos os casos que não seja possível à equipe do APLICATIVO validar a nota, a EMPRESA PROMOTORA reserva-se o direito de não incluí-la na promoção. Casos em que pode não ser possível validar uma nota enviada por um PARTICIPANTE incluem, mas não se limitam, aos seguintes cenários:

          - Caso a nota ou cupom fiscal esteja indisponível para consulta na data da validação pela equipe do APLICATIVO, no sistema de nota dos Estados do ${this.formatSiglasEstadosSorteio()} disponíveis, respectivamente, nos links:
          ${this.formatCapturasEstadosSorteio()}

          - Caso a nota ou cupom fiscal, quando acessada pelo sistema de consultas de notas dos estados, não apresente o CPF do PARTICIPANTE.

          - Caso existam indícios (conforme julgado pela equipe do APLICATIVO) de que a nota não reflete uma compra real da pessoa.


          CRITÉRIOS DE VALIDAÇÃO DAS NOTAS

          - A nota ou foto enviada deve ser de um cupom fiscal ou nota fiscal

          - A nota enviada deve ter o CPF do Usuário cadastrado no aplicativo

          - A nota deve ter valor igual ou superior a R$ 2,00

          - A nota deve ser de uma compra feita há, no máximo, 3 dias da data de envio

          - As notas que possuem QR Code, Código de Barras ou Chave de Acesso (sequência de 44 dígitos) não podem ser enviadas por foto.


          # Invalidação e desclassificação por comportamento anormal

          - Nos reservamos o direito de invalidar (a qualquer momento, mesmo após a validação automática das notas) notas de usuários que tenham um comportamento anormal. O persistência desse tipo de comportamento poderá, a critério da equipe da promoção, desclassificar o usuário.

          - Entendemos que comportamento anormal seja o envio de notas que não refletem o efetivo perfil de consumo da pessoa.

          - Por exemplo: Uma pessoa que envia várias notas de um estabelecimento e apenas ou majoritariamente daquele estabelecimento, mesmo que dentro do limite de 3 notas por dia, poderá ter suas notas desse estabelecimento invalidadas e caso o comportamento persista, poderá ser desclassificada.

                    
          # Tipos de notas que são aceitas

          - São aceitas notas referentes à compra de bens de consumo. Por exemplo, compras em supermercados, farmácias, lojas online, bares, padarias, etc.

          - Não são aceitos boletos e notas de serviço como: luz, água, telefone, estacionamento, pedágio, passagens de ônibus/avião, etc.

          - Não serão aceitas notas de estabelecimentos comerciais com o qual a participante tem qualquer vínculo (funcionário, por exemplo). A MANDATÁRIA pode solicitar informações complementares do PARTICIPANTE para confirmar esses vínculos. A recusa ou incapacidade em fornecer esses dados implica na invalidação das notas ou desclassificação do Usuário.

          # Limites de Envio
          - O envio das notas é limitado dependendo do tipo de submissão dentro do APLICATIVO:
          -- Envio por foto: 3 notas por dia;
          -- Demais formas de envio: 3 notas por estabelecimento por dia.

          - Também é limitado o envio de 100 notas por mês.
                      

          DESCRIÇÃO DE PRÊMIOS

          Na presente PROMOÇÃO, serão contemplados ${toWords.convert(this.premios.length).toLocaleLowerCase()} usuários, cada um recebendo ${this.premios[0] ? this.premios[0].descricao : ''}.

          Os prêmios serão disponibilizados através de APLICATIVO. Ainda, para utilizar o prêmio, a pessoa sorteada deverá instalar e se cadastrar no Aplicativo Vale Presente, disponível para Android (https://play.google.com/store/apps/details?id=br.com.sofist.dinheironanota) e iOS (https://apps.apple.com/br/app/cart%C3%B5es-vale-presente/id1436868047) para desbloquear o cartão.`, 
        status: "OK", 
        comentarios:"Fórmula"
      },
      {
        item: "Regras gerais - Critérios de desclassificação", 
        descricao: `"Não poderão participar da promoção os funcionários e colaboradores da empresa PROMOTORA.

          A PROMOTORA reserva-se o direito de desclassificar e excluir os participantes que apresentarem conduta no sentido de manipular dolosamente a operação da PROMOÇÃO, bem como os participantes que tentarem fraudar ou burlar as regras estabelecidas no presente REGULAMENTO, ou da legislação em vigor, podendo, ainda, responder civil e/ou criminalmente por tal conduta.

          Entende-se, a título de exemplo, por irregularidade ou fraude: 
          a) cadastro que apresente dados falsos ou incompletos sobre o participante;
          b) inscrição no CPF inválida ou que não seja do participante;
          c) e-mail inválido;
          d) cadastro por mais de uma vez de uma mesma nota/cupom fiscal;
          e) participação por meio de sistemas automáticos ('robôs') ou que permitam uma vantagem indevida em relação aos demais participantes;
          f) prestação de informação falsa;
          g) envio de cupons que não sejam do consumo próprio efetivamente realizado, conforme analisado pela equipe do APLICATIVO.

          A ocorrência de quaisquer das situações de desclassificação poderá ser verificada pela PROMOTORA no decorrer do período de validade da PROMOÇÃO e até a data de prescrição dos prêmios, sendo certo que o participante desclassificado não poderá participar novamente desta mesma PROMOÇÃO. Para tal verificação, poderá a PROMOTORA requerer informações complementares do participante. A recusa na prestação de informações poderá, da mesma forma, ensejar a desclassificação desta PROMOÇÃO.

          Em caso de desclassificação, o Participante será excluído automaticamente desta PROMOÇÃO e seus NÚMEROS DA SORTE serão considerados como 'não distribuídos' para fim de participação no sorteio e/ou para fim de recebimento do prêmio.
          "`, 
        status: "OK", 
        comentarios:"Fórmula"
      },
      {
        item: "Regras gerais - Forma de apuração", 
        descricao: `REGRA DE FORMAÇÃO DOS ELEMENTOS SORTEÁVEIS

          SÉRIE: Serão emitidas ${this.sorteio.quantidadeSeries} séries numeradas de 0 a ${this.sorteio.quantidadeSeries - 1}, cada uma delas composta por ${this.sorteio.quantidadeElementosSorteaveis} números.

          ELEMENTO SORTEÁVEL: Serão gerados ${this.sorteio.quantidadeElementosSorteaveis} Elementos sorteáveis de 0 a ${this.sorteio.quantidadeElementosSorteaveis - 1} por série;

          NÚMERO DA SORTE: Será formado por 7 números, sendo que os 2 primeiros números correspondem à série e os 5 últimos números ao ELEMENTO SORTEÁVEL, conforme exemplo abaixo:
          Exemplo: 12/12345 = 12(série); 12345(elemento sorteável).


          DATA DO SORTEIO DA LOTERIA FEDERAL
          Nesta promoção, será utilizada a Extração da Loteria Federal do dia ${this.apuracao.dataExtracaoLoteriaFederal != '' ? format(this.apuracao.dataExtracaoLoteriaFederal, "dd/MMM/yyyy", {locale: ptBR}) : ''}.

          Caso a Extração da Loteria Federal não venha a ocorrer na data prevista, por qualquer motivo, será considerada para efeitos de apuração do resultado desta Promoção, a data da Extração da Loteria Federal subsequente.


          REGRA DE APURAÇÃO DO NÚMERO DA SORTE SORTEADO

          Para a identificação do NÚMERO DA SORTE SORTEADO, serão considerados os 5 (cinco) primeiros prêmios da extração da Loteria Federal, aplicando-se a seguinte regra:

          Para determinação do ELEMENTO SORTEÁVEL serão utilizados os algarismos das unidades simples dos 5 (cinco) primeiros prêmios de cada extração da Loteria Federal, acima indicadas, lidos de cima para baixo.

          Para determinação da SÉRIE, serão utilizados os algarismos das dezenas simples dos 2 (dois) primeiros prêmios da extração da Loteria Federal.

          Por exemplo, se a Extração da Loteria Federal do Brasil fosse:
          1o premio: 5 6 3 3 3
          2o premio: 7 3 9 7 2
          3o premio: 9 9 2 0 7
          4o premio: 0 0 8 9 4
          5o premio: 7 3 2 9 5

          Neste caso o ELEMENTO SORTEÁVEL seria 32745 e a SÉRIE seria 37. Com isso, teremos o NÚMERO DA SORTE SORTEADO como 37/32745.


          REGRA DE APURAÇÃO DOS CONTEMPLADOS

          Para determinação do primeiro participante contemplado, este deve possuir o NÚMERO DA SORTE que coincide exatamente com o NÚMERO DA SORTE SORTEADO e atender aos critérios de participação. 

          No caso de não ter sido distribuído o NÚMERO DA SORTE apurado ou não atenda aos critérios de participação, a determinação do primeiro participante contemplado será o NÚMERO DA SORTE imediatamente superior, ou, na falta deste, o imediatamente inferior, repetindo-se tal procedimento até que se encontre um NÚMERO DA SORTE distribuído mais próximo ao apurado. No caso de se alcançar o NÚMERO DA SORTE inicial ou final, buscar-se-á apenas os imediatamente superiores e inferiores, respectivamente.

          Os demais contemplados serão determinados pelos NÚMEROS DA SORTE mais próximos, seguindo a mesma regra descrita no parágrafo anterior.

          Caso não tenham sido distribuídos NÚMEROS DA SORTE suficientes para distribuir todos os prêmios, o valor correspondente aos prêmios não distribuídos serão recolhidos, pela empresa Promotora, ao Tesouro Nacional, como renda da União, na forma da Lei.


          DISTRIBUIÇÃO DOS ELEMENTOS SORTEÁVEIS E SÉRIES

          A geração e distribuição dos elementos sorteáveis e das séries devem ser feitas de forma aleatória.`, 
        status: "OK", 
        comentarios:"Fórmula"
      },
      {
        item: "Regras gerais - Forma de divulgação do resultados", 
        descricao: `Os ganhadores serão avisados por meio de e-mail e/ou mensagem no APLICATIVO, de acordo com seus dados cadastrais.

          Os números da sorte e o nome dos ganhadores serão divulgados dentro do APLICATIVO para consulta pelo prazo de 90 (noventa) dias a contar da data da apuração.`, 
        status: "OK", 
        comentarios:"Fórmula"
      },
      {
        item: "Regras gerais - Entrega dos prêmios", 
        descricao: `Os prêmios serão entregues ao participante em até 30 (trinta) dias contados da data de contemplação na conta do APLICATIVO.

          O prêmio é pessoal e intransferível, não se responsabilizando a PROMOTORA por eventuais restrições que o ganhador possa ter para usufruí-lo.

          O prêmio será entregue livre e desembaraçado de quaisquer ônus para os ganhadores, não sendo permitido ao contemplado trocar seu prêmio por qualquer outro`, 
        status: "OK", 
        comentarios:"Fórmula"
      },
      {
        item: "Regras gerais - Disposições gerais", 
        descricao: `Ao participar da promoção, sem nenhum custo adicional, o contemplado autoriza a empresa promotora a utilizar sua imagem, nome e/ou voz, sempre vinculado ao plano de ação autorizado, por prazo não superior a 12 (doze) meses após a apuração.

          Os participantes serão automaticamente excluídos desta promoção, no caso de má fé ou fraude comprovada, respondendo civil e criminalmente por seus atos.

          Todos os participantes concordam com os TERMOS DE USO e POLÍTICA DE PRIVACIDADE do APLICATIVO, dado que o aceite deles é necessário para uso do APLICATIVO e participação na PROMOÇÃO.

          A participação na presente PROMOÇÃO implica na aceitação automática de todas as disposições do presente REGULAMENTO, em relação às quais os participantes não poderão alegar desconhecimento.

          Este Regulamento será disponibilizado no APLICATIVO. Dúvidas e informações sobre esta Promoção poderão ser esclarecidas por meio do suporte disponível via email e chat no aplicativo.

          A PROMOTORA não se responsabiliza por nenhuma falha técnica, problemas de acesso à Internet ou qualquer caso fortuito ou de força maior que possam impedir a participação do usuário.

          Se ocorrerem incidentes que fogem totalmente do controle da equipe da EMPRESA PROMOTORA, como, por exemplo, casos fortuitos ou força maior, as datas de realização da PROMOÇÃO poderão ser alteradas. Nesses casos, as novas datas serão amplamente divulgadas.

          As dúvidas e controvérsias oriundas de reclamações dos participantes serão, primeiramente, dirimidas pela promotora, persistindo-as, estas deverão ser submetidas à Secretaria de Prêmios e Apostas SPA/MF.

          Os órgãos locais de defesa do consumidor receberão as reclamações devidamente fundamentadas.

          A empresa deverá encaminhar à SRE a Lista de Participantes, anexando na aba 'Apurações' do sistema SCPC, os nomes e números da sorte distribuídos, após o término de do período de participação e antes da extração da Loteria.`, 
        status: "OK", 
        comentarios: "Fórmula"
      },
      {
        item: "Regras gerais - Informações restritas", 
        descricao: "", 
        status: "OK", 
        comentarios: "Deixar vazio (Fórmula)"
      },
      {
        item: "Solicitantes - Valor médio da DRO", 
        descricao: "", 
        status: "OK", 
        comentarios: `https://nfse.campinas.sp.gov.br/NotaFiscal/index.php

          Calcular a partir do site da prefeitura, opção "Minha empresa" > "Consultar notas".

          Salvar valores mês a mês na fórmula, para reutilizar no documento DRO.`
      },
      {
        item: "Solicitantes - Procurador", 
        descricao: "049.090.719-97", 
        status: "OK", 
        comentarios: `Deve ser o CPF de quem está cadastrando e que tem a procuração.

          Júlio - 32507905832
          Zanoni - 04909071997`
      },
      {
        item: "Solicitantes - Autorizados", 
        descricao: "325.079.058-32", 
        status: "OK", 
        comentarios: `Deve ser o CPF de quem está cadastrando e que tem a procuração.

          Júlio - 32507905832
          Zanoni - 04909071997`
      },
      {
        item: "Abrangência", 
        descricao: `${this.formatSiglasEstadosSorteio()}`, 
        status: "OK", 
        comentarios:"Nenhum"
      },
      {
        item: "Apurações - Quantidade de elementos sorteáveis por série", 
        descricao: `${this.sorteio.quantidadeElementosSorteaveis}`, 
        status: "OK", 
        comentarios: "Devido ao alto número de cupons, trabalhamos com 100 séries de 100.000 elementos sorteáveis."
      },
      {
        item: "Apurações - Quantidade de séries", 
        descricao: `${this.sorteio.quantidadeSeries}`, 
        status: "OK", 
        comentarios: "Devido ao alto número de cupons, trabalhamos com 100 séries de 100.000 elementos sorteáveis."
      },
      {
        item: "Apurações - Data e hora da apuração", 
        descricao: `${this.apuracao.data_apuracao != '' ? format(this.apuracao.data_apuracao, 'dd/MM/yyyy') : ''} 11:00`, 
        status: "OK", 
        comentarios: `3a feira na semana seguinte à extração da loteria federal

          Formato: dd/mmm/aaaa 11:00`
      },
      {
        item: `"Apurações - Período de participação
          (Acertar na plataforma e no texto)"`, 
        descricao:`${this.apuracao.dataInicialParticipacao != '' ? format(this.apuracao.dataInicialParticipacao, 'dd/MM/yyyy HH:mm') : ''}`, 
        status: "OK", 
        comentarios: `Início deve ser pelo menos 10 dias da submissão da promoção.

          Formato deve ser:
          dd/mm/aaaa 00:00 a dd/mm/aaaa 23:59`
      },
      {
        item: `"Apurações - Período de participação
          (Acertar na plataforma e no texto)"`, 
        descricao:`${this.apuracao.dataFinalParticipacao != '' ? format(this.apuracao.dataFinalParticipacao, 'dd/MM/yyyy HH:mm') : ''}`, 
        status: "OK", 
        comentarios: `Início deve ser pelo menos 10 dias da submissão da promoção.

          Formato deve ser:
          dd/mm/aaaa 00:00 a dd/mm/aaaa 23:59`
      },
      {
        item: "Apurações - Sorteio da Loteria Federal", 
        descricao: `${this.apuracao.dataExtracaoLoteriaFederal != '' ? format(this.apuracao.dataExtracaoLoteriaFederal, 'dd/MM/yyyy') : ''}`, 
        status: "OK", 
        comentarios: "Fórmula, foi definido acima."
      },
      {
        item: "Apurações - Endereço - CEP", 
        descricao: "13010-211", 
        status: "OK", 
        comentarios:"Nenhum"
      },
      {
        item: "Apurações - Endereço - Logradouro", 
        descricao: "AVENIDA OROSIMBO MAIA", 
        status: "OK", 
        comentarios:"Nenhum"
      },
      {
        item: "Apurações - Endereço - Número", 
        descricao: "360", 
        status: "OK", 
        comentarios:"Nenhum"
      },
      {
        item: "Apurações - Endereço - Complemento", 
        descricao: "SALA 509", 
        status: "OK", 
        comentarios:"Nenhum"
      },
      {
        item: "Apurações - Endereço - Bairro", 
        descricao: "CENTRO", 
        status: "OK", 
        comentarios:"Nenhum"
      },
      {
        item: "Apurações - Endereço - UF", 
        descricao: "SP", 
        status: "OK", 
        comentarios: "Este campo é selecionado na SEFEL."
      },
      {
        item: "Apurações - Endereço - Cidade", 
        descricao: "Campinas", 
        status: "OK", 
        comentarios: "Este campo é selecionado na SEFEL."
      },
      {
        item: "Apurações - Endereço - Local da apuração", 
        descricao: "A apuração será por vídeo conferência disponível publicamente através do link: https://meet.google.com/wsejygm-qfz",
        status: "OK", 
        comentarios:"Nenhum"
      },
      {
        item: "Apurações - Prêmios", 
        descricao: `${toWords.convert(this.premios.length).toLocaleLowerCase()}`, 
        status: "OK", 
        comentarios:"Nenhum"
      },
      {
        item: "Apurações - Prêmios", 
        descricao: `${this.premios[0] ? this.premios[0].descricao : ''}`, 
        status: "OK", 
        comentarios: `Fórmula, foi definido acima.

          Cuidado para cadastrar corretamente a Descrição, Quantidade, Valor e Séries.`
      },
      {
        item: "Apurações - Série inicial e final", 
        descricao: `${this.sorteio.quantidadeSeries != 0 ? ("0 a " + (this.sorteio.quantidadeSeries - 1).toString()) : ''}`, 
        status: "OK", 
        comentarios: "Série que o prêmio está relacionado"
      },
      {
        item: "Termo de responsabilidade", 
        descricao: "Apenas aceitar", 
        status: "OK", 
        comentarios:"Nenhum"
      },
      {
        item: "Taxa de fiscalização - GRU - Responsável", 
        descricao: "26.350.495/0001-72", 
        status: "OK", 
        comentarios: `Link do curso com a informação para geração e pagamento da guia:
          https://autorizapromocoes.club.hotmart.com/lesson/BeZYGr084w/o-que-e-e-como-emitir-uma-gru

          Link onde é possível emitir a GRU:
          https://pagtesouro.tesouro.gov.br/portal-gru/#/emissao-gru

          Instruções para emissão da GRU:
          Unidade Gestora (UG): 170592
          Gestão: 00001-TESOURO NACIONAL
          Nome da Unidade: SECRETARIA ACOMP. FISCAL, ENERGIA E LOTERIA
          Código de Recolhimento: 10033-1 - 

          CNPJ: 26.350.495/0001-72
          RAZAO SOCIAL: BENCHMKT INTELIGENCIA E PESQUISAS DE MERCADO LTDA

          O valor depende do valor dos prêmios. Até R$ 5.000,00 de prêmios, o valor é: 166,00

          Após gerar a GRU, é necessário pagar. Hoje, só é possível pagar ONLINE através do Banco do Brasil. Pagar de uma conta pessoal e anexar o comprovante.`
      },
      {
        item: "Taxa de fiscalização - GRU - Data", 
        descricao: "", 
        status: "OK", 
        comentarios: "Preencher com a data do pagamento (dd/mm/aaaa)"
      },
      {
        item: "Taxa de fiscalização - GRU - Código de autenticação", 
        descricao: "", 
        status: "OK", 
        comentarios: "Nenhum"
      },
      {
        item: "Taxa de fiscalização - GRU - Valor", 
        descricao: "", 
        status: "OK", 
        comentarios: "Nenhum"
      },
      {
        item: "AAAAMMDD - Benchmkt - Termo de mandatária", 
        descricao: "", 
        status: "OK", 
        comentarios: "Preencher documento e coletar assinaturas digitais"
      },
      {
        item: "Imposto de Renda Sobre os prêmios - darf", 
        descricao: "", 
        status: "OK", 
        comentarios: `"Essa DARF deve ser gerada na submissão, mas só precisa ser paga no vencimento. Caso precise gerar uma DARF nova, pode descartar a antiga.

          Link do curso explicando sobre a prestação de contas. Olhar também os comentários para ver nossas perguntas
          https://autorizapromocoes.club.hotmart.com/lesson/37d9XxWBOL/conceitos-e-documentacao-para-prestacao-de-contas

          Link que deve ser utilizado para emitir a DARF
          https://sicalc.receita.economia.gov.br/sicalc/rapido/contribuinte

          Instruções:
          - Selecionar pessoa jurídica
          - CNPJ: 26.350.495/0001-72
          - Código ou nome de receita: 0916 - 02 - DC - a partir de 01/01/2006 -  IRRF - Prêmios Obtidos em Concursos e Sorteios - IRRF - PRÊMIOS OBTIDOS EM CONCURSOS E SORTEIOS
          - Data de consolidação: Data da apuração
          - Período de apuração: seguir regra do decêndio.
            + 01/<mes_apuracao>/<ano_apuracao> se apuração for entre os dia 01 e 10
            + 02/<mes_apuracao>/<ano_apuracao> se apuração for entre os dia 11 e 20
            + 03/<mes_apuracao>/<ano_apuracao> se apuração for entre os dia 21 e 31
          - Valor do Principal: 20% do valor total dos prêmios"`
      },
    ];
  }

  getItens(): ItemInfoSCPC[] {
    return this.itens;
  }

  private ordenarPorNome(nomes: string[]): string[] {
    return nomes.sort((a, b) => {
      if (a < b)
        return -1;
      if (a > b)
        return 1;
      return 0; 
    });
  }

  private formatNomesEstadosSorteio(): string {
    if (this.sorteio.estados == '')
      return '';
    let count = 0;
    let nomes = '';
    const arraySiglasEstados = this.ordenarPorNome(this.sorteio.estados.split(","));
    const arrayNomesEstados = arraySiglasEstados.map(sigla => this.estadosUtils.findNomeBySigla(sigla as EstadoBrasileiro));
    arrayNomesEstados.forEach(nome => {
      if (count == arrayNomesEstados.length - 1) {
        nomes += `e ${nome}`;
      } else if (count == arrayNomesEstados.length - 2) {
        nomes += `${nome} `;
      } else {
        nomes += `${nome}, `;
      }
      count++;
    });
    return nomes;
  }

  private formatSiglasEstadosSorteio(): string {
    if (this.sorteio.estados == '')
      return '';
    let count = 0;
    let nomes = '';
    const arraySiglasEstados = this.ordenarPorNome(this.sorteio.estados.split(","));
    arraySiglasEstados.forEach(sigla => {
      if (count == arraySiglasEstados.length - 1) {
        nomes += `e ${sigla}`;
      } else if (count == arraySiglasEstados.length - 2) {
        nomes += `${sigla} `;
      } else {
        nomes += `${sigla}, `;
      }
      count++;
    });
    return nomes;
  }

  private formatCapturasEstadosSorteio(): string {
    if (this.capturas.length < 1)
      return ''
    let urls = ''
    const arraySiglasEstados = this.ordenarPorNome(this.sorteio.estados.split(","));
    arraySiglasEstados.forEach((estado) => {
      this.capturas.forEach((captura) => {
        if (captura.stateInitials == estado && captura.docTypeName == "NFCE") {
          urls += `${captura.url}
        `
        }
      });
    });
    return urls;
  }

  private getDataInicialEnvioNotas(): string {
    if (this.apuracao.dataInicialEnvioNotas != '' && this.apuracao.dataInicialEnvioNotas != null) {
      return format(this.apuracao.dataInicialEnvioNotas, "MMMM 'de' yyyy", {locale: ptBR});
    } else if (this.apuracao.dataInicialEnvioNotas != '' && this.apuracao.dataInicialEnvioNotas == null) {
      return format(this.apuracao.dataInicialParticipacao, "MMMM 'de' yyyy", {locale: ptBR});
    }
    return '';
  }

  private getDataFinalEnvioNotas(): string {
    if (this.apuracao.dataFinalEnvioNotas != '' && this.apuracao.dataFinalEnvioNotas != null) {
      return format(this.apuracao.dataFinalEnvioNotas, "dd 'de' MMMM 'de' yyyy", {locale: ptBR});
    } else if (this.apuracao.dataFinalEnvioNotas != '' && this.apuracao.dataFinalEnvioNotas == null) {
      return format(this.apuracao.dataFinalParticipacao, "dd 'de' MMMM 'de' yyyy", {locale: ptBR});
    }
    return '';
  }

} 
import api from "./Api";
import {AxiosResponse} from "axios";
import token from "./TokeService";
import Sorteio from "../interfaces/Sorteio";

function getAllSorteios(page: number, size: number, sort: string): Promise<AxiosResponse> {
  return api.get(`/promocoes?token=${token()}&page=${page}&size=${size}&sort=${sort},desc`);
}

function postSorteio(body: Sorteio): Promise<AxiosResponse> {
  return api.post(`/promocoes?token=${token()}`, body);
}

function putSorteio(body: Sorteio): Promise<AxiosResponse> {
  return api.post(`/promocoes/${body.id}/update?token=${token()}`, body);
}

function deleteSorteio(idSorteio: number): Promise<AxiosResponse> {
  return api.post(`/promocoes/${idSorteio}/delete?token=${token()}`)
}

function getSorteio(idSorteio: number): Promise<AxiosResponse> {
  return api.get(`/promocoes/${idSorteio}?token=${token()}`)
}

const SorteioService = {
    getAllSorteios,
    postSorteio,
    putSorteio,
    deleteSorteio,
    getSorteio
};

export default SorteioService;

import React, {ReactElement, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import styles from "./ReportPage.module.scss";
import {Allotment} from "allotment";
import {TabPanel, TabView} from "primereact/tabview";
import {ScrollPanel} from "primereact/scrollpanel";
import {UserPage} from "../user/UserPage";
import {ReportList} from "./report_list/ReportList";
import ReportStatus from "../../interfaces/ReportStatus";
import ReportLevel from "../../interfaces/ReportLevel";
import {Toast} from "primereact/toast";
import {Loading} from "../../components/loading/Loading";
import ReportController from "./ReportController";
import ReportBoxRef from "./report_box/ReportBoxRef";
import ReportBox from "./report_box/ReportBox";
import WindowDimensions from "../../components/dimensions/WindowDimensions";


export function ReportPage(): ReactElement {
    const reportBoxRef = useRef<ReportBoxRef>();
    const toast = useRef<Toast>(null);
    const [isResponseLoading, setIsResponseLoading] = useState(false);
    const [report, setReport] = useState<ReportStatus | null>(null);

    const [reportSub, setReportSub] = useState<ReportStatus | null>(null);

    const [activeIndex, setActiveIndex] = useState(0);
    const [msg, setMsg] = useState("");
    const {cpf} = useParams();
    const [levels, setLevels] = useState<Array<ReportLevel>>([]);
    const {height, width} = WindowDimensions();

    const controller = React.useCallback(
        () => new ReportController(setIsResponseLoading, toast),
        [setIsResponseLoading, toast]
    );

    async function getLevels(): Promise<void> {
        const data = await controller().getLevels()

        if (data) {
            setLevels(data)
        }
    }

    useEffect(() => {
        getLevels()
    }, []);

    useEffect(() => {
        reportBoxRef.current?.clean();
    }, [report]);

    return (
        <div className={styles.containerBody}>
            <Loading isLoading={isResponseLoading}/>
            <Toast ref={toast}/>
            <div className={styles.ReportPage}>
                <Allotment className={styles.splitter}>
                    <Allotment.Pane
                        minSize={150}
                        className={styles.splitterPanel}
                        preferredSize={250}
                        snap
                    >
                        <ReportList report={reportSub} setReport={setReport} cpf={cpf} levels={levels}/>
                    </Allotment.Pane>
                    <Allotment.Pane minSize={500} className={styles.splitterPanel}>
                        <ReportBox ref={reportBoxRef} user={report} set={setMsg} setTab={setActiveIndex} value={msg}/>
                    </Allotment.Pane>
                    <Allotment.Pane
                        minSize={200}
                        className={styles.reportInfo}
                        snap
                        preferredSize={width / 2.5}
                    >
                        <TabView
                            activeIndex={activeIndex}
                            onTabChange={(e) => setActiveIndex(e.index)}
                            className={styles.reportInfoScroll}
                        >
                            <TabPanel header="INFO">
                                <ScrollPanel>
                                    <UserPage
                                        user={report}
                                        enable={false}
                                        className={styles.reportUserPage}
                                        setReport={setReportSub}
                                    />
                                </ScrollPanel>
                            </TabPanel>
                        </TabView>
                    </Allotment.Pane>
                </Allotment>
            </div>
        </div>
    );

}
